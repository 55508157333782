import React, { useEffect } from "react";
import Toast from '../../components/Toast';
import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { clearHomeRouteState, getCachedPhoneNumber, isNewSignInFlowEnabled, trackUTM } from "../../utils/helpers";
import { useHistory, useLocation } from "react-router-dom";
import { NewSignInFlowActions } from "../../components/NewSignInFlowActions";
import { OldSignInFlowActions } from "../../components/OldSignInFlowActions";
import { AutoHideToast } from "../../components/AutohideToast";

function SenecaWomen() {
  const location: any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  trackUTM('marketingData', window.location.href); // Track marketing data

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <>
    {title && <AutoHideToast title={title} timeout={6000} />}
    <div>
      <div className="container-sw bg-sw x-sm:bg-none w-full lg:min-h-screen x-sm:min-h-full pt-7 pb-2 px-20 x-sm:px-8 x-sm:pb-10">
        {message && <Toast
          cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center"
          text={message} timeout={6000} />}
        <div className="flex justify-between mb-14 x-sm:mb-1 x-sm:items-center">
          <a className="inline-block x-sm:w-24 mb-6 w-40" href="/">
            <img src={`${IMAGE_PREFIX()}/logo.png`} alt="logo" />
          </a>
          <div className="text-white text-xs x-sm:right-full">
            <span className="">We are powered by</span><br />
            <img
              className="inline-block x-sm:w-22 x-sm:mt-1 mt-1 x-sm:mb-4 mb-6 h-5"
              src={`${IMAGE_PREFIX()}/poweredby.svg`}
              alt="powered by deserve"
            />
          </div>
        </div>
        <div className="sw-content-container md:flex md:justify-center md:flex-col min-h-screen">
          {!phoneNumber && <div>
            <h1 className="text-white text-5xl x-sm:text-4xl x-sm:leading-9 x-sm:mb-2 font-bodoni mb-6 x-sm:mt-2.5 x-sm:text-center"><span className="block">Shop women-owned. </span> Get rewarded.</h1>
            <p className="text-white text-2xl x-sm:text-sm x-sm:leading-4 x-sm:mt-2 mt-1 x-sm:mb-1 desc-sw font-circular x-sm:text-center">Get <span className="color-theme">3% cash back</span> when you shop <br /> from The Marketplace by Seneca Women.</p>
            <p className="text-white text-s x-sm:mb-2 mt-1 x-sm:mt-0 font-sf-display x-sm:text-center">Apply and use your card within minutes*</p>
            <p className="text-white text-s x-sm:mb-2 mt-1 mb-16 x-sm:mt-0 font-sf-display info-sw x-sm:text-center">Not available for Android users.</p>
          </div>}
          {phoneNumber && <h1 className="text-white text-5xl x-sm:text-4xl x-sm:leading-9 x-sm:mb-2 font-bodoni mb-6 x-sm:mt-8 x-sm:text-center">Welcome back</h1>}
          {phoneNumber && <p className="text-white text-s x-sm:mb-2 mt-1 x-sm:mt-0 font-sf-display x-sm:text-center mb-4">Continue with your phone number <span className="block">verification to sign in</span></p>}
          <div className="x-sm:w-full x-sm:h-0.5 x-sm:my-1.5 x-sm:bg-gray-200">
          </div>
          <div className="md:hidden lg:hidden 2xl:hidden xl:hidden x-sm:flex x-sm:justify-center x-sm:pb-2 x-sm:pt-1">
            <span className="text-white text-sm">Sign in or Apply below</span>
          </div>
          {isNewSignInFlowEnabled() ? <NewSignInFlowActions showOnlySignIn={true} /> : <OldSignInFlowActions />}
        </div>
        <p className="text-white text-xs x-sm:relative x-sm:bottom-0 x-sm:pb-0 x-sm:pt-10 md:mt-8">*Subject to credit approval</p>
      </div>
      <div className="footer pt-6 px-20 x-sm:px-8 x-sm:pb-4">
        <div className="text-blue text-xs  mb-10">
          <div className="mb-4 flex x-sm:block items-baseline">
            <div className="mt-9 mb-4  x-sm:hidden">© 2022 Deserve</div>
            <ul className="flex x-sm:block mb-4">
              <li className="mb-2 font-bold"><a className="ml-5 x-sm:ml-0" href="https://protect-us.mimecast.com/s/I3SgCG6rAnF1AW1LfKqEER?domain=static.deserve.com">Terms and Conditions</a></li>
              <li className="mb-2 font-bold"><a className="ml-5 x-sm:ml-0" href="https://www.deserve.com/terms-and-conditions//electronic-consent-policy/">Electronic Consent Policy</a></li>
              <li className="mb-2 font-bold"><a className="ml-5 x-sm:ml-0" href="https://www.deserve.com/terms-and-conditions/usa-patriot-act-notice/">PATRIOT Act Notice</a></li>
              <li className="mb-2 font-bold"><a className="ml-5 x-sm:ml-0" href="https://www.deserve.com/privacy-policy">Privacy Policy</a></li>
              <li className="mb-2 font-bold"><a className="ml-5 x-sm:ml-0" href="https://www.deserve.com/terms-and-conditions/privacy-notice/">Privacy Notice</a></li>
              <li className="mb-2 font-bold"><a className="ml-5 x-sm:ml-0" href="https://www.deserve.com/terms-and-conditions/terms-of-service/">Terms of Service</a></li>
            </ul>
          </div>
          <div className="mt-9 mb-2 hidden x-sm:block">© {new Date().getFullYear()} Deserve</div>
          <div className="text-blue text-xs mb-10">
            <p className="mb-4">The Card by Seneca Women (“The Card”) is issued by Celtic Bank, a Utah-Chartered Industrial Bank, Member FDIC and powered by Deserve, Inc. Cardholders will receive 3% cash back rewards when they shop from women-owned businesses included in The Marketplace by Seneca Women, 2% rewards on purchasing in their top spending category for the month, and 1% on everything else. 3% and 2% rewards are capped at a cumulative spend of $500 per month. The details and terms of The Card and this rewards program, including the definition of women-owned businesses, is set forth in the <a rel="noreferrer" href="https://static.deserve.com/resources/CHA/Seneca+Women+Deserve+DIGITAL+PRO+Card+Terms+&+Conditions+APR+Range.pdf" target="_blank" className="font-bold">Cardholder Agreement and the Rewards Program Agreement</a> for The Card. Applying for The Card does not guarantee that you will be eligible to receive The Card. Geographic, regulatory, and underwriting restrictions will apply. Fees and terms are subject to change, and additional terms of service will apply to The Card. Rates for The Card are subject to change. See the <a href="https://static.deserve.com/resources/CHA/Seneca+Women+Deserve+DIGITAL+PRO+Card+Terms+&+Conditions+APR+Range.pdf" target="_blank" rel="noreferrer" className="font-bold">Cardholder Agreement</a> and <a href="https://website-static.deserve.com/wp-content/uploads/2019/11/Website-Terms-of-Use.pdf" target="_blank" rel="noreferrer" className="font-bold">Deserve Terms of Use</a>.</p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default SenecaWomen;
