/* eslint-disable no-extend-native */
import { RouterHistory } from "@sentry/react/types/reactrouter";
import * as Sentry from "@sentry/react";
import * as SentryB from "@sentry/browser";
import queryString from "query-string";
import { PlaidLinkOnEventMetadata, PlaidLinkOnExitMetadata } from "react-plaid-link";
import { EVENT } from "../constants/events.constants";
import { DASHBOARD_PATH, PAYMENT_METHOD, AUTO_PAY, PROFILE_PATH, EDIT_ADDRESS_PATH, CONTACT_US_PATH } from "../constants/route-paths.constants"
import { SignInQueryType } from "../types/SignInQueryType";
import { track } from "./analytics";
import ClientStorage from "./client-storage"
import { unFormatMobileNumber } from "./mobileNumberFormatter";
import { AxiosError } from "axios";
import Cookies from "js-cookie";
import toast from 'react-hot-toast';

export const buildAppleSignInUrl = () => {
  let clientId = window['appConfig'].APPLE_CLIENT_ID
  let redirectUri = `${window['appConfig'].BASE_URL}/api/apple/callback`
  return `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id=${clientId}&redirect_uri=${redirectUri}&&scope=name%20email&response_mode=form_post`
}

export const buildGoogleSignInUrl = () => {
  let clientId = window['appConfig'].GOOGLE_CLIENT_ID
  let redirectUri = `${window['appConfig'].BASE_URL}/auth/google/callback`
  return `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=email%20profile&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`
}

export const DownloadOrViewFile = (FileBlob, FileType: any, FileName: string, ShouldDownload: any) => {
  try {
    var blob = new Blob([FileBlob], { type: FileType });
    let a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = FileName;
    // flag `ShouldDownload` decides wheather to download or open file in new tab
    if (ShouldDownload) {
      a.click();
    } else {
      window.open(url, '_blank')
    }

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('error downloading the file.');
  }
}

export const redirectTo = (url: string) => {
  window.location.href = url;
}

export function trackUTM(key: string, value?: string) {
  const expiresAt = new Date();
  const createdAt = new Date();
  let finalURL = "";
  let marketingURLParams: any = {
    message: ""
  };

  if (key && value) {
    let decoded_url = decodeURIComponent(value);
    let splited_urls = decoded_url.split("marketing_url="); // This will have 1 value if no marketing_url present in query || intial level

    if (splited_urls?.length > 0) {
      let marketingURL = splited_urls[(splited_urls.length - 1)];
      let marketingURLArray = marketingURL.split("?");

      if (marketingURLArray.length > 1) { // Pick the marketing_url query params
        marketingURLParams = queryString.parse(marketingURLArray[marketingURLArray.length - 1]);
        // Delete non required query params
        delete marketingURLParams?.message;
        delete marketingURLParams?.email;
        delete marketingURLParams?.otp;

        finalURL = queryString.stringifyUrl({
          url: `${window.location.origin}/`,
          query: marketingURLParams
        });

        expiresAt.setDate(expiresAt.getDate() + 1); // Here today becomes tomorrow by adding one day in today, it's 24 hrs.

        let data = {
          "marketing_url": finalURL,
          "expiresAt": expiresAt,
          "createdAt": createdAt
        },
          marketingDataString = JSON.stringify(data);

        ClientStorage.setLocal(key, marketingDataString);

        marketingDataString = ClientStorage.getLocal(key);

        if (ClientStorage.getLocal(key) === marketingDataString) {
          track(EVENT.onb_marketing_url_stored, {
            ...data,
            stored: true
          });
        }
      } else {
        ClientStorage.deleteLocal(key);
      }
    }
  } else {
    track(EVENT.onb_marketing_url_readed, {
      ...JSON.parse(ClientStorage.getLocal(key)),
      readed: true
    });
  }

  return {
    ...JSON.parse(ClientStorage.getLocal(key))
  };
}

export function checkMarketingDataExpired(marketingAllData: any): any {
  if (marketingAllData?.expiresAt && Date.now() > Date.parse(marketingAllData?.expiresAt)) {
    marketingAllData.expired = true;
  } else {
    marketingAllData.expired = false;
  }
  return marketingAllData;
}

export function parseQueryString(url: string) {
  let params = new URLSearchParams(url),
    data = {};

  params.forEach((val, key) => {
    if (key.indexOf("?") !== -1) {
      let tempKey = key.substring(key.indexOf("?") + 1);
      return data[tempKey] = val;
    }
    return data[key] = val;
  });
  return data;
}

export function confirmDashboardPage() {
  return (
    window.location.pathname === DASHBOARD_PATH ||
    window.location.pathname === PAYMENT_METHOD ||
    window.location.pathname === AUTO_PAY ||
    window.location.pathname === PROFILE_PATH ||
    window.location.pathname === EDIT_ADDRESS_PATH ||
    window.location.pathname === CONTACT_US_PATH
  );
}

export function confirmOnlyDashboardPage() {
  return window.location.pathname === DASHBOARD_PATH;
}

export function trackPlaidLinking(eventName: string, metadata: PlaidLinkOnEventMetadata | PlaidLinkOnExitMetadata): Promise<null> {
  return new Promise((resolve) => {
    if (EVENT[`onb_bank_connect_${eventName.toLowerCase()}`]) {
      track(EVENT[`onb_bank_connect_${eventName.toLowerCase()}`], metadata);
    }
    resolve(null);
  });
}


export function unFormattedPhoneNumber(newValue: string) {
  if (newValue && newValue.length) {
    return unFormatMobileNumber(newValue);
  } else {
    return null;
  }
}

export const replaceAt = function (str: string, index: number, replacement: any) {
  return str.substring(0, index) + replacement + str.substring(index + replacement.replace(/\s/igm, '').length);
}

export function validatePhoneNumber(newValue: string, pattern: RegExp = /^[0-9]{10}\d*$/, min_length: number = 10, max_length: number = 12) {
  const phoneNumber = unFormattedPhoneNumber(newValue)
  const hasUScountryCode = phoneNumber && new RegExp(/^1/).test(phoneNumber.toString());
  const isValidEntry = phoneNumber && new RegExp(pattern).test(phoneNumber.toString());
  const isInRange = phoneNumber > 1 && phoneNumber.toString().length >= min_length && `+1${phoneNumber.toString()}`.length <= max_length;

  return phoneNumber && !hasUScountryCode && isValidEntry && isInRange;
}

export const isNewSignInFlowEnabled = () => {
  return window['appConfig']?.NEW_SIGNIN_FLOW_ENABLED === "true" || window['appConfig']?.NEW_SIGNIN_FLOW_ENABLED === true;
};

export const isNewSignInFlowEnabledForDFC = () => {
  return ((window['appConfig']?.NEW_SIGNIN_FLOW_ENABLED_FOR_DFC === "true" || window['appConfig']?.NEW_SIGNIN_FLOW_ENABLED_FOR_DFC === true) && window['appConfig']?.PARTNER_NAME === "deserve");
};

export const isOnboardingRefreshV2Enabled = () => {
  return (((window['appConfig']?.ENABLE_ONBOARDING_REFRESH_V2 === "true" || window['appConfig']?.ENABLE_ONBOARDING_REFRESH_V2 === true) && (window['appConfig']?.PARTNER_NAME === "deserve" || window['appConfig']?.PARTNER_NAME === "amc" || window['appConfig']?.PARTNER_NAME === "customers_bank"))
    || ((window['appConfig']?.ENABLE_ONBOARDING_REFRESH_V2_KROWDFIT === "true" || window['appConfig']?.ENABLE_ONBOARDING_REFRESH_V2_KROWDFIT === true) && window['appConfig']?.PARTNER_NAME === "krowdfit")
    || ((window['appConfig']?.ENABLE_ONBOARDING_REFRESH_V2_EARNEST === "true" || window['appConfig']?.ENABLE_ONBOARDING_REFRESH_V2_EARNEST === true) && window['appConfig']?.PARTNER_NAME === "earnest")
    || ((window['appConfig']?.ENABLE_ONBOARDING_REFRESH_V2_SW === "true" || window['appConfig']?.ENABLE_ONBOARDING_REFRESH_V2_SW === true) && window['appConfig']?.PARTNER_NAME === "sw")
    || window['appConfig']?.PARTNER_NAME === "deserve_edu"
    || window['appConfig']?.PARTNER_NAME === "slm_ignite"
    || window['appConfig']?.PARTNER_NAME === "ccbank"
  );
};

export const isProveFlowEnabled = () => {
  return ((window['appConfig']?.PROVE_FLOW_ENABLED_DESERVE === "true" || window['appConfig']?.PROVE_FLOW_ENABLED_DESERVE === true) && window['appConfig']?.PARTNER_NAME === "deserve");
};

export const getUSPhoneNumber = (phone_number: string): string | any => {
  return phone_number ? phone_number.includes("+1") ? phone_number : `+1${phone_number}` : null;
};

export const setSessionId = (data) => {
  if (data?.session_id) {
    ClientStorage.setLocal("sign_in_session_id", data?.session_id);
  }
};

export const getSessionId = () => {
  return ClientStorage.getLocal("sign_in_session_id");
};

export const getCachedPhoneNumber = () => {
  return ClientStorage.getLocal("phone_number");
}

export const isPhoneNumberCached = (cachedPhoneNumber: string, flow: string, phone: string) => {
  let temproryCachedNumber = ClientStorage.getLocal("non_cached_phone_number");
  let application_id = ClientStorage.getLocal("application_id");
  let is_phone_number_cached = ClientStorage.getLocal("phone_number");

  if (is_phone_number_cached && (cachedPhoneNumber && flow === "signin" || phone === temproryCachedNumber && application_id)) {
    return true;
  } else {
    return false;
  }
};

export const getNewSignInCommonEventProps = (query?: SignInQueryType | any) => {
  return {
    isNewSignInFlowEnabled: isNewSignInFlowEnabled(),
    tenant_id: window['appConfig']['PROGRAM_ID'],
    ...query?.flow ? { flow: query.flow } : { flow: "Sign Up" },
    ...query?.phone ? { phone: query.phone } : {}
  }
};

export const clearRouteState = (route: string, routeHistory: any) => {
  routeHistory.push(route);
  return null;
};

export const clearHomeRouteState = (title: string, routeHistory: any) => {
  if (title) {
    setTimeout(() => {
      clearRouteState("/", routeHistory);
    }, 6000);
  }
};


export const appSentryCaptureError = (
  extraKey: string,
  requestPayload: Record<string, any> = {},
  errorResp: AxiosError,
) => {
  Sentry.withScope(scope => {
    SentryB.setTag("user_id", localStorage.getItem("user_id"));
    scope.setExtra(extraKey, {
      ...errorResp?.response?.data,
      payloadBody: requestPayload
    });
    Sentry.captureException(errorResp);
  });
};

export const logErrorToSentry = (
  eventKey: string,
  requestData: Record<string, any> = {},
  errorResp: AxiosError
) => {
  const respStatus = errorResp?.response?.status;
  const responseData = {
    ...errorResp?.response?.data,
      status: respStatus
  };

  if (respStatus === 400 || (respStatus >= 500 && respStatus <= 511)) {
    appSentryCaptureError(eventKey, requestData, errorResp);
  }
};

export const getApplicationToken = () => {
  if(window['appConfig'].ENABLE_LOCAL_STORAGE_BASED_AUTHENTICATION === 'true') {
    return ClientStorage.getLocal("application_token")
  }
  return Cookies.get("application_token")
}

export const getApplicationRefreshToken = () => {
  if (window['appConfig'].ENABLE_LOCAL_STORAGE_BASED_AUTHENTICATION === 'true') {
    return ClientStorage.getLocal("application_refresh_token")
  }
  return Cookies.get("application_refresh_token")
}

export const clearApplicationToken = () => {
  if (window['appConfig'].ENABLE_LOCAL_STORAGE_BASED_AUTHENTICATION === 'true') {
    return ClientStorage.deleteLocal("application_token")
  }
  return Cookies.remove("application_token")
}

export const clearApplicationRefreshToken = () => {
   if (window['appConfig'].ENABLE_LOCAL_STORAGE_BASED_AUTHENTICATION === 'true') {
    return ClientStorage.deleteLocal("application_refresh_token")
  }
  return Cookies.remove("application_refresh_token")
}

export const isCallbackFlow = () => {
   if (window['appConfig'].ENABLE_LOCAL_STORAGE_BASED_AUTHENTICATION === 'true') {
    return ClientStorage.getLocal("is_callback_flow") === 'true'
  }
  return Cookies.get("is_callback_flow") === 'true'
}

export const clearIsCallbackFlow = () => {
   if (window['appConfig'].ENABLE_LOCAL_STORAGE_BASED_AUTHENTICATION === 'true') {
    return ClientStorage.deleteLocal("is_callback_flow")
  }
  return Cookies.remove("is_callback_flow")
}

export const copyToClipboard = (str, onSuccessMessage = "Copied to clipboard") => {
  window.navigator.clipboard.writeText(str).then(() => {
    toast.success(onSuccessMessage);
  }).catch(er => {
    toast.error('failed to copy to clipboard');
    console.error(er);
  });
};
