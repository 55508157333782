import React, { useState, useRef, Fragment } from 'react';
import Downshift from 'downshift'
import SnackPopMessage from '../components/SnackPopMessage';
import { STREET_API_SYSTEM_DOWN_LABEL } from '../constants/label.constants';
import { track, trackFailure } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { SmartStreeSuggetionType } from '../types/SmartStreeAddressType';
import { AddressType } from '../types/AddressType';
import debounce from 'lodash.debounce';

type Props = {
  onAddressSelect: Function;
  defaultValue?: string
};

function SmartStreetAutoComplete(props: Props) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastSearch, setLastSearch] = useState("");
  const [streetApiOffline, setStreetApiOffline] = useState(false);
  const SmartyStreetsSDK = require("smartystreets-javascript-sdk");
  const SmartyStreetsCore = SmartyStreetsSDK.core;
  const Lookup = SmartyStreetsSDK.usAutocompletePro.Lookup;

  const credentials = new SmartyStreetsCore.SharedCredentials(window["appConfig"].SMART_STREET_API_KEY);
  let client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);
  const addresInputRef = useRef<any>();
  const debounceSearchAddress = debounce(searchAddress, 1000);

  async function searchAddress(inputValue: string | null = "", selected: string | null = null) {
    let addresLookup = new Lookup();

    addresLookup.search = inputValue || lastSearch;
    addresLookup.selected = selected || undefined;
    setIsLoading(true);

    client.send(addresLookup)
      .then((data: SmartStreeSuggetionType) => {
        setOptions([]);
        setIsLoading(false);
        setStreetApiOffline(false);
        track(EVENT.onb_smartstreet_api_success);
        setOptions(data.result);
        setLastSearch(inputValue);
        return data.result;
      })
      .catch((err: any) => {
        setIsLoading(false);
        setStreetApiOffline(true);
        trackFailure(EVENT.onb_smartstreet_api_failed);
        setOptions([]);
        setLastSearch("");
        return [];
      });
  }

  function getEntriesItemHtmlOrString(address: AddressType, inString: boolean) {
    if (inString) {
      return (`${address.streetLine} ${address.secondary} ${address.entries <= 1 ? '' : `(${address.entries} more entries)`} ${address.city} ${address.state} - ${address.zipcode}`);
    } else {
      return (
        <div>
          {`${address.streetLine} ${address.secondary}`} <span className="font-black">{`${address.entries <= 1 ? '' : `(${address.entries} more entries)`}`}</span> {`${address.city} ${address.state} - ${address.zipcode}`}
        </div>
      );
    }
  }

  function getItemHtmlOrString(address: AddressType, inString: boolean) {
    if (inString && address.entries > 0) {
      return `${address.streetLine} ${address.secondary} ${address.city} ${address.state} - ${address.zipcode}`;
    } else {
      return (
        <div>
          {`${address.streetLine} ${address.secondary}`} {`${address.city} ${address.state} - ${address.zipcode}`}
        </div>
      )
    }
  }

  function formatOptionLabel(address: AddressType, inString: boolean) {
    if (!address)
      return "";

    if (address.entries > 1) {
      return getEntriesItemHtmlOrString(address, inString);
    }

    return getItemHtmlOrString(address, inString);
  }

  function itemToString(address: AddressType) {
    if (!address)
      return "";
    return (`${address.streetLine} ${address.secondary}`);
  }

  async function onInputValueChange(inputValue: string, stateAndHelpers: any) {
    let { selectedItem } = stateAndHelpers;

    if (inputValue.length === 0) {
      return null;
    }
    if ((inputValue && stateAndHelpers.type === Downshift.stateChangeTypes.changeInput)) {
      debounceSearchAddress(inputValue)
    } else if (selectedItem && selectedItem?.entries > 1) {
      let selectedQuery = `${selectedItem.streetLine} ${selectedItem.secondary} (${selectedItem.entries}) ${selectedItem.city} ${selectedItem.state} ${selectedItem.zipcode}`;
      debounceSearchAddress(null, selectedQuery)
    }
  }



  function stateReducer(state: any, changes: any) {
    let { selectedItem } = changes;
    let _inputVal = selectedItem ? (`${selectedItem.streetLine} ${selectedItem.secondary}`).trim() : changes.inputValue;

    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEscape:
      case Downshift.stateChangeTypes.mouseUp:
        props.onAddressSelect(changes, state?.inputValue || "", streetApiOffline);
        return {
          ...changes,
          inputValue: state?.inputValue
        }
      case Downshift.stateChangeTypes.clickItem:
      case Downshift.stateChangeTypes.blurInput:
        let new_inputVal = selectedItem ? _inputVal : "";
        if (changes.type === Downshift.stateChangeTypes.clickItem) {
          if (selectedItem.entries <= 1) {
            props.onAddressSelect(changes, new_inputVal, streetApiOffline);
          }
          return {
            ...changes,
            isOpen: selectedItem ? selectedItem.entries > 1 ? true : false : false,
            inputValue: new_inputVal
          }
        }
        return state;
      default:
        if (changes.type === Downshift.stateChangeTypes.clickItem || (changes.type === Downshift.stateChangeTypes.changeInput && changes.inputValue === "")) {
          props.onAddressSelect(changes, _inputVal || "", streetApiOffline);
          return {
            ...changes,
            isOpen: false
          }
        }
        return {
          ...changes,
          ...(changes.inputValue === "" || changes.type === Downshift.stateChangeTypes.mouseUp) ? { inputValue: "" } : (selectedItem && selectedItem.entries < 1) ? { inputValue: _inputVal || "" } : {}
        }
    }
  }

  function filterOptions(inputValue: string, item) {
    if (inputValue && item.entries <= 1) {
      return item || (`${item.streetLine} ${item.secondary}`).trim().includes(inputValue);
    } else if (inputValue && item.entries > 1) {
      return (`${item.streetLine} ${item.secondary} ${item.entries <= 1 ? '' : `(${item.entries} more entries)`} ${item.city} ${item.state} - ${item.zipcode}`)
    }
    return true;
  }

  function renderList({
    isOpen,
    getItemProps
  }) {
    if (!isLoading && isOpen && options.length > 0) {
      return options
        .filter(filterOptions)
        .map((item, index) => {
          return (
            <li
              data-testid={`typeahead__menu-item_testid_${index}`}
              className="typeahead__menu-item"
              {...getItemProps({
                key: index,
                index,
                item
              })}>
              {formatOptionLabel(item, false)}
            </li>
          );
        });
    } else if (isOpen && options.length < 1) {
      return (
        <li data-testid="typeahead__menu-item_testid"  className="typeahead__menu-item">
          <span>No matches found.</span>
        </li>
      );
    }
    return "";
  }

  function renderMenu({
    isOpen,
    getMenuProps,
    getItemProps
  }) {
    return (
      <ul data-testid="typeahead__menu_testid" {...getMenuProps()} className={isOpen ? "typeahead__menu typeahead__menu-open" : ""}>
        {renderList({ isOpen, getItemProps })}
      </ul>
    );
  }

  return (
    <Fragment>
      {streetApiOffline && <SnackPopMessage
        message={STREET_API_SYSTEM_DOWN_LABEL}
        styleClasses="w-7/12 bg-white absolute top-0 right-0 p-4 rounded rounded-3xl text-base ease-out z-50 dashboard-edit-address-lookup" />}

      <label className="w-full">
        <Downshift
          onInputValueChange={onInputValueChange}
          stateReducer={stateReducer}
          itemToString={itemToString}
          initialInputValue={props?.defaultValue?.trim() || ""}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            isOpen
          }) => {
            return (
              <div className="typeahead_container">
                <div>
                  <input data-testid="typeahead__control" {...getInputProps({
                    ref: addresInputRef,
                    type: "text",
                    autoComplete: "off",
                    autoCapitalize: "off",
                    className: "typeahead__control text-field"
                  })} />
                  {isLoading && <div data-testid="dot-pulse-loader" className="dot-pulse-loader">
                    <div className="dot-pulse"></div>
                  </div>}
                </div>
                {!isLoading && renderMenu({ isOpen, getMenuProps, getItemProps })}
              </div>
            );
          }}
        </Downshift>
      </label>
    </Fragment>
  )
};

export default SmartStreetAutoComplete;
