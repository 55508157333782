import { postWithAuthHeader } from "./client";
import { AUTOPOLL_ENDPOINT, LOGIN_URL } from "../constants/urls.constants";
import { EVENT } from "../constants/events.constants";

export let autoPoll = (workflow, setWorkflow) => {
  const data = workflow["steps"][0].auto_poll;

  return setInterval(async () => {
    try {
      data["current_itr"] = data["current_itr"] + 1;
      let response = await postWithAuthHeader(
        AUTOPOLL_ENDPOINT,
        data,
        EVENT.onb_auto_poll_failed
      );
      if (response.status === "401") {
        window.location.href = LOGIN_URL;
      }
      if (response.data && response.data.steps && setWorkflow) {
        setWorkflow(response);
      }
    } catch (error) {
      window.location.href = LOGIN_URL;
    }
  }, data["itr_interval"] * 1000);
};
