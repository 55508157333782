import React from "react";
import { QRCodeSVG } from "qrcode.react";

export function AppQRCode() {
  const QRCodeImageURL = window["appConfig"]["CLIENT_APP_DOWNLOAD_URL"];

  return (
    <div className="p-4 flex flex-col justify-center items-center">
      <h1 className="text-main py-1 m-auto" data-testid="text-main">
        Download App
      </h1>
      <div className="flex justify-center m-auto p-2">
        <QRCodeSVG value={QRCodeImageURL} size={180} />
      </div>
    </div>
  );
}
