

import React from "react";
type Props = {
  color?: string
};

function DollarIcon(props: Props) {
  const { color } = props;
  return (
    <svg style={{
      display: 'inline'
    }} width="8" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.34 7.428c0 .792-.268 1.407-.802 1.845-.53.433-1.315.67-2.356.708v1.15h-.716V10c-.94-.035-1.674-.256-2.199-.663C.742 8.927.397 8.298.23 7.454l1.68-.308c.084.494.248.857.493 1.09.25.227.604.363 1.063.406V6.076a.317.317 0 00-.098-.027.552.552 0 00-.105-.02c-.71-.161-1.245-.352-1.609-.57a2.43 2.43 0 01-.833-.84C.624 4.276.525 3.86.525 3.37c0-.726.25-1.289.749-1.687.503-.398 1.234-.615 2.192-.65v-.88h.716v.88c.573.022 1.05.114 1.43.276.386.158.694.392.926.702.237.307.427.747.571 1.32l-1.733.256c-.065-.38-.194-.674-.387-.88-.188-.21-.457-.339-.807-.387v2.31l.072.014c.127 0 .512.105 1.155.315.648.21 1.131.52 1.45.932.32.407.48.919.48 1.536zM3.465 2.295c-.814.06-1.22.411-1.22 1.05 0 .192.032.352.098.48.07.126.168.231.295.314.131.083.407.195.827.335v-2.18zm2.166 5.16c0-.22-.039-.397-.118-.532a.9.9 0 00-.348-.342c-.148-.087-.477-.203-.984-.348v2.41c.967-.066 1.45-.462 1.45-1.189z" fill={color || '#fff'} /></svg>
  );
}

export default DollarIcon;