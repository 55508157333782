import React, { useContext, useEffect, useRef, useState } from "react";
import BtnNext from "../components/BtnNext";
import { track } from "../utils/analytics";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { EVENT } from "../constants/events.constants";
import { AxiosError } from "axios";
import { VERIFY_USER_BY_SSN_DOB_API_V3 } from "../constants/urls.constants";
import { post } from "../utils/client";
import { getNewSignInCommonEventProps } from "../utils/helpers";
import { parse } from "query-string";
import { SignInQueryType } from "../types/SignInQueryType";
import { useLocation } from "react-router-dom";
import DateInputType from "./DateInputType";
import moment from "moment";
import SecureTextInput from "../components/SecureTextInput";
import { DeviceDetailsContext } from "../utils/device-details-context";
import ClientStorage from "../utils/client-storage";

type CapureSignInSSNDBOProps = {
  stepData: any;
  query: string;
  workflowSubmitCb: Function
};

function CaptureSignInSSNDBO(props: CapureSignInSSNDBOProps,) {
  const appConfig = window['appConfig'];
  const location: any = useLocation();
  const FormRef = useRef(null);
  const [formErros, setFormErros] = useState({
    ssn_last_four: {
      valid: false
    },
    dob: {
      valid: false
    }
  });
  const [showButton, setShowButton] = useState(false);
  const [individualFailure, setIndividualFailure] = useState([true, true]);
  const query: SignInQueryType | any = parse(location.search.replace("?", ""));
  const [submitting, setSubmitting] = useState(false);

  const {browser, os} = useContext(DeviceDetailsContext);

  const { capture_dob_ssn, capture_additional_data } = props.stepData.next_screen;
  const validation = capture_dob_ssn?.validation ?? capture_additional_data?.validation;

  const onInputValidity = (formField: string, val: boolean) => {
    const result = { ...formErros };
    result[formField].valid = val
    setFormErros(result);

    if (
      (result?.ssn_last_four?.valid && result?.dob?.valid) ||
      (validation?.ssn_last_four === undefined && result?.dob?.valid) ||
      (validation?.dob === undefined && result?.ssn_last_four?.valid)
    ) {
      setShowButton(true);
      return true;
    }
  };

  function handleSubmit(e) {
    let inputSSNEl = FormRef.current && FormRef.current.querySelector(`input[name="ssn_last_four"]`);
    let inputDOBEl = FormRef.current && FormRef.current.querySelector(`input[name="dob"]`);
    const body = {
      ...(validation?.ssn_last_four ? { last_four_ssn: inputSSNEl.value } : {}),
      ...(validation?.dob ? { dob: moment(inputDOBEl.value).format("MM/DD/YYYY") } : {}),
      device_details: {
        device_id: ClientStorage.getLocal('CUSTOMER_AUTH_LOGIN_IDENTIFIER'),
        model: os,
        name: browser
      }
    };

    track(
      EVENT.dfc_auth_ssn_dob_next_clicked,
      {
        ...getNewSignInCommonEventProps(query),
      }
    );

    track(EVENT.dfc_auth_ssn_dob_form_valid);
    setSubmitting(true);

    post(VERIFY_USER_BY_SSN_DOB_API_V3, body, {
      headers: {
        Authorization: `Bearer ${capture_dob_ssn?.access_token ?? capture_additional_data?.access_token}`
      }
    })
      .then((data) => {
        track(
          EVENT.dfc_auth_ssn_dob_submit_succeed,
          {
            ...getNewSignInCommonEventProps(query),
          }
        );
        props.workflowSubmitCb({
          ...props.stepData,
          ...data,
          ...body
        });
      })
      .catch((error: AxiosError) => {
        track(
          EVENT.dfc_auth_ssn_dob_submit_failed,
          {
            ...getNewSignInCommonEventProps(query),
            error: error?.response?.data
          }
        );
        props.workflowSubmitCb({
          ...error.response?.data?.error,
          title: error.response?.data?.error?.display_message,
          details: {
            ...error.response?.data?.details
          },
          next_screen: {
            ...error.response?.data?.error,
            identifier: error.response?.data?.error?.code,
          }
        });
      });
  }

  useEffect(() => {
    track(
      EVENT.dfc_auth_ssn_dob_screen,
      getNewSignInCommonEventProps(query)
    );
  }, [props]);

  return (
    <div className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page`}>
      <h1 className="text-main md:mb-8 lg:mb-8 xl:mb-8 leading-8 text-1-heading">
        Let's confirm it's you
      </h1>
      <div className="content-container">
        <form onSubmit={handleSubmit} ref={FormRef} noValidate>
          <div className="flex flex-row x-sm:flex-col md:space-x-4">
            {validation?.ssn_last_four && <SecureTextInput
              wpStyleClass="w-full x-sm:pr-0 sm:pr-0 md:pr-6 lg:pr-6 xl:pr-6"
              input_field={{
                identifier: "ssn_last_four",
                min_length: validation.ssn_last_four.min_length,
                max_length: validation.ssn_last_four.max_length,
                validation_regex: validation.ssn_last_four.regex,
                is_editable: true
              }}
              label={"last Four of your SSN"}
              setShowButton={(val: boolean) => onInputValidity("ssn_last_four", val)}
              placeholder={"Enter last Four of SSN"}
              setIndividualFailure={setIndividualFailure}
              individualFailure={individualFailure}
              index={0}
              secureEyeTestId="eye-testid"
            />}
            {validation?.dob && <DateInputType
              input_field={{ "identifier": 'dob' }}
              label={"Date of Birth"}
              wpStyleClass={`w-full x-sm:pl-0 sm:pl-0 ${validation?.ssn_last_four ? 'md:pl-6 lg:pl-6 xl:pl-6' : ''}`}
              key={""}
              testId="dob"
              setShowButton={(val: boolean) => onInputValidity("dob", val)}
            />}
          </div>
          <div className="md:mt-2 lg:mt-2 xl:mt-2 x-sm:mb-8 sm:mb-8 md:mb-16 lg:mb-16 xl:mb-16">
            <p className="text-1-heading-3">{capture_dob_ssn?.dob_ssn_tnc ?? capture_additional_data?.additional_data_tnc}</p>
          </div>
        </form>
        <div className="text-center">
          {!submitting && <BtnNext data-testid="next-btn" className={!showButton ? 'opacity-25 inline-block pointer-events-none' : ''} onClick={handleSubmit} />}
          {submitting && <BtnSpinnerNext />}
        </div>
      </div>
    </div>
  );
}

export default CaptureSignInSSNDBO;