import React from "react";
import {IMAGE_PREFIX} from "../constants/urls.constants";
import { isOnboardingRefreshV2Enabled } from "../utils/helpers";
import { navLogo } from "../utils/constants";

function Logo() {
  let partnerName = window['appConfig'].PARTNER_NAME;
  let imgName = navLogo[partnerName];
  let logoUrl = `${IMAGE_PREFIX()}/${imgName}`;
  return (<img src={logoUrl} alt="logo" className="mx-auto x-sm:w-9/12 nv-logo"/>);
}

export default Logo;
