export const setAMCThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#FF2532");
    document.body.style.setProperty("--text-h1", "38px");
    document.body.style.setProperty("--primary-font", "Gordita");
    document.body.style.setProperty("--secondary-font", "Gordita");
    document.body.style.setProperty("--theme-input_label_font_family", "Gordita");
    document.body.style.setProperty("--theme-input_font_family", "Gordita");
    document.body.style.setProperty("--theme-background", "#442D30");

    document.body.style.setProperty("--text-t1-size", "30px");
    document.body.style.setProperty("--text-t1-weight", "700");
    document.body.style.setProperty("--text-t1-color", "#d4d4d4");
    document.body.style.setProperty("--text-t1-font", "Gordita");
    document.body.style.setProperty("--text-t1-lh", "31px");

    document.body.style.setProperty("--text-t2-size", "18px");
    document.body.style.setProperty("--text-t2-weight", "400");
    document.body.style.setProperty("--text-t2-color", "#B3B3B3");
    document.body.style.setProperty("--text-t2-font", "sf-ui-display");
    document.body.style.setProperty("--text-t2-lh", "21px");

    document.body.style.setProperty("--text-t3-size", "10px");
    document.body.style.setProperty("--text-t3-weight", "400");
    document.body.style.setProperty("--text-t3-color", "#FFFFFF");
    document.body.style.setProperty("--text-t3-font", "sf-ui-display");
    document.body.style.setProperty("--text-t3-lh", "12px");
    document.body.style.setProperty("--text-t3-op", "0.6");

    document.body.style.setProperty("--theme-cardaction-error", "#FFFFFF");

    document.body.style.setProperty("--theme-color-rgb", "239, 47, 58");
    document.body.style.setProperty("--theme-color", "#FF2532");
    document.body.style.setProperty("--progress-line-over-color", "#FF2532");
    document.body.style.setProperty("--text-color", "#FFFFFF");
    document.body.style.setProperty("--text2-color", "#B3B3B3");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#000000");
    document.body.style.setProperty("--theme-error", "#D2353F");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background-dark", "#D2353F");
    document.body.style.setProperty("--theme-background-image", "url(https://dfcweb-assets-static.deserve.com/images/hotlink-ok/amc/amc-bg-image.png)");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#FF2532");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#FF2532");
    document.body.style.setProperty("--theme-checkbox-tick-color", "#FFFFFF");
    document.body.style.setProperty("--theme-card-background", "#121010");
    document.body.style.setProperty("--theme-card-text", "#d4d4d4");
    document.body.style.setProperty("--slider-bg", "#E5E5E5");
    document.body.style.setProperty("--slider-off", '#211E1E');
    document.body.style.setProperty("--theme-typehead-menu-bg", "rgb(255, 255, 255)");
    document.body.style.setProperty("--sub-text-color", "#B3B3B3");
    document.body.style.setProperty("--theme-card-active-background", "#D2353F");
    document.body.style.setProperty("--theme-card-active-color", "#FFFFFF");
    // document.body.style.setProperty("--theme-dashboard-content", "#231841");
    document.body.style.setProperty("--theme-dashboard-t1", "#B3B3B3");
    document.body.style.setProperty("--theme-payment-success-block-background", "#272323");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(255, 37, 50, 0.16)");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(255, 37, 50, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#D4D4D4");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#FF2532");
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#D2353F");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(255, 37, 50, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#B3B3B3");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#B3B3B3");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "Gordita");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#d4d4d4");
};