import React, { ReactElement, useState } from "react";
import parse from 'html-react-parser';
type AccordionProps = {
  title: string;
  content: ReactElement | string;
};

export const Accordion = ({ title, content }: AccordionProps) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="accordion-item py-6">
      <div
        className="accordion-title flex justify-between cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <div>{parse(title)}</div>
        <div className="pl-4">{expanded ? "-" : "+"}</div>
      </div>
      {expanded && (
        <div
          className="accordion-content pt-4 pr-2 break-words"
        >
          {typeof content === 'object' ? content : parse(content)}
        </div>
      )}
    </div>
  );
};
