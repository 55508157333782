export const DEFAULT_PRODUCT = 'default';
export const PRODUCT_ALIAS_KEY = 'product_id_alias';
export const PRODUCT_ALIAS_HEADER = {key: 'product-id-alias'};
export const THEME_ID_HEADER = {key: 'theme_id'};
export const PRODUCT_ID_ALIAS = {
    CCBANK_PEAK: 'ccbank_peak',
    CCBANK_WARRIORS: 'ccbank_warriors',
    CCBANK_DEFAULT: 'ccbank_default'
}
export const CC_BANK_PRODUCTS = [PRODUCT_ID_ALIAS.CCBANK_PEAK, PRODUCT_ID_ALIAS.CCBANK_WARRIORS]
