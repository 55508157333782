import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Loading from "../components/Loading";
import ClientStorage from "../utils/client-storage";
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { usePlaidLink } from "react-plaid-link";
import { APPLICATION_BASE_URL } from "../constants/urls.constants";

interface PlaidOAuthProps {
  redirectURi: string
}

const PlaidOAuth: React.FC<PlaidOAuthProps> = (props) => {
  const { search } = useLocation();
  const history = useHistory();
  const link_token = ClientStorage.getLocal("link_token")

  function onSuccess(token: any, metadata: any) {
    track(EVENT.plaidConnectBankOauthStarted);
    ClientStorage.setLocal("bank_token", token)
    ClientStorage.setLocal("plaid-metadata", JSON.stringify(metadata));
    ClientStorage.setLocal("is_plaid_callback", "true")
    track(EVENT.plaidConnectBankOauthSuccess);
  };

  const onEvent = (event) => {
    if (event === "HANDOFF") {
      window.location.href = ClientStorage.getLocal("redirectUrl");
    }
  };

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: link_token,
    receivedRedirectUri: window.location.href,
    onSuccess,
    onEvent,
  };
  const { open, ready, error } = usePlaidLink(config);
  // automatically re-initialize Link
  useEffect(() => {
    history.replace('/apply-card');

    if (ready) {
      open();
      track(EVENT.plaidConnectBankViewed);
    }
  }, [ready, open]);

  return (
    <div className="content-container">
      <Loading />
    </div>
  );
}


export default PlaidOAuth;
