import React, { useState, useEffect } from "react";
import BtnNext from "../components/BtnNext";
import classnames from "classnames";
import IconUnCheck from "../components/IconUnCheck";
import IconCheck from "../components/IconCheck";
import emitter from "../utils/emitter";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import ResumeApplication from "./ResumeApplication";
import PDFViewer from "./PDFViewer";
import ReactHtmlParser from 'html-react-parser';

type Props = {
  workflow: object;
  handleSubmit: Function;
  currentStep: object;
};

function OppFiCHA(props: Props) {
  const [TermsAgreed, setTermsAgreed] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [saveAndContinue, setSaveAndContinue] = useState(false);
  const [isAgreementRead, setIsAgreementRead] = useState(false);

  const breadcrumbs = props.workflow["breadcrumbs"];
  const template = props.workflow["steps"][0]["ui_template_type"];
  const identifier = props.workflow["steps"][0].identifier;
  const step = props.workflow["steps"][0][template];
  const file_url = step.agreement_url;
  const consentDescription = step.user_consent_model.consent_description;

  useEffect(() => {
    emitter.emit("progress", { breadcrumbs, identifier });
  }, [breadcrumbs, identifier, props.workflow]);

  function handleSaveAndContinueApplication() {
    track(EVENT.onb_TNC_saved);
    setSaveAndContinue(true);
  }

  function handleSubmit() {
    let data = { accepted_pre_card_holder_agreement: TermsAgreed };

    if (TermsAgreed) {
      track(EVENT.onb_TNC_accepted);
      setSubmitting(true);
      props.handleSubmit(data).catch(() => setSubmitting(false));
    }
  }

  const btn_classes = classnames(
    "mt-3 text-white block text-center text-xl tracking-tight button-with-custom-text h-16 p-5 cursor-pointer w-72",
    { "opacity-25": !TermsAgreed }
  );


  function onUserReadPDF(done: boolean) {
    setIsAgreementRead(done);
  }


  if (!saveAndContinue) {
    return (<div className="">
      <p className="text-main font-semibold x-sm:mt-10 x-sm:mb-10">{step.default_state.title}</p>
      <PDFViewer
        pdfURL={file_url}
        onUserReadPDF={onUserReadPDF}
      />
      {isAgreementRead && <div className="mt-4">
        <div className="flex flex-row mb-10 w-full space-x-4">
          <span>
            <input
              type="checkbox"
              name="user-consent"
              id="oppfi-user-consent-checkbox"
              className="invisible absolute"
              onChange={() => {
                setTermsAgreed((e) => !e);
              }}
            />
            <label className="df-icon-unchecked" htmlFor="oppfi-user-consent-checkbox">
              <IconUnCheck />
            </label>
            <label className="df-icon-checked" htmlFor="oppfi-user-consent-checkbox">
              <IconCheck />
            </label>
          </span>
          <label className="text-xs txt-type-para">
            {ReactHtmlParser(consentDescription)}
          </label>
        </div>
        <div className="flex flex-row space-x-2">
          {!submitting && (
            <>
              <BtnNext
                onClick={handleSaveAndContinueApplication}
                text={step.second_action_title}
                className="mt-3 text-white block text-center text-xl tracking-tight consent-button-cancel h-16 p-5 cursor-pointer w-72 rounded-full"
              />
              <BtnNext
                onClick={handleSubmit}
                {...(!TermsAgreed && { disabled: true })}
                text={step.first_action_title}
                className={btn_classes}
              />
            </>
          )}
          {submitting && <BtnSpinnerNext />}
        </div>
      </div>}
    </div>);
  } else {
    return <ResumeApplication workflow={props.workflow} />
  }
}

export default OppFiCHA;
