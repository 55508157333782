import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import emitter from '../utils/emitter';
import {APPLICATION_BASE_URL, IMAGE_PREFIX, LOGIN_URL} from "../constants/urls.constants";
import {clearApplicationRefreshToken, clearApplicationToken} from "../utils/helpers";

type Props = { workflow: Object };

function ResumeApplication(props: Props) {
  const template = props.workflow["steps"][1]["ui_template_type"]
  const step = props.workflow["steps"][1][template];
  const imageName = `${IMAGE_PREFIX()}/${step.image.name}.${step.image.type}`;
  const host = LOGIN_URL;
  const breadcrumbs = props.workflow['breadcrumbs'];
  const identifier = props.workflow["steps"][0].identifier;

  useEffect(() => {
    emitter.emit('progress', { breadcrumbs, identifier });
  }, [breadcrumbs, identifier]);

  const handleExit = (e) => {
    e.preventDefault();
    clearApplicationToken();
    window.location.href = host;
  }

  const handleResume = (e) => {
    e.preventDefault();
    window.location.href = APPLICATION_BASE_URL;
  }

  return (
    <div className=''>
      <div className='text-main'>
        <p className='text-center text-3xl md:text-4xl pt-9 md:pt-0'>
          {step.default_state.title}
        </p>
      </div>
      <div className='pt-9'>
        <img className='mx-auto xl:w-7/12 lg:w-3/5' src={imageName} alt='' />
      </div>
      <p className='pt-7 text-center text-lg font-light tracking-tight px-5'>
        {step.description}
      </p>
      <div className='md:flex md:flex-row-reverse md:justify-center mx-auto pt-10 text-white'>
        <div className='md:w-6/12 text-center text-xl tracking-tight p-2 md:p-5 cursor-pointer'>
          <button
            className="w-72 consent-button-submit rounded-full py-3 px-24 text-white"
            onClick={handleResume}>{step.default_state.first_action_title}
          </button>
        </div>

        <div className='md:w-6/12 text-center text-xl tracking-tight p-2 md:p-5 cursor-pointer'>
          <button
            className="w-72 consent-button-cancel rounded-full py-3 px-24"
            onClick={handleExit}>{step.default_state.second_action_title}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResumeApplication;
