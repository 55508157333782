import React from "react";

function IconStatements({fillColor = "rgba(0, 0, 0, 0.02)"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="42"
      fill="#fff"
      viewBox="0 0 42 42"
    >
      <rect
        width="39"
        height="39"
        x="1.5"
        y="1.5"
        fill="#221f20"
        stroke="#221f20"
        opacity="0.05"
        rx="19.5"
      ></rect>
      <rect
        width="41"
        height="41"
        x="0.5"
        y="0.5"
        stroke="#CCCCCC"
        rx="20.5"
        fill={fillColor}
      ></rect>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M28.331 10.438h-2.656v-.411c0-.514-.531-1.027-1.063-1.027h-7.225c-.53 0-1.062.513-1.062 1.027v.41h-2.656c-.638 0-1.169.514-1.169 1.13V30.87c0 .617.531 1.13 1.169 1.13H28.33c.638 0 1.169-.513 1.169-1.13V11.567c0-.616-.531-1.13-1.169-1.13zm-11.05-.411c0-.103 0-.103 0 0l7.331-.103c.107 0 .107 0 .107.103v1.848c0 .103 0 .103-.107.103h-7.225c-.106 0-.106 0-.106-.103zM28.437 30.87c0 .103 0 .103-.106.103H13.67c-.107 0-.107 0-.107-.103V11.567c0-.103 0-.103.107-.103h2.656v.411c0 .513.531 1.027 1.063 1.027h7.224c.532 0 1.063-.514 1.063-1.027v-.41h2.656c.106 0 .106 0 .106.102z"
        clipRule="evenodd"
      ></path>
      <g fill="#221f20">
        <path d="M26.445 25.084H15.556c-.374 0-.68.262-.68.583 0 .32.306.583.68.583h10.89c.373 0 .68-.262.68-.583 0-.321-.307-.583-.68-.583zM26.445 27.416H15.556c-.374 0-.68.263-.68.584 0 .32.306.583.68.583h10.89c.373 0 .68-.262.68-.583 0-.321-.307-.583-.68-.583zM21.256 15.326v.43c.272.028.504.087.697.174.196.088.366.22.51.398.115.135.202.274.263.417a.98.98 0 01.096.394.483.483 0 01-.141.345.446.446 0 01-.335.143c-.25 0-.41-.14-.484-.421a.885.885 0 00-.606-.66V18.2a8.1 8.1 0 01.75.246c.188.074.356.182.503.322.158.146.278.32.362.525.087.201.13.423.13.664 0 .302-.069.585-.206.85a1.64 1.64 0 01-.598.644 2.073 2.073 0 01-.941.298v.802c0 .156-.015.27-.046.342-.028.071-.091.107-.19.107-.092 0-.156-.03-.194-.087a.533.533 0 01-.054-.27v-.886a2.347 2.347 0 01-.78-.218 1.792 1.792 0 01-.884-.93 1.435 1.435 0 01-.107-.53c0-.126.047-.24.14-.341a.47.47 0 01.359-.155c.114 0 .21.028.29.084a.43.43 0 01.163.234c.069.217.128.384.18.5.05.115.126.22.228.315.104.095.241.168.411.218v-1.848a4.642 4.642 0 01-.853-.326 1.475 1.475 0 01-.556-.517c-.143-.222-.214-.508-.214-.858 0-.456.139-.83.415-1.121.28-.291.682-.461 1.208-.509v-.421c0-.223.08-.334.24-.334.163 0 .244.109.244.326zm-.484 2.727V16.53a1.31 1.31 0 00-.499.263c-.12.108-.179.273-.179.493 0 .209.056.368.168.476.111.106.282.203.51.29zm.484 1.18v1.741c.257-.053.455-.16.594-.322.14-.161.21-.35.21-.564 0-.23-.069-.408-.206-.533-.135-.127-.334-.234-.598-.322z"></path>
      </g>
    </svg>
  );
}

export default IconStatements;
