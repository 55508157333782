/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useCallback } from "react";
import BtnNext from "../components/BtnNext";
import { useForm } from "react-hook-form-lts";
import { useLocation } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";
import { parse } from "query-string";
import { post } from "../utils/client";
import { track } from "../utils/analytics";
import { loginMessage, OtpTimers } from "../utils/constants";
import { workflow } from '../constants/workflow.constants';

import {
  EXISTING_ACCOUNT_PAGE_URL,
  EMAIL_OTP_TRIGGER_ENDPOINT_V3,
  EMAIL_OTP_VERIFY_ENDPOINT_V3,
} from "../constants/urls.constants";
import BtnSpinnerNext from "../components/BtnSpinnerNext";

import { logErrorToSentry, getNewSignInCommonEventProps, redirectTo, setSessionId } from "../utils/helpers";
import { EVENT } from "../constants/events.constants";
import ClientStorage from "../utils/client-storage";
import { AxiosError } from "axios";

type Props = {
  query: string;
  stepData: any;
  workflowSubmitCb: Function;
};

function SignInVerifyEmailOtp(props: Props) {
  const appConfig = window['appConfig'];
  const OTP_time: number = props?.stepData?.next_screen?.capture_email_otp?.timer;
  const location: any = useLocation();
  const query = parse(location.search.replace("?", ""));
  const isSigninFlow = query.flow ? true : false;
  const [hasError, setHasError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [IsInvalidOTP, setIsInvalidOTP] = useState(false);
  const [OTPExpired, setOTPExpired] = useState(false);
  const [HasFailed, setHasFailed] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(true);
  const [counter, setCounter] = useState(OTP_time);
  const resendOtpLinkRef = useRef(null);
  const isInitialMount = useRef(true);
  const cachedPhoneNumber = ClientStorage.getLocal("phone_number");
  const {
    register,
    handleSubmit,
    reset,
    formState: {
      isValid
    }
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const { capture_email_otp: { validation, otp_help_text } } = props.stepData.next_screen;
  const invalid_otp_str = `Something went wrong. Please try again.`;
  const otp_expired_str = `Your code expired. Please try again.`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function resetForm() {
    reset();
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      track(
        EVENT.dfc_auth_email_otp_screen,
        getNewSignInCommonEventProps()
      );
    }
  }, []);

  useEffect(() => {
    let intervalId: any;

    if (counter === 0) {
      track(EVENT.dfc_auth_email_otp_expired, {
        ...getNewSignInCommonEventProps()
      });
      setOTPExpired(true);
      resetForm();
      setShowSubmitBtn(false);
      clearInterval(intervalId);
      return;
    }

    intervalId = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [counter]);


  const resendEmailOTP = useCallback(
    () => {
      let maskedEmailPattern = /(\.){3,}([aA-zZ,0-9]){1}@/ig;
      return new Promise((resolve, reject) => {
        let body: any = {
          email: props.stepData?.email,
          tenant_id: window['appConfig'].PROGRAM_ID,
        };

        if (isSigninFlow || body?.email?.match(maskedEmailPattern)) {
          body = {
            ...body,
            phone: ClientStorage.getLocal("non_cached_phone_number"),
            otp: ClientStorage.getLocal("phone_otp_code"),
            "is_resend": true,
          };
        }

        track(EVENT.dfc_auth_email_otp_resend_clicked, {
          ...getNewSignInCommonEventProps(),
        });

        post(EMAIL_OTP_TRIGGER_ENDPOINT_V3, body)
          .then((data) => {
            track(EVENT.dfc_auth_email_verify_otp_succeed, {
              ...getNewSignInCommonEventProps(),
            });
            resolve(data);
          })
          .catch((err: AxiosError) => {
            logErrorToSentry('signin_resend_email_otp_failed', body, err);

            track(EVENT.dfc_auth_email_verify_otp_failed, {
              ...getNewSignInCommonEventProps(),
              response: err?.response?.data
            });
            reject(err);
          });
      });
    },
    [props.stepData?.email]
  );

  const resendCodeCB = useCallback(
    (e: any) => {
      e.preventDefault();

      const resendOtpLink = resendOtpLinkRef.current;

      if (resendOtpLink) {
        const disableResendOtpClassList = ["pointer-events-none", "opacity-40"];
        resendOtpLink.classList.add(...disableResendOtpClassList);

        setTimeout(() => {
          resendOtpLink.classList.remove(...disableResendOtpClassList);
        }, OtpTimers.resend_otp_timer * 1000);
      }
      setSubmitting(true);
      resendEmailOTP()
        .then((data) => {
          setSessionId(data);
          setCounter(OTP_time)
          setShowSubmitBtn(true);
          setHasFailed(false);
          setOTPExpired(false);
          setIsInvalidOTP(false);
          setHasError(false);
          reset();
        })
        .catch(() => {
          setHasFailed(true);
          setHasError(true);
        })
        .finally(() => {
          setSubmitting(false);
        })
    },
    []
  );

  function handleFormSubmit({ otp_code }) {
    track(EVENT.dfc_auth_email_otp_next_clicked, {
      ...getNewSignInCommonEventProps(),
      otp_code: otp_code
    });

    if (isValid) {
      setSubmitting(true);
      verifyEmailOTP(otp_code);
    } else {
      setHasError(true);
    }
  }

  function verifyEmailOTP(otp_code: any) {
    const _phone_otp_code = ClientStorage.getLocal("phone_otp_code");
    let body = {
      otp: otp_code,
      email: props.stepData?.email,
      ...props.stepData ? {
        session_id: props.stepData?.session_id,
      } : {},
      phone: ClientStorage.getLocal("non_cached_phone_number") ?? props.stepData?.phone,
      ...(cachedPhoneNumber && !_phone_otp_code) ? {
        phone_otp: null,
        refresh_token: ClientStorage.getLocal('application_refresh_token')
      } : {
        phone_otp: _phone_otp_code,
      }
    };

    post(EMAIL_OTP_VERIFY_ENDPOINT_V3, body)
      .then((data) => {
        track(
          EVENT.dfc_auth_email_verify_otp_succeed, {
          ...getNewSignInCommonEventProps(),
        }
        );
        setHasError(false);
        props.workflowSubmitCb({
          ...props?.stepData,
          ...data,
          phone: props?.stepData?.phone
        });
        setSessionId(data);
        onOTPSubmitSuccess(data);
        return data;
      })
      .catch((errRes) => {
        const errorData = errRes?.response?.data || null;

        logErrorToSentry('signin_resend_email_otp_failed', body, errRes);

        track(
          EVENT.dfc_auth_email_verify_otp_failed, {
          ...getNewSignInCommonEventProps(),
          response: errorData
        }
        );

        if (errorData) {
          if (errorData.code !== "invalid_otp") {
            props.workflowSubmitCb({
              ...errorData,
              ...props?.stepData,
              title: errorData?.error?.display_message || errorData?.message,
              subtitle: errorData?.error?.display_description,
              next_screen: {
                identifier: errorData.code,
              }
            });
          } else if (errorData?.code === "invalid_otp") {
            setShowSubmitBtn(false);
            props.workflowSubmitCb(props.stepData);
          } else {
            props.workflowSubmitCb(props.stepData);
          }
        }
        onOTPSubmitFail(errRes);
      });
  }

  function onOTPSubmitSuccess(data: any) {
    setSubmitting(false);
    setShowSubmitBtn(true);
    if (data?.code) {
      if (data.code === "invalid_otp") {
        setIsInvalidOTP(true);
        return null;
      }
      if (data.code === "expired_otp") {
        setOTPExpired(true);
        resetForm();
        return null;
      }
    }
  }

  function onOTPSubmitFail(err: any) {
    setSubmitting(false);
    switch (err?.response?.data?.code) {
      case "invalid_otp":
        setIsInvalidOTP(true);
        break;
      case "expired_otp":
        setIsInvalidOTP(true);
        setOTPExpired(true);
        resetForm();
        break;
      default:
        setHasFailed(true);
        break;
    }
  }

  let seconds = "0";
  const Minutes = Math.floor(counter / 60);
  let secNum = counter - Minutes * 60;

  seconds = secNum < 10 ? "0" + secNum.toString() : secNum.toString();

  function renderExpireView() {
    let otp_help_data = otp_help_text.split("?");
    let not_get_code_text = otp_help_data[0];
    let timer_text_data = otp_help_data[1].split("%s");

    if (hasError) {
      return (
        <p className="txt-type-para font-b10 py-2 theme-text-color text-2-heading text-t2-color">
          {workflow.otp.sendTheOtpTo}{" "} {props.stepData?.email}
        </p>
      );
    }

    if (counter !== 0 && !OTPExpired) {
      return (
        <>
          {!isSigninFlow && <p className="txt-type-para font-b10 py-2 theme-text-color text-2-heading text-t2-color text-b7" data-testid="signup-resend-text">
            {workflow.otp.resendCodeMessage}{" "}
            <br />
            {workflow.otp.codeExpiration} {Minutes}:{seconds}
          </p>}


          {isSigninFlow && <p className="txt-type-para font-b10 py-2 theme-text-color text-2-heading text-t2-color text-b7">
            {not_get_code_text}?
            <span>{ReactHtmlParser(timer_text_data[0])} {Minutes}:{seconds}<br /> {ReactHtmlParser(timer_text_data[1])}</span>
            <br />
          </p>}
        </>
      );
    }

    return null;
  }

  return (
    <div id="verify_email_otp" className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page`}>
      {(IsInvalidOTP && !OTPExpired) && <p className="text-main mb-10 text-1-heading text-headline-h1" data-testid="invalid-otp">{invalid_otp_str}</p>}
      {OTPExpired && <p className="text-main mb-10 text-1-heading text-headline-h1" data-testid="otp-expired">{otp_expired_str}</p>}
      {HasFailed && <p className="text-main mb-10 text-1-heading" data-testid="otp-failed">Failed</p>}
      {!IsInvalidOTP && !OTPExpired && !HasFailed && <p className="text-main mb-10 text-1-heading text-headline-h1" data-testid="text-main">Check your email</p>}
      {(!IsInvalidOTP && !OTPExpired) && <p className="text-sm txt-type-para font-normal text-2-heading text-t2-color text-b3" data-testid="text-subtitile">{ReactHtmlParser(loginMessage('emailSubTitle'))}{props.stepData?.email}</p>}
      <div className="content-container mt-8">
        <form onSubmit={handleSubmit(handleFormSubmit)} data-testid="verify-email-otp-form">
          <div className="pb-4 relative">
            <div className="m-auto">
              <label>
                <div
                  className="uppercase tracking-wide field-caption text-c2"
                  data-testid="field-caption">Enter code</div>
                <input
                  type="text"
                  className="bg-transparent w-full h-12 text-3xl border-b-2 outline-none text-field text-b1"
                  data-testid="otp_code"
                  placeholder="Enter code"
                  maxLength={validation?.email_otp?.max_length}
                  minLength={validation?.email_otp?.min_length}
                  {...register("otp_code", {
                    required: true,
                    maxLength: validation?.email_otp?.max_length,
                    minLength: validation?.email_otp?.min_length,
                    pattern: new RegExp(validation?.email_otp?.regex, 'g'),
                    disabled: OTPExpired
                  })}
                />
              </label>
              <p className={`${hasError ? "visible" : "invisible"} error-block text-xs absolute my-2`} data-testid="error-label">
                {workflow.otp.enterValidCode}
              </p>
            </div>

            <div className={`${hasError ? "mt-12" : "mt-8"}`}>{renderExpireView()}</div>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

            {(counter !== 0 || !HasFailed) && showSubmitBtn && (
              <a
                href="#"
                onClick={resendCodeCB}
                className="font-bn4 color-theme my-6 text-small-btn inline-block"
                ref={resendOtpLinkRef}
                data-testid="resend-code-link">
                {workflow.otp.resendCode}
              </a>
            )}
          </div>

          {!submitting && <div className="text-center">
            {showSubmitBtn
              ?
              (<BtnNext data-testid="next-btn" className={!isValid ? 'opacity-25 inline-block' : ''} onClick={handleSubmit(handleFormSubmit)} />)
              :
              (<div className='text-center text-xl tracking-tight p-2 md:p-5'>
                <button
                  className='consent-button-cancel rounded-full py-6 px-32 x-sm:px-24 text-small-btn brand-btn-solid'
                  onClick={resendCodeCB}
                  data-testid="resend-code-btn"
                >
                  {workflow.otp.resendCode
                  }</button>
              </div>)
            }
          </div>}
          {submitting && <BtnSpinnerNext />}
        </form>
      </div>
    </div>
  );
}

export default SignInVerifyEmailOtp;
