import PaymentCard from "./PaymentCard";


const PaymentCards = ({
    cards,
    selected,
    set_selected,
} : {
    cards: any,
    selected: any,
    set_selected: (txt) => void
}) => {

    return (
        <div className="h-54 flex flex-col lg:flex-row justify-center lg:justify-between">
        {selected ? cards.map((card) => {
            if(card.is_hidden) {
                return null;
            }
            return (
                <PaymentCard
                    key={card.key}
                    card={card}
                    selected={selected} 
                    updateCard={(card) => {
                        set_selected(card)
                    }} 
                    enable_selection={card.allow_selection}
                    amount={card.amount}
                />
            );
        }) : null}
    </div>    
    )
    
}

export default PaymentCards;
