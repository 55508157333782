import React, { useEffect } from "react";
import emitter from '../utils/emitter';
import { track } from "../utils/analytics";

import { IMAGE_PREFIX } from "../constants/urls.constants";
import DollarIcon from "../components/DollarIcon";
import { numberFormatter } from "../utils/numberFormatter";
import { autoPoll } from "../utils/auto_poll";
import { isOnboardingRefreshV2Enabled } from "../utils/helpers";

type Props = {
  breadcrumbs: Object,
  workflow: Object,
  setWorkflow: Function,
  currentStep: Object | any
};

function DocumentsStatus(props: Props) {
  const template = props.workflow["steps"][0]["ui_template_type"]
  const step = props.workflow["steps"][0][template];
  const title = step.default_state.title;
  const subtitle = step.subtitle;
  const description = step.description;

  const onbUploadingGif = `${IMAGE_PREFIX()}/${step.image.name}.${step.image.type}`

  useEffect(() => {
    const intervalId = props.workflow["steps"][0].auto_poll && autoPoll(props.workflow, props.setWorkflow);

    emitter.emit('progress', {
      breadcrumbs: props.breadcrumbs,
      identifier: props.currentStep.step_info.step_identifier,
    });

    return () => {
      clearInterval(intervalId);
    }
  }, [props.breadcrumbs, props.currentStep, props.setWorkflow, props.workflow]);

  return (
    <div className="absolute top-28 left-0 right-0 flex flex-1 items-center document-status">
      <div className="content-container m-auto x-sm:w-full">
        <p data-testid="subtitle" className='text-subtitle text-main text-center z-20 mt-0 sm:mt-4 mb-0 px-40 x-sm:p-0'>{subtitle}</p>
        <img src={onbUploadingGif} data-testid="onbUploadingGif" alt="onbUploadingGif" className="x-sm:w-80 md:w-96 mx-auto m-auto" />
        {description && <p data-testid="description" className='text-base text-center z-20 mb-2 mt-1'>{description}</p>}
      </div>
    </div>
  );
}

export default DocumentsStatus;
