import React, {useEffect} from 'react';
import BtnSpinnerNext from '../components/BtnSpinnerNext';

type Props = {
  handleSubmit: Function,
  currentStep: any
};

function RefreshToken(props: Props) {
  const { handleSubmit, currentStep } = props;
  const template = currentStep["ui_template_type"];
  const step = currentStep[template];

  const partnerName = window['appConfig'].PARTNER_NAME;

  useEffect(() => {
    handleSubmit({});
  }, [handleSubmit]);

  return (
    <div className={`app-container-${partnerName} onb-workflow-page`}>
      <p className="text-main mb-10 text-center">{step.upload_state.title}</p>
      <div className="content-container">
      <BtnSpinnerNext />
      </div>
    </div>
  );
}

export default RefreshToken;
