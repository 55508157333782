
import React from "react";
type Props = {
  color?: string
};
function DownloadArrowIcon(props: Props) {
  const { color } = props;
  return (
    <svg width="34" height="49" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.463 36.32a2.174 2.174 0 003.074 0l13.835-13.835a2.174 2.174 0 10-3.075-3.074L17 31.708 4.702 19.411a2.174 2.174 0 00-3.074 3.074L15.463 36.32zM14.826 0v34.783h4.348V0h-4.348z" fill={color || "#fff"} /><path stroke={color || "#fff"} strokeWidth="4.348" strokeLinecap="round" strokeLinejoin="round" d="M9.391 46.739h16.304" /></svg>
  );
}

export default DownloadArrowIcon;