const PrimaryButton = ({ children, className, ...props }) => {
    return (
        <button
            className={`bg-primary-tw py-2 px-4 ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};

export default PrimaryButton;
