import { getAssetsPath } from "../utils/utils";

export const APP_CONFIG = window['appConfig'] || {};
export const LOGIN_URL = window.location.origin || 'https://deserve.com';
export const APPLICATION_BASE_URL = '/apply-card';

export const LOGIN_EMAIL_V3_API = `${APPLICATION_BASE_URL}/api/v3/user/login/email`;
export const EMAIL_SUBMIT_OTP_V3_API = `${APPLICATION_BASE_URL}/api/v3/user/login/email/submit_otp`;

export const PHONE_TRIGGER_ENDPOINT_V3 = `${APPLICATION_BASE_URL}/api/v3/phone/trigger`;
export const PHONE_OTP_VERIFY_ENDPOINT_V3 = `${APPLICATION_BASE_URL}/api/v3/phone/verify/otp`;

export const EMAIL_OTP_TRIGGER_ENDPOINT_V3: string = `${APPLICATION_BASE_URL}/api/v3/trigger/email/otp`;
export const EMAIL_OTP_VERIFY_ENDPOINT_V3: string = `${APPLICATION_BASE_URL}/api/v3/verify/email/otp`;

export const VERIFY_USER_BY_SSN_DOB_API_V3: string = `${APPLICATION_BASE_URL}/api/user/v3/verify-ssn-dob`;

export const ERROR_PAGE_URL = `${APPLICATION_BASE_URL}/error`
export const REFRESH_TOKEN = `${APPLICATION_BASE_URL}/api/token/refresh`
export const EXISTING_ACCOUNT_PAGE_URL = `${APPLICATION_BASE_URL}/existing-account`
export const NEXT_STEPS_URL = `${APPLICATION_BASE_URL}/next-steps`
export const METADATA_API = `${APPLICATION_BASE_URL}/api/meta-data`
export const PHONE_API = `${APPLICATION_BASE_URL}/api/phone`
export const INCOME_API = `${APPLICATION_BASE_URL}/api/income`
export const HOUSING_API = `${APPLICATION_BASE_URL}/api/housing`
export const ADDITIONAL_INCOME_API = `${APPLICATION_BASE_URL}/api/additional-income-detail`
export const LOGIN_API = `${APPLICATION_BASE_URL}/api/login`
export const VALIDATE_TOKEN_ENDPOINT = `${APPLICATION_BASE_URL}/api/token/validate`
export const CREDIT_PULL_CONSENT_ENDPOINT = `${APPLICATION_BASE_URL}/api/credit-pull-consent`
export const WORKFLOW_ENDPOINT = `${APPLICATION_BASE_URL}/api/workflow`
export const CREDIT_BUREAU_FREEZE_STATE_ENDPOINT = `${APPLICATION_BASE_URL}/api/update-credit-bureau-freeze-state`
export const PHONE_TRIGGER_ENDPOINT = `${APPLICATION_BASE_URL}/api/phone/trigger`
export const ADD_BANK_ENDPOINT = `${APPLICATION_BASE_URL}/api/add-bank`
export const GET_PAYMENT_METHOD = `${APPLICATION_BASE_URL}/api/get-payment`
export const SET_PAYMENT_METHOD = `${APPLICATION_BASE_URL}/api/set-payment`
export const PAYMENT_API: string = `${APPLICATION_BASE_URL}/api/make-payment`;
export const GET_SCHEDULED_PAYMENTS: string = `${APPLICATION_BASE_URL}/api/get-scheduled-payments`;
export const GET_AUTOPAY_STATUS: string = `${APPLICATION_BASE_URL}/api/payments`;
export const CANCEL_AUTOPAY: string = `${APPLICATION_BASE_URL}/api/cancel-autopay`;
export const UPDATE_AUTOPAY: string = `${APPLICATION_BASE_URL}/api/update-autopay`;
export const GET_LINK_TOKEN = `${APPLICATION_BASE_URL}/api/link-token`
export const OTP_VERIFY_ENDPOINT = `${APPLICATION_BASE_URL}/api/phone/verify`
export const VAULT_CONFIG_ENDPOINT = `${APPLICATION_BASE_URL}/api/vault/config`
export const AUTOPOLL_ENDPOINT = `${APPLICATION_BASE_URL}/api/auto-poll`
export const GOOGLE_AUTH_VERIFY_API_URL: string = `${APPLICATION_BASE_URL}/api/google/callback`;
export const DASHBOARD_API_URL: string = `${APPLICATION_BASE_URL}/api/account`;
export const ACCOUNT_V3_API_URL: string = `${APPLICATION_BASE_URL}/api/v3/account`;
export const PAYMENT_V3_API_URL: string = `${APPLICATION_BASE_URL}/api/v3/payments`;
export const TRANSACTIONS_API_URL: string = `${APPLICATION_BASE_URL}/bff/api/transactions`;
export const STATEMENTS_API_URL: string = `${APPLICATION_BASE_URL}/bff/api/statement`;
export const PROFILE_API_URL: string = `${APPLICATION_BASE_URL}/bff/profile`;
export const STATEMENTS_DOWNLOAD_API_URL: string = `${APPLICATION_BASE_URL}/bff/api/downloadstatement`;
export const PROFILE_ADDRESS_UPDATE_API: string = `${APPLICATION_BASE_URL}/bff/api/profile/address`;
export const FREEZE_CARD_UPDATE_API: string = `${APPLICATION_BASE_URL}/bff/api/profile/card-action`;
export const EPHEMERAL_TOKEN_API_URL: string = `${APPLICATION_BASE_URL}/bff/ephemeral/token`;
export const SSN_TOKEN_API: string = `${APPLICATION_BASE_URL}/bff/onboarding/ssn-token`;

export const EMAIL_SIGIN_IN_WORKFLOW_ENDPOINT = `${APPLICATION_BASE_URL}/api/email-sign-in/workflow`;
export const SIGN_IN_BY_EMAIL_API_URL: string = `${APPLICATION_BASE_URL}/api/signin/by/email`;
export const VERIFY_EMAIL_OTP_API_URL: string = `${APPLICATION_BASE_URL}/api/verify/email/otp`;
export const REAPPLY_APPLICATION_API_URL: string = `${APPLICATION_BASE_URL}/bff/api/start-application`;

export const partnerName =  window['appConfig'] ? window['appConfig'].PARTNER_NAME : '';
export const IMAGE_BASE_URL = "https://dfcweb-assets-static.deserve.com/images/hotlink-ok";

/**
 * Dev Note: We should not use this calculation of image prefix here.
 * This is already done in TenantConfiguration class.
 * Ideally we should use TenantConfiguration class to get the image prefix all over places
 * using context
 * @returns
 */
export const IMAGE_PREFIX = () => {
  return getAssetsPath(IMAGE_BASE_URL, partnerName);
};


export const LOGOUT_URL: string = `${APPLICATION_BASE_URL}/bff/api/logout`;

export const ACCOUNT_CHA_ACCEPTANCE: string = `${APPLICATION_BASE_URL}/api/account/cha_acceptance`;
export const FETCH_FINICITY_INSTITUTIONS_URL: string = `${APPLICATION_BASE_URL}/api/finicity-institutions`;
export const FETCH_FINICITY_LITE_URL = (institution_id) : string =>  `${APPLICATION_BASE_URL}/api/connect-url/institution/${institution_id}`;
