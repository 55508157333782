export const dashboardConst = {
    statement: "statement",
    balance: "balance",
    available: "available",
    Last4Digit: "Last 4 Digits",
    credit: "credit",
    rewards: "rewards",
    minimum: "minimum",
    due: "due",
    btnAutoPay: "Set up Autopay",
    btnPay: "Pay",
    noDueDate: "Nothing to pay"
}