import React, { useState, useEffect } from 'react'
import BtnNext from '../components/BtnNext';
import { post } from "../utils/client";
import { SIGN_IN_BY_EMAIL_API_URL } from "../constants/urls.constants";
import BtnSpinnerNext from '../components/BtnSpinnerNext';
import { track, trackFailure } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { checkMarketingDataExpired, trackUTM } from '../utils/helpers';
import { workflow } from '../constants/workflow.constants';

// TODO: Run validations on paste

type Props = {
  currentStep: Object,
  onClick: Function
};

function EmailSignIn(props: Props) {
  const [emailId, setEmailId] = useState();
  const [hasError, setHasError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const ModelName = props.currentStep['ui_template_type'];
  const ModelData = props.currentStep[ModelName];
  const validation_regex = ModelData['input_fields'][0].validation_regex;

  function handleChange(event: any) {
    if (validate()) {
      handleBlur(event);
    }
    setEmailId(event.target.value);
  }

  function handleBlur(event: any) {
    if (validate() || !event.target.value) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  }

  function validate() {
    const isValidEntry = new RegExp(validation_regex).test(emailId);
    return isValidEntry;
  }

  function setErrors(errors?: any) {
    setSubmitting(false);
    setHasError(true);
  }

  useEffect(() => {
    if (localStorage.getItem('email')) {
      const mail = localStorage.getItem('email');
      localStorage.removeItem('email')
      setEmailId(JSON.parse(mail));
    }
  }, []);

  function handleFormSubmit(e: any) {
    e.preventDefault();
    
    let marketingAllData = trackUTM('marketingData');

    marketingAllData = checkMarketingDataExpired(marketingAllData);
    setSubmitting(true);
    if (validate()) {
      track(EVENT.onb_marketing_data_on_email_sign_in, marketingAllData);
      track(EVENT.onb_email_next_clicked);
      post(SIGN_IN_BY_EMAIL_API_URL, {
        email: emailId
      }, {
        headers: {
          ...marketingAllData ? {
            'x-marketing-url': marketingAllData?.marketing_url,
            'x-marketing-url-expires-at': marketingAllData?.expiresAt,
            'x-marketing-url-created-at': marketingAllData?.createdAt,
            'x-marketing-url-expired': marketingAllData?.expired
          } : {},
        }
      })
        .then((data: any) => {
          props.onClick({
            ...data,
            emailId: emailId
          });
          setSubmitting(false);
        })
        .catch((error: any) => {
          trackFailure(EVENT.onb_email_submit_failed, error);
          setErrors(error);
        });
    } else {
      setErrors();
    }
  }

  return (
    <div>
      <h1 className="text-main md:mb-1" data-testid="text-main">{ModelData.default_state.title}</h1>
      <p className="text-xs font-normal mb-8" data-testid="text-subtitle">{ModelData.default_state.subtitle}</p>
      <div className="content-container">
        <form onSubmit={handleFormSubmit}>
          <div className="pb-16 relative">
            <label className="">
              <div className="uppercase mb-2 tracking-wide field-caption" data-testid="field-caption">{ModelData.input_fields[0].title}</div>
              <input
                type="text"
                name="email_address"
                data-testid="email_address"
                className="bg-transparent w-full h-12 text-3xl border-b-2 outline-none text-field"
                defaultValue={emailId}
                autoComplete='off'
                onBlur={handleBlur}
                onChange={handleChange}
                required
              />
            </label>
            <p className={`${hasError ? 'visible' : 'invisible'} error-block text-xs absolute my-2`} data-testid="error-label">{workflow.email.emailErrorMessage}</p>
          </div>
          <div className="text-center">
            {!submitting && <BtnNext data-testid="next-btn" className={!validate() || hasError ? 'opacity-25 inline-block' : ''} onClick={handleFormSubmit} />}
            {submitting && <BtnSpinnerNext />}
          </div>
        </form>
      </div>
    </div>
  )
}

export default EmailSignIn;
