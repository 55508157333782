import React from "react";

function IconCheck(props) {
  function getThemeIcon(theme_name) {
    let color = getComputedStyle(document.body).getPropertyValue("--theme-color") || "#BC9966";

    return (
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="35" height="35" rx="5.5" stroke={color} />
        <path
          d="M10 18.375L16.4759 24.75L27 12"
          stroke={color}
          strokeWidth="4.08046"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <span {...props}>{getThemeIcon(window["appConfig"].PARTNER_NAME)}</span>
  );
}

export default IconCheck;
