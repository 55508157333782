export const setCustomersBankThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#009DFF");
    document.body.style.setProperty("--primary-font", "Poppins-Semibold");
    document.body.style.setProperty("--secondary-font", "Poppins-Regular");
    document.body.style.setProperty("--theme-input_label_font_family", "Poppins-Regular");
    document.body.style.setProperty("--theme-input_font_family", "Poppins-Regular");
    document.body.style.setProperty("--theme-color-rgb", "0, 157, 255");
    document.body.style.setProperty("--theme-color", "#009DFF");
    document.body.style.setProperty("--text-h1-sm", "26px");
    document.body.style.setProperty("--progress-line-over-color", "#009DFF");
    document.body.style.setProperty("--text-color", "#011936");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#011936");
    document.body.style.setProperty("--theme-error", "#009DFF");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background", "#FFFFFF");
    document.body.style.setProperty("--theme-background-dark", "#011936");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#009DFF");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#009DFF");
    document.body.style.setProperty("--theme-card-background", "#FFFFFF");
    document.body.style.setProperty("--theme-card-text", "#000000");
    document.body.style.setProperty("--slider-bg", "#E5E5E5");
    document.body.style.setProperty("--slider-off", '#E5E5E5');
    document.body.style.setProperty("--text-bold", "600");
    document.body.style.setProperty("--theme-payment-success-block-background", "#01264A");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#009DFF");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#009DFF");
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#009DFF");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(0, 157, 255, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgba(0, 157, 255, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#009DFF");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#1A1717");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#040D14");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "Poppins-Regular");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#000000");
};