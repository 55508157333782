export const setEarnestThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#21CF93");
    document.body.style.setProperty("--primary-font", "circular");
    document.body.style.setProperty("--secondary-font", "sf-ui-text");
    document.body.style.setProperty("--theme-input_label_font_family", "sf-ui-text");
    document.body.style.setProperty("--theme-input_font_family", "sf-ui-text");
    document.body.style.setProperty("--theme-color", "#21CF93");
    document.body.style.setProperty("--progress-line-over-color", "#21CF93");
    document.body.style.setProperty("--theme-color-rgb", "33, 207, 147");
    document.body.style.setProperty("--text-color", "#4E1BB4");
    document.body.style.setProperty("--text-t2-color", "#231841");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#4E1BB4");
    document.body.style.setProperty("--theme-background", "#F8F6FA");
    document.body.style.setProperty("--theme-background-dark", "#4E1BB4");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#4E1BB4");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#4E1BB4");
    document.body.style.setProperty("--theme-card-background", "#af9dd440");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(33, 207, 147, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#21CF93");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(33, 207, 147, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "rgba(33, 207, 147, 1)");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(33, 207, 147, 1)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgba(33, 207, 147, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "rgba(78, 27, 180, 1)");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "rgba(78, 27, 180, 1)");
};