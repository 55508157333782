import React from "react";
type Props = {
  onClick?: Function;
  fillColor?: string;
};
export function ArrowDown(props: Props) {
  const { onClick = () => {} } = props;
  const fillColor = props.fillColor || "#534B4B";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" fill="none">
      <g fill="#272525" clipPath="url(#a)">
        <path d="M7.275 8.62c0-.135.045-.27.14-.38L13.985.7a.578.578 0 0 1 .875.755l-6.57 7.54a.578.578 0 0 1-1.015-.38v.005Z" fill={fillColor} />
        <path d="M.705 1.08A.578.578 0 0 1 1.72.7l6.57 7.54a.578.578 0 0 1-.87.76L.85 1.455a.577.577 0 0 1-.14-.38l-.005.005Z" fill={fillColor} />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M15 .5v8.7H.705V.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
