import React, {useState} from "react";
import {FirstCharUpperCase} from "../utils/text";
import {workflow} from "../constants/workflow.constants";
import {formatMobileNumber, unFormatMobileNumber} from "../utils/mobileNumberFormatter";

type Props = { input_field: Object; label: string; name: string; setShowButton: Function, defaultValue: string };

export function MobileInputType(props: Props) {
  const [HasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [value, setValue] = useState(props.defaultValue);


   function unFormattedPhoneNumber(newValue) {
    if (newValue && newValue.length) {
      return unFormatMobileNumber(newValue);
    } else {
      return null;
    }
  }

  function validate(newValue) {
    const phoneNumber = unFormattedPhoneNumber(newValue)
    let inputField = props.input_field;
    const hasUScountryCode = phoneNumber && new RegExp(/^1/).test(phoneNumber.toString());
    const isValidEntry = phoneNumber &&  new RegExp(inputField['validation_regex']).test(phoneNumber.toString());
    const isInRange = phoneNumber > 1 && phoneNumber.toString().length >= inputField['min_length'] && `+1${phoneNumber.toString()}`.length <= inputField['max_length'];

    return phoneNumber && !hasUScountryCode && isValidEntry && isInRange;
  }

  function handleChange(e, setShowButton) {
    let newValue = e.target.value;
    const phoneNumberString = unFormattedPhoneNumber(newValue) || "";
    
    if (validate(newValue)) {
      setValue(formatMobileNumber(phoneNumberString));
      setShowButton(true)
      setHasError(false);
    } else {
      setValue(formatMobileNumber(phoneNumberString));
      setShowButton(false)
      setHasError(true);
    }
  }

  return (
    <div className="mb-10">
      <label className="">
        <div className="uppercase mb-2 tracking-wide field-caption">
          {FirstCharUpperCase(props.label)}
        </div>
        <div className="relative flex">
          <div className="absolute top-0 left-0 text-2xl text-gray-700">+ 1</div>
          <input
          type="text"
          name={props.name}
          pattern={props.input_field['validation_regex']}
          onChange={e => handleChange(e, props.setShowButton)}
          autoFocus={true}
          value={value}
          onBlur={e => formatMobileNumber(e.target.value)}
          className="bg-transparent w-full h-12 text-3xl text-field border-b-2 pb-3.5 pl-11"
          required
        />
        </div>
      </label>
      {HasError ? (
        <div>
          <p className="error-block text-xs absolute my-2">{errorMessage || workflow.phone.errorMessage}</p>
        </div>
      ) : null}
    </div>
  )
}
