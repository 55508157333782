import React from "react";

const InfoIcon = () => {
    return (
        <div className="ml-2 cursor-pointer">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.10156 4.30078C6.55078 4.30078 6.89453 3.95312 6.89453 3.53906C6.89453 3.125 6.55078 2.77734 6.10156 2.77734C5.65234 2.77734 5.30859 3.125 5.30859 3.53906C5.30859 3.95312 5.65234 4.30078 6.10156 4.30078ZM5.30078 9H6.90234V4.6875H5.30078V9Z" fill="white" />
                <circle cx="6" cy="6" r="5.5" stroke="white" />
            </svg>
        </div>
    );
}

export default InfoIcon;










