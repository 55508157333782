export const PLAID_EVENTS = {
  "CLOSE_OAUTH": "CLOSE_OAUTH",
  "ERROR": "ERROR",
  "EXIT": "EXIT",
  "FAIL_OAUTH": "FAIL_OAUTH",
  "HANDOFF": "HANDOFF",
  "MATCHED_SELECT_INSTITUTION": "MATCHED_SELECT_INSTITUTION",
  "MATCHED_SELECT_VERIFY_METHOD": "MATCHED_SELECT_VERIFY_METHOD",
  "OPEN": "OPEN",
  "OPEN_MY_PLAID": "OPEN_MY_PLAID",
  "OPEN_OAUTH": "OPEN_OAUTH",
  "SEARCH_INSTITUTION": "SEARCH_INSTITUTION",
  "SELECT_BRAND": "SELECT_BRAND",
  "SELECT_INSTITUTION": "SELECT_INSTITUTION",
  "SUBMIT_CREDENTIALS": "SUBMIT_CREDENTIALS",
  "SUBMIT_MFA": "SUBMIT_MFA",
  "TRANSITION_VIEW": "TRANSITION_VIEW",
}