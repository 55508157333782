import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { post } from "../utils/client";

import Loading from "../components/Loading";

import { GOOGLE_AUTH_VERIFY_API_URL } from '../constants/urls.constants';
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { checkMarketingDataExpired, parseQueryString, trackUTM } from "../utils/helpers";

interface GoogleAuthProps { }

const GoogleAuth: React.FC<GoogleAuthProps> = (props) => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const url = GOOGLE_AUTH_VERIFY_API_URL + search;

    let marketingAllData: any = trackUTM('marketingData') || { "empty": true };
    let trackProperties = marketingAllData ? parseQueryString(marketingAllData.marketing_url) : { "empty": true };

    marketingAllData = checkMarketingDataExpired(marketingAllData);
    
    track(EVENT.onb_login_with_google_callback_marketing_data, {
      ...trackProperties,
      ...marketingAllData ? { "marketingAllData": marketingAllData } : {}
    });

    post(url)
      .then((resp) => {
        if (resp && resp.success) {
          history.replace('/apply-card');
        }
      })
      .catch((error) => {
        track(EVENT.google_signin_failed, { status: error.response?.status, errorPayload: error.response?.data })
        if (error.response && error.response.status === 409) {
          let message = `${error.response.data.error.display_message} ${error.response.data.error.display_description}`;
          window.location.href = `/?message=${message}`;
        } else {
         window.location.href = "/apply-card/error";
        }
      });
  }, [history, search]);

  return (
    <div className="content-container">
      <Loading />
    </div>
  );
}

export default GoogleAuth;
