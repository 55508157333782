import React, { useContext } from "react";

import { ITenantConfiguration } from "./TenantConfiguration";

export const TenantConfigurationContext =
  React.createContext<ITenantConfiguration | null>(null);

export const TenantConfigurationProvider: React.FC<{
  currentTenantConfig: ITenantConfiguration;
  children: React.ReactNode;
}> = ({ currentTenantConfig, children }) => {
  console.log("currentTenantConfig", currentTenantConfig?.imagePrefix);
  return (
    <TenantConfigurationContext.Provider value={currentTenantConfig}>
      {children}
    </TenantConfigurationContext.Provider>
  );
};
