import React, { useEffect } from 'react';
import Toast from '../../components/Toast';

import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { clearHomeRouteState, getCachedPhoneNumber, isNewSignInFlowEnabled, trackUTM } from "../../utils/helpers";
import { useHistory, useLocation } from 'react-router-dom';
import { NewSignInFlowActions } from '../../components/NewSignInFlowActions';
import { OldSignInFlowActions } from '../../components/OldSignInFlowActions';
import { AutoHideToast } from '../../components/AutohideToast';

const CustomersBank = (props) => {
  const location: any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  const footerLinks = {
    termsAndConditions: 'https://static.deserve.com/resources/CHA/Customers%20Bank%20Consumer%20Credit%20Card%20Application%20Agreement.pdf',
    electronicConsentPolicy: 'https://static.cards.customersbank.com/resources/cubi/Electronic%20Communications%20Policy%20and%20Consent_Approved.docx.pdf',
    patriotActUSA: 'https://www.customersbank.com/usa-patriot-act',
    privacyPolicy: 'https://www.customersbank.com/privacy-policy/',
    privacyNotice: 'https://www.customersbank.com/privacy-notice',
    termsOfService: 'https://www.customersbank.com/terms-of-use/'
  };

  trackUTM('marketingData', window.location.href); // Track marketing data

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <>
      {title && <AutoHideToast title={title} timeout={6000} />}
      <div>
        <div className="bg-customersbank cb-container w-full py-7 flex flex-col">
          {message && <Toast cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center" text={message} timeout={6000} />}
          <div className="flex justify-between x-sm:items-center">
            <a className="inline-block " href="/">
              <img src={`${IMAGE_PREFIX()}/logo.svg`} alt="logo" />
            </a>
          </div>
          <div className="cb-content-container x-sm:pt-0.5 pb-4 flex flex-col lg:flex-row-reverse justify-center lg:justify-between x-sm:min-h-full">
            <div className="bg-customersbank-mobile lg:hidden x-sm:h-64"></div>
            <img src={`${IMAGE_PREFIX()}/cubi_index_card_image.png`} className="customersbank-card-img x-sm:mx-auto x-sm:w-44 lg:ml-24 lg:w-1/2" alt="cubicard" />
            <div className='lg:w-2/5 lg:self-center'>
              {!phoneNumber && <h1 className="text-black x-sm:text-4xl lg:text-5xl text-6xl font-semibold text-center lg:text-left font-poppins-semibold">
                <span className='block'>Start Earning Today</span>
              </h1>}

              {phoneNumber && <h1 className="text-black x-sm:text-4xl lg:text-5xl text-6xl font-semibold text-center lg:text-left font-poppins-semibold">Welcome back</h1>}
              {!phoneNumber && <p className="text-black text-xl x-sm:leading-7 x-sm:my-4 mt-6 font-normal text-center lg:text-left font-poppins-regular">A credit card that gives you more so you can worry less.*</p>}

              {phoneNumber && <p className="text-black text-xl x-sm:leading-7 x-sm:my-4 mt-6 font-normal text-center lg:text-left font-poppins-regular">
                Continue with your phone number <span className='block'>verification to sign in</span>
              </p>}
              <div className="w-full h-0.5 my-2.5 bg-gray-200 lg:hidden">
              </div>
              <div className="lg:hidden x-sm:font-semibold x-sm:pb-0 x-sm:pt-1 x-sm:text-center md:text-center font-poppins-regular">
                <span>Sign in or Apply below</span>
              </div>
              {isNewSignInFlowEnabled() ? <NewSignInFlowActions /> : <OldSignInFlowActions />}
            </div>
          </div>
          <div className="x-sm:flex x-sm:grid-flow-row gap-2 relative lg:absolute lg:bottom-2 xl:bottom-6 2xl:bottom-10">
            <p className="theme-text-color mt-4 text-xs x-sm:top-0 z-10 x-sm:relative x-sm:text-left font-poppins-regular">*Subject to credit approval, verification and required download of the Customers Bank Credit Card<br className='hidden lg:block' /> mobile app (available on iOS and Android mobile devices). Issued by Customers Bank.</p>
          </div>
        </div>
        <footer className="bg-white w-full customersbank-footer pt-14 pb-28">
          <ul className="text-xs">
            <li><a target='_blank' href={footerLinks.termsAndConditions}>Terms and Conditions</a></li>
            <li><a target='_blank' href={footerLinks.electronicConsentPolicy}>Electronic Consent Policy</a></li>
            <li><a target='_blank' href={footerLinks.patriotActUSA}>PATRIOT Act Notice</a></li>
            <li><a target='_blank' href={footerLinks.privacyPolicy}>Privacy Policy</a></li>
            <li><a target='_blank' href={footerLinks.privacyNotice}>Privacy Notice</a></li>
            <li><a target='_blank' href={footerLinks.termsOfService}>Terms of Service</a></li>
          </ul>
          <span>&#169; 2023 Customers Bank</span>
          <div className="mt-3">
            <p className='leading-6'>The Customers Bank Credit Card is issued by Customers Bank & Powered by Deserve®.</p>
            <p className='leading-6'>All product names, logos, and brands are property of their respective owners. All company, product and service marks used in this website are for identification purposes only. Use of these names, logos, and brands does not imply endorsement.</p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default CustomersBank;
