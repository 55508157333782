export const setBlockfiThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#FF6230");
    document.body.style.setProperty("--primary-font", "suisseintl");
    document.body.style.setProperty("--secondary-font", "suisseintl");
    document.body.style.setProperty("--theme-input_label_font_family", "suisseintl");
    document.body.style.setProperty("--theme-input_font_family", "suisseintl");
    document.body.style.setProperty("--theme-color", "#FF6230");
    document.body.style.setProperty("--progress-line-over-color", "#FF6230");
    document.body.style.setProperty("--text-color", "#000000");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#000000");
    // document.body.style.setProperty("--text2-color", "#534B4B");
    document.body.style.setProperty("--theme-background", "#F9F9F9");
    document.body.style.setProperty("--theme-background-dark", "#000000");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-color-rgb", "255, 98, 48");
    document.body.style.setProperty("--theme-error", "#FF6230");
    document.body.style.setProperty("--theme-approval-text", "#FDF0E0");
    document.body.style.setProperty("--theme-checkbox-background", "#FF6230");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#FF6230");
    document.body.style.setProperty("--theme-card-background", "#FFFFFF");
    document.body.style.setProperty("--theme-cardaction-error", "#000000");
    document.body.style.setProperty("--theme-card-active-background", "#000000");
    document.body.style.setProperty("--theme-card-active-color", "#FFFFFF");
    document.body.style.setProperty("--theme-card-text", "#1E1F26");
    document.body.style.setProperty("--theme-payment-success-block-background", "#FF6230");
    // New sign in button style vars
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#FF6230");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#000000");
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#000000");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_border_color", "transparent");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "#FF6230");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#1A1717");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#040D14");
    document.body.style.setProperty("--slider-bg", "#000000");
    document.body.style.setProperty("--slider-off", "rgba(0, 0, 0, 0.2)");
};