export const setSWThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#F49C92");
    document.body.style.setProperty("--primary-font", "europa");
    document.body.style.setProperty("--secondary-font", "europa");
    document.body.style.setProperty("--theme-input_label_font_family", "europa");
    document.body.style.setProperty("--theme-input_font_family", "europa");
    document.body.style.setProperty("--theme-color-rgb", "244, 156, 146");
    document.body.style.setProperty("--theme-color", "#F49C92");
    document.body.style.setProperty("--progress-line-over-color", "#F49C92");
    document.body.style.setProperty("--text-color", "#112949");
    document.body.style.setProperty("--text-t1-color", "#112949");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#112949");
    document.body.style.setProperty("--theme-background", "#FAEEEB");
    document.body.style.setProperty("--theme-background-dark", "#112949");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#112949");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#112949");
    document.body.style.setProperty("--theme-card-background", "#6187ba60");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(244, 156, 146, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#F49C92");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(244, 156, 146, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "rgba(244, 156, 146, 1)");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(244, 156, 146, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgba(244, 156, 146, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#F49C92");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "rgba(255, 255, 255, .15)");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "rgba(255, 255, 255, 1)");
};