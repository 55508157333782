import * as Sentry from "@sentry/react";
import axios from "axios";

export const getDecodedAuth0Token = () => {
  const key_prefix = "@@auth0spajs@@";
  const client_id = window["appConfig"].BUSINESS_CARD_PORTAL_CLIENT_ID;
  const scope = "openid profile email offline_access";
  const audience = window["appConfig"].BUSINESS_CARD_PORTAL_AUDIENCE;

  return JSON.parse(
    localStorage.getItem(`${key_prefix}::${client_id}::${audience}::${scope}`)
  );
};

class AxiosClient {
  static MAX_RETRY = 10;
  static async getData(url, accessToken, params = {}) {
    return axios
      .get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return Promise.reject(err);
      });
  }

  static async getDataWithRetry(
    url,
    accessToken,
    params = {},
    condition,
    currentRetry = 1
  ) {
    return axios
      .get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(async (resp) => {
        if (
          condition &&
          !condition(resp.data) &&
          currentRetry < this.MAX_RETRY
        ) {
          currentRetry++;
          return await AxiosClient.getDataWithRetry(
            url,
            accessToken,
            params,
            condition,
            currentRetry
          );
        }

        return resp.data;
      })
      .catch(async (err) => {
        if (currentRetry <= this.MAX_RETRY) {
          currentRetry++;
          return await AxiosClient.getDataWithRetry(
            url,
            accessToken,
            params,
            condition,
            currentRetry
          );
        } else {
          Sentry.captureException(err);
        }

        return Promise.reject(err);
      });
  }

  static async postData(url, data, accessToken, headers = {}) {
    return axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          ...headers,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return Promise.reject(err);
      });
  }

  static async patchData(url, data, accessToken) {
    return axios
      .patch(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return Promise.reject(err);
      });
  }

  static async putData(url, data, accessToken, headers = {}) {
    return axios
      .put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          ...headers,
        },
      })
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return err;
      });
  }

  static async deleteData(url, accessToken, data) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const config = data
      ? {
          headers,
          data,
        }
      : {
          headers,
        };

    return axios
      .delete(url, config)
      .then((resp) => resp.data)
      .catch((err) => {
        Sentry.captureException(err);
        return err;
      });
  }
}

export default AxiosClient;
