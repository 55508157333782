import React, { useState } from "react";

interface SnackPopMessageProps {
    message: string,
    onClose?: Function,
    timeout?: number,
    styleClasses?: string
}

const SnackPopMessage: React.FC<SnackPopMessageProps> = ({ message, styleClasses, timeout }) => {
    const [disappear, setDisappear] = useState(false);

    window.setTimeout(() => {
        setDisappear(true);
    }, timeout || 3000);

    if (!disappear) {
        return (
            <div id="SnackPopMessage" data-testid="SnackPopMessage" className={styleClasses}>
                <span>{message}</span>
            </div>
        );
    } else {
        return null;
    }
}

export default SnackPopMessage;