import React from "react";
import { workflow } from "../constants/workflow.constants";

type ResendCodeButtonTypes = {
  resendCode: React.MouseEventHandler<HTMLButtonElement>
};

export const ResendCodeButton = (props: ResendCodeButtonTypes) => {
  return (
    <div className='text-center text-xl tracking-tight p-2 md:p-5'>
      <button
        className='consent-button-cancel rounded-full py-6 px-32 x-sm:px-24 text-small-btn brand-btn-void'
        data-testid="resend-code-btn"
        onClick={props.resendCode}>{workflow.otp.resendCode}</button>
    </div>
  );
}
