type Props = {
    onClick?: Function,
    fillColor?: string
}

const Check = (props: Props) => {
    const { fillColor = "#000000" } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M4.80078 12.1001L10.4823 18.6L19.7154 5.6001" stroke={fillColor} strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default Check;