import moment from "moment";
import {useState} from "react";
import {FirstCharUpperCase} from "../utils/text";
import {workflow} from "../constants/workflow.constants";

type Props = { input_field: Object; label: string; key: string; setShowButton: Function; wpStyleClass?: string; testId?: string;};

export function DateInputType(props: Props) {
  const [HasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  function isValidInput(setShowButton, years) {
    const MIN_AGE = props.input_field['min_age'];
    const MAX_AGE = 150;
    const ERROR_MESSAGES = {
      minAge: 'Minimum Age requirement not fulfilled',
      inValidDate: 'Please Enter a valid date of birth'
    }
    
    if(!MIN_AGE) {
      setHasError(false);
      setShowButton(true);
      return true;
    }

    if (years >= MIN_AGE && years <= MAX_AGE) {
      setShowButton([true])
      setHasError(false)
      return true
    }

    if (years > 0 && years < MIN_AGE) {
      setShowButton(false)
      setHasError(true)
      setErrorMessage(ERROR_MESSAGES.minAge);
    } else {
      setShowButton(false)
      setHasError(true)
      setErrorMessage(ERROR_MESSAGES.inValidDate);
    }

    return false;
  }

  function handleChange(setShowButton, event) {
    const target = event.target;
    const value = target.value;

    const year = new Date(value).getFullYear();

    if (isNaN(year) || year?.toString().length > 4) {
      setHasError(true);
      setShowButton(false);
      setErrorMessage('Please Enter a valid date of birth')
      return;
    }

    const formattedDate = new Date(value);
    const years = moment().diff(formattedDate, "years");

    return isValidInput(setShowButton, years);
  }

  const getDate18YearsAgo = () => {
    const MIN_AGE = props.input_field['min_age'];
    if (MIN_AGE) {
      return moment().subtract(18, 'years').format('YYYY-MM-DD');
    }
    return null;
  };

  return (
    <div className={`mb-10 ${props.wpStyleClass}`}>
      <label className="">
        <div className="uppercase mb-2 tracking-wide field-caption">
          {FirstCharUpperCase(props.label)}
        </div>
        <input
          type="date"
          name="dob"
          max={getDate18YearsAgo()}
          onChange={e => handleChange(props.setShowButton, e)}
          className="bg-transparent w-full h-12 text-3xl border-b-2 text-field"
          data-testid={props.testId??''}
          required
        />
      </label>
      {HasError ? (
        <div>
          <p className="error-block text-xs absolute my-2">{errorMessage || workflow[props.key].errorMessage}</p>
        </div>
      ) : null}
    </div>
  )
}

export default DateInputType;