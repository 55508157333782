import BankNotFound from "./components/BankNotFound";
import DefaultInstitutionsSkeleton from "./components/DefaultInstitutionsSkeleton";
import InstitutionsError from "./components/InstitutionsError";
import InstitutionsSkeleton from "./components/InstitutionsSkeleton";

const FinicityInstitutions = ({
  showLoading,
  searchTerm,
  institutions,
  institutionsMetadata,
  onInstitutionClick,
}) => {
  const showSuggestionsOrList = () => {
    if (showLoading) {
      // decide which skeleton to show based on the searchTerm

      if (searchTerm) {
        return <InstitutionsSkeleton />;
      }

      return <DefaultInstitutionsSkeleton />;
    }

    if (searchTerm) {
      return (
        <div className="overflow-auto h-[70%] h-4/6">
          <div className="bg-white rounded-2xl mr-4">
            {institutions.map((institution) => {
              return (
                <div
                  onClick={() => {
                    onInstitutionClick(institution);
                  }}
                  className="flex p-4 items-center gap-4"
                >
                  <div className="w-10 h-10 cursor-pointer">
                    <img
                      // Rahul: Consumer Finicity - Added fallback image for institution logo
                      src={
                        institution.branding?.icon ||
                        "/apply-card/images/hotlink-ok/finicity/fallback-institution-logo.png"
                      }
                      alt={institution.name}
                    />
                  </div>
                  <div className="cursor-pointer text-t1-color">
                    <p>{institution.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-3 gap-3">
        {institutions.map((institution) => {
          return (
            <div
              onClick={() => {
                onInstitutionClick(institution);
              }}
              className="bg-white rounded-md w-[153px] h-[86px] flex items-center justify-center cursor-pointer w-40 h-20"
            >
              <img
                alt={institution.name}
                src={institution.branding?.logo}
                data-testid={`${institution.name
                  .toLowerCase()
                  .split(" ")
                  .join("-")}-logo`}
              />
            </div>
          );
        })}
      </div>
    );
  };

  if (institutions.length === 0 && !showLoading) {
    return (
      <div className="flex-1">
        {searchTerm.length === 0 ? (<InstitutionsError
          type="DEFAULT_BANK_ERROR"
          institutionsMetadata={institutionsMetadata}
        />) : (<BankNotFound
          institutionsMetadata={institutionsMetadata}
        />)}
      </div>
    );
  }

  return (
    <div className="overflow-auto h-full mb-8">{showSuggestionsOrList()}</div>
  );
};

export default FinicityInstitutions;
