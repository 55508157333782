export const setCreditKrowdfitThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#EB6E1E");
    document.body.style.setProperty("--primary-font", "Aktiv-Grotesk-Light");
    document.body.style.setProperty("--secondary-font", "Aktiv-Grotesk-Regular");
    document.body.style.setProperty("--theme-input_label_font_family", "Aktiv-Grotesk-Regular");
    document.body.style.setProperty("--theme-input_font_family", "Aktiv-Grotesk-Regular");
    document.body.style.setProperty("--theme-color-rgb", "235, 110, 30");
    document.body.style.setProperty("--theme-color", "#EB6E1E");
    document.body.style.setProperty("--progress-line-over-color", "#EB6E1E");
    document.body.style.setProperty("--text-color", "#4C4C4C");
    document.body.style.setProperty("--text-t1-color", "#000000");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#4C4C4C");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background", "##EB6E1E");
    document.body.style.setProperty("--theme-background-dark", "#EB6E1E");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#EB6E1E");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#EB6E1E");
    document.body.style.setProperty("--theme-card-background", "#3333332b");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(235, 110, 30, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#EB6E1E");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(235, 110, 30, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "rgba(235, 110, 30, 1)");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(235, 110, 30, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgb(235, 110, 30, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "rgba(235, 110, 30, 1)");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "rgba(4, 13, 20, 1)");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "rgba(4, 13, 20, 1)");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "Aktiv-Grotesk-Regular");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#FFF");
};