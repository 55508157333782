const CloseIcon = ({ onClick }) => {
  return (
    <svg
      className="cursor-pointer"
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9.70342L2.03802 15.6654C1.81496 15.8885 1.53105 16 1.18631 16C0.841572 16 0.557668 15.8885 0.334601 15.6654C0.111534 15.4423 0 15.1584 0 14.8137C0 14.4689 0.111534 14.185 0.334601 13.962L6.29658 8L0.334601 2.03802C0.111534 1.81496 0 1.53105 0 1.18631C0 0.841572 0.111534 0.557668 0.334601 0.334601C0.557668 0.111534 0.841572 0 1.18631 0C1.53105 0 1.81496 0.111534 2.03802 0.334601L8 6.29658L13.962 0.334601C14.185 0.111534 14.4689 0 14.8137 0C15.1584 0 15.4423 0.111534 15.6654 0.334601C15.8885 0.557668 16 0.841572 16 1.18631C16 1.53105 15.8885 1.81496 15.6654 2.03802L9.70342 8L15.6654 13.962C15.8885 14.185 16 14.4689 16 14.8137C16 15.1584 15.8885 15.4423 15.6654 15.6654C15.4423 15.8885 15.1584 16 14.8137 16C14.4689 16 14.185 15.8885 13.962 15.6654L8 9.70342Z"
        fill="#534B4B"
      />
    </svg>
  );
};

export default CloseIcon;
