import React from "react";
type Props = {
    onClick?: Function,
    fillColor?: string,
}

const ArrowLeft = (props: Props) => {
    const { fillColor = "#534B4B" } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="21" viewBox="0 0 14 21" fill="none">
            <g clipPath="url(#clip0_20857_11600)">
                <path d="M1.75639 10.8401C1.94539 10.8401 2.13439 10.7771 2.28839 10.6441L12.8444 1.4461C13.1804 1.1521 13.2224 0.641097 12.9284 0.305097C12.6344 -0.0309026 12.1234 -0.0659026 11.7874 0.221097L1.23139 9.4191C0.895392 9.7131 0.860393 10.2241 1.15439 10.5601C1.31539 10.7421 1.53939 10.8401 1.76339 10.8401H1.75639Z" fill={fillColor}/>
                <path d="M12.313 20.0374C12.537 20.0374 12.761 19.9464 12.922 19.7574C13.216 19.4214 13.181 18.9104 12.845 18.6164L2.28897 9.41836C1.95297 9.12436 1.44197 9.15936 1.14797 9.49536C0.85397 9.83136 0.88897 10.3424 1.22497 10.6364L11.788 19.8344C11.942 19.9674 12.131 20.0304 12.32 20.0304L12.313 20.0374Z" fill={fillColor}/>
            </g>
            <defs>
                <clipPath id="clip0_20857_11600">
                    <rect width="12.18" height="20.013" fill="white" transform="matrix(-1 0 0 1 13.125 0.0234375)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default ArrowLeft;

