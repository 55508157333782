export const setOppfiThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#0528A0");
    document.body.style.setProperty("--primary-font", "Arimo");
    document.body.style.setProperty("--secondary-font", "Arimo");
    document.body.style.setProperty("--theme-input_label_font_family", "Arimo");
    document.body.style.setProperty("--theme-input_font_family", "Arimo");
    document.body.style.setProperty("--theme-color", "#0528A0");
    document.body.style.setProperty("--progress-line-over-color", "#0528A0");
    document.body.style.setProperty("--text-color", "#1A1717");
    document.body.style.setProperty("--text-t1-color", "#0F1515");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#1A1717");
    document.body.style.setProperty("--theme-background", "#F3F5FB");
    document.body.style.setProperty("--theme-background-dark", "#0528A0");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-color-rgb", "5, 40, 160");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-border-color", "#000");
    document.body.style.setProperty("--theme-checkbox-background", "#0528A0");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#0528A0");
    document.body.style.setProperty("--theme-card-background", "#849be860");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--text-bold", "700");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "rgba(5, 40, 160, 1)");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "rgba(5, 40, 160, .15)");
    document.body.style.setProperty("--theme-signin_btn_border_color", "rgba(5, 40, 160, .15)");
    document.body.style.setProperty("--theme-signin_btn_text_color", "rgba(5, 40, 160, 1)");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "rgba(26, 23, 23, 1)");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "rgba(26, 23, 23, 1)");
};