import React from "react";
type Props = {
  color?: string
};
function TickIcon(props: Props) {
  const { color } = props;
  return (
    <svg width="38" height="33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 21.5L13.5 30l22-27.5" stroke={color || '#fff'} strokeWidth="4.348" strokeLinecap="round" strokeLinejoin="round" /></svg>
  );
}

export default TickIcon;
