import React, { useEffect, Fragment, useState, useCallback } from "react";
import { PlaidLinkOnEvent, PlaidLinkOnEventMetadata, usePlaidLink } from 'react-plaid-link';
import emitter from '../utils/emitter';
import { track } from "../utils/analytics";
import { findInCollection } from "../utils/find-in-collection";
import BtnNext from "../components/BtnNext";
import { EVENT } from "../constants/events.constants";
import ClientStorage from "../utils/client-storage";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import ReactHtmlParser from "html-react-parser";
import { trackPlaidLinking } from "../utils/helpers";
import { PLAID_EVENTS } from "../constants/plaid-event.constants";

type Props = {
  handleSubmit: Function,
  breadcrumbs: Object,
  workflow: Object,
  currentStep: Object | any
};

function LinkBankAccount(props: Props) {
  const [submitting, setSubmitting] = useState(false);
  const template = props.workflow["steps"][0]["ui_template_type"]
  const step = props.workflow["steps"][0][template];
  const bankDescSection = findInCollection(step.sections, 'identifier', 'add_bank_description_section');
  const bankRequestSection = findInCollection(step.sections, 'identifier', 'add_bank_request_access_section');
  const bankFooterSection = findInCollection(step.sections, 'identifier', 'add_bank_footer_section');
  const title = step.default_state.title;
  const link_token = step.plaid_link_token.link_token;

  useEffect(() => {
    track(EVENT.add_bank);
    emitter.emit('progress', { breadcrumbs: props.breadcrumbs, identifier: props.currentStep['identifier'] });
    ClientStorage.setLocal("link_token", link_token)
    ClientStorage.setLocal("redirectUrl", window.location.href)
    return () => { }
  }, [props.breadcrumbs, props.currentStep, props.workflow]);

  function onSuccess(token: any, metadata: any) {
    track(EVENT.onb_bank_connected);
    ClientStorage.setLocal("bank_token", token)
    ClientStorage.setLocal("plaid-metadata", JSON.stringify(metadata))
    props.handleSubmit({});
  };

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName: string, metadata: PlaidLinkOnEventMetadata) => {
    if(eventName === PLAID_EVENTS.EXIT) {
      setSubmitting(false);
    }
    trackPlaidLinking(eventName, metadata);
  }, []);


  const config = {
    token: link_token,
    onSuccess,
    onEvent
  };

  const { open } = usePlaidLink(config);

  function handleClick() {
    track(EVENT.onb_bank_next_clicked);
    setSubmitting(true);
    open();
  }

  function renderDescription() {
    if (bankDescSection && bankDescSection.cells) {
      let { cells } = bankDescSection;
      return (
        <p data-testid="subtitle" className='text-subtitle text-center z-20 mb-0'>{cells[0].title}</p>
      );
    }
    return null;
  }

  function renderRequestSection() {
    return (
      <div className="mt-8 grid grid-rows-1 sgrid-rows-3 md:grid-flow-col gap-4">
        {bankRequestSection.cells.map((cell: any, index: number) => {
          return (
            <div key={index} className="bg-white p-8 rounded-2xl">
              <p data-testid="subtitle" className='text-xl font-bold text-center z-20 mb-2'>{cell.title}</p>
              <p data-testid="description" className='text-base mb-2 mt-1'>{cell.description}</p>
            </div>
          );
        })}
      </div>
    );
  }

  function renderFooterSection() {
    const footerTextCell = findInCollection(bankFooterSection.cells, 'cell_type', 'text_cell');
    const footerActionCell = findInCollection(bankFooterSection.cells, 'cell_type', 'action_cell');

    return (
      <div className="mt-8">
        <p data-testid="description" className='text-sm txt-type-para mb-2 mt-1'>{ReactHtmlParser(footerTextCell.title)}</p>
        {!submitting && <BtnNext
          onClick={handleClick}
          text={footerActionCell.first_action_title}
          className="text-white block text-center text-xl tracking-tight button-with-custom-text h-16 p-5 cursor-pointer w-72 ml-auto mr-auto mt-8 rounded-full"
        />}
        {submitting && <BtnSpinnerNext />}
      </div>
    );
  }

  return (
    <Fragment>
      <p className="text-main x-sm:mb-10 mb-4 text-center">{title}</p>
      {renderDescription()}
      <div className="std-container sm:std-container">
        {renderRequestSection()}
        {renderFooterSection()}
      </div>
    </Fragment>
  );
}

export default LinkBankAccount;
