/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useRef } from "react";
import BtnNext from "../components/BtnNext";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { post } from "../utils/client";
import { formatMobileNumber } from "../utils/mobileNumberFormatter";
import emitter from "../utils/emitter";
import { track, trackFailure } from "../utils/analytics";
import { StepIdentifier, OtpTimers } from "../utils/constants";
import { workflow } from '../constants/workflow.constants';

import {
  EXISTING_ACCOUNT_PAGE_URL,
  OTP_VERIFY_ENDPOINT,
  PHONE_TRIGGER_ENDPOINT,
  SIGN_IN_BY_EMAIL_API_URL,
  VERIFY_EMAIL_OTP_API_URL,
} from "../constants/urls.constants";
import { useHistory } from "react-router";
import BtnSpinnerNext from "../components/BtnSpinnerNext";

import {getApplicationToken, redirectTo} from "../utils/helpers";
import { EVENT } from "../constants/events.constants";

type Props = {
  currentStep: Object;
  onClick: Function;
  mobile_number?: string;
  breadcrumbs?: Object;
  user?: {
    emailId?: string;
    session_id?: string;
  };
};

function OTP(props: Props) {
  const ModelName = props.currentStep["ui_template_type"];
  const ModelData = props.currentStep[ModelName];
  const title = ModelData.default_state.title;
  const history = useHistory();
  const [hasError, setHasError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [IsInvalidOTP, setIsInvalidOTP] = useState(false);
  const [OTPExpired, setOTPExpired] = useState(false);
  const [HasFailed, setHasFailed] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(true);
  const [segmentEventName, setSegmentEventName] =
    useState("pqAppCodeSubmitted");
  const OTP_time = 180;
  const [counter, setCounter] = useState(OTP_time);
  const resendOtpLinkRef = useRef(null);

  const { register, handleSubmit, formState } = useForm({
    reValidateMode: "onBlur",
    mode: "all",
  });

  const invalid_otp_str = `Looks like that code didn’t match. Please try again.`;
  const otp_expired_str = `Your code expired. Please try again.`;
  const validation_regex = ModelData["input_fields"][0].validation_regex;
  const min_length = ModelData["input_fields"][0].min_length;
  const max_length = ModelData["input_fields"][0].max_length;
  const { isValid } = formState;

  function isEmailOTP(): boolean {
    return StepIdentifier.email_verify === props.currentStep["identifier"];
  }

  const memoizedIsEmailOTP = useCallback(isEmailOTP, [props.currentStep]);

  useEffect(() => {
    if (memoizedIsEmailOTP()) {
      emitter.emit("progress", {
        breadcrumbs: props.breadcrumbs,
        identifier: StepIdentifier.confirm_name_and_email,
      });
    } else {
      emitter.emit("progress", {
        breadcrumbs: props.breadcrumbs,
        identifier: props.currentStep["identifier"],
      });
    }
  }, [props.breadcrumbs, props.currentStep, memoizedIsEmailOTP]);

  useEffect(() => {
    let intervalId: any;

    if (counter === 0) {
      setOTPExpired(true);
      setShowSubmitBtn(false);
      clearInterval(intervalId);
      return;
    }

    intervalId = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [counter]);

  function handleChange(event: any) {
    setHasError(false);
    if (isValid) {
      setHasError(false);
    } else {
      setHasError(true);
    }
  }

  function ResendCode(e: any) {
    e.preventDefault();
    const applicationToken = getApplicationToken();

    const resendOtpLink = resendOtpLinkRef.current;

    if (resendOtpLink) {
      const disableResendOtpClassList = ["pointer-events-none", "opacity-40"];
      resendOtpLink.classList.add(...disableResendOtpClassList);

      setTimeout(() => {
        resendOtpLink.classList.remove(...disableResendOtpClassList);
      }, OtpTimers.resend_otp_timer * 1000);
    }

    setShowSubmitBtn(true);
    setHasFailed(false);
    setOTPExpired(false);
    setIsInvalidOTP(false);
    setSegmentEventName("pqAppRecodeSubmitted");

    if (memoizedIsEmailOTP()) {
      resendEmailOTP();
    } else {
      resendPhoneOTP(applicationToken);
    }
  }

  function resendPhoneOTP(applicationToken: string) {
    let data = {
      idToken: applicationToken,
      phone: "+1" + props.mobile_number,
    };

    track(EVENT.onb_phone_resend_code_clicked);

    post(PHONE_TRIGGER_ENDPOINT, data)
      .then((res) => {
        setCounter(180);
      })
      .catch((err) => {
        track(EVENT.onb_phone_resend_code_submit_failed);
        setHasFailed(true);
      });
  }

  function resendEmailOTP() {
    track(EVENT.onb_email_resend_code_clicked);
    post(SIGN_IN_BY_EMAIL_API_URL, {
      email: props.user.emailId,
    })
      .then((res) => {
        setCounter(180);
      })
      .catch((err) => {
        track(EVENT.onb_email_resend_code_failed);
        setHasFailed(true);
      });
  }

  function handleFormSubmit({ otp_code }) {
    const applicationToken = getApplicationToken();
    if (isValid) {
      setSubmitting(true);
      if (memoizedIsEmailOTP()) {
        verifyEmailOTP(otp_code);
        return null;
      }
      verifyPhoneOTP(otp_code, applicationToken);
    } else {
      setHasError(true);
    }
  }

  function verifyPhoneOTP(otp_code: any, applicationToken: string) {
    let data = {
      idToken: applicationToken,
      phone: `+1${props.mobile_number}`,
      otp: otp_code,
    };

    track(EVENT.onb_phone_code_next_clicked);

    post(OTP_VERIFY_ENDPOINT, data)
      .then((res) => {
        track(EVENT.onb_phone_code_submitted);
        return res;
      })
      .then((res) => {
        onOTPSubmitSuccess(res);
      })
      .catch((err) => {
        onOTPSubmitFail(err, "mobileOtp", EVENT.onb_phone_code_submit_failed);
      });
  }

  function verifyEmailOTP(otp_code: any) {
    let data = {
      email: props.user.emailId,
      session_id: props.user.session_id,
      otp: otp_code,
    };
    track(EVENT.onb_email_code_next_clicked);

    post(VERIFY_EMAIL_OTP_API_URL, data)
      .then((res) => {
        track(segmentEventName);
        localStorage.removeItem("email");
        return res;
      })
      .then((res) => {
        onOTPSubmitSuccess(res);
      })
      .catch((err) => {
        onOTPSubmitFail(err, "emailOtp", EVENT.onb_email_code_submit_failed);
      });
  }

  function onOTPSubmitSuccess(res: any) {
    setSubmitting(false);

    if (memoizedIsEmailOTP()) {
      track(EVENT.onb_email_code_submitted);
      history.push("/apply-card");
      return null;
    }

    if (res.success) {
      props.onClick({ mobile_number: `+1${props.mobile_number}` });
      return;
    }

    if (res.data.code === "invalid_otp") {
      setIsInvalidOTP(true);
    }

    if (res.data.code === "expired_otp") {
      setOTPExpired(true);
    }
  }

  function onOTPSubmitFail(err: any, from: string, event: string) {
    setSubmitting(false);

    function errorMessage() {
      return `${err?.response?.data?.error?.display_message} ${err?.response?.data?.error?.display_description}`;
    }

    if (err?.response && err?.response?.status === 409) {
      let url =
        from === "emailOtp"
          ? `/?message=${errorMessage()}`
          : EXISTING_ACCOUNT_PAGE_URL;

      trackFailure(event, err, () => redirectTo(url));
    } else {
      trackFailure(event, err);

      if (err?.response?.data?.code === "invalid_otp") {
        setIsInvalidOTP(true);
      } else if (err?.response?.data?.code === "expired_otp") {
        setOTPExpired(true);
      } else {
        setHasFailed(true);
      }
    }
  }

  const emailRedirection = (e: any) => {
    e.preventDefault();
    localStorage.setItem("email", JSON.stringify(props.user.emailId));
    history.push("/apply-card/email-sign-in");
  };

  const phoneRedirection = (e: any) => {
    e.preventDefault();
    localStorage.setItem("phone", JSON.stringify(props.mobile_number));
    history.goBack();
  };

  let seconds = "0";
  const Minutes = Math.floor(counter / 60);
  let secNum = counter - Minutes * 60;
  seconds = secNum < 10 ? "0" + secNum.toString() : secNum.toString();

  function renderExpireView() {
    if (counter !== 0) {
      return (
        <p className="txt-type-para font-b10 py-2 theme-text-color">
          {workflow.otp.resendCodeMessage}{" "}
          {memoizedIsEmailOTP() ? (
            <a onClick={emailRedirection} href="#">
              {props.user.emailId}
            </a>
          ) : (
            <a onClick={phoneRedirection} href="#">
              {formatMobileNumber(props.mobile_number)}
            </a>
          )}
          . <br />
          {workflow.otp.codeExpiration} {Minutes}:{seconds}
        </p>
      );
    }

    return (
      <p className="txt-type-para font-b10 py-2 theme-text-color">
        {workflow.otp.sendTheOtpTo}{" "}
        {memoizedIsEmailOTP() ? (
          <a onClick={emailRedirection} href="#">
            {props.user.emailId}
          </a>
        ) : (
          <a onClick={phoneRedirection} href="#">
            {formatMobileNumber(props.mobile_number)}
          </a>
        )}
        . <br />
        {workflow.otp.dataRatesMayApply}
      </p>
    );
  }

  return (
    <div>
      <p className="text-main mb-10" data-testid="text-main">
        {IsInvalidOTP
          ? invalid_otp_str
          : OTPExpired
            ? otp_expired_str
            : HasFailed
              ? ModelData.failed_state.title
              : title}
      </p>
      <div className="content-container">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="pb-16 relative">
            <label className="">
              <div
                className="uppercase mb-2 tracking-wide field-caption"
                data-testid="field-caption"
              >
                {ModelData.input_fields[0].title}
              </div>
              <input
                type="text"
                name="otp_code"
                data-testid="otp_code"
                className="bg-transparent w-full h-12 text-3xl border-b-2 outline-none text-field"
                defaultValue=""
                maxLength={max_length}
                ref={register({
                  required: true,
                  maxLength: max_length,
                  pattern: new RegExp(validation_regex),
                  minLength: min_length,
                })}
                onBlur={handleChange}
                required
              />
            </label>

            <p
              className={`${hasError ? "visible" : "invisible"
                } error-block text-xs absolute my-2`}
              data-testid="error-label"
            >
              {workflow.otp.enterValidCode}
            </p>

            <div className="my-6">{renderExpireView()}</div>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

            {(counter !== 0 || !HasFailed) && showSubmitBtn && (
              <a
                href="#"
                onClick={ResendCode}
                className="font-bn4 color-theme my-4 text-small-btn"
                ref={resendOtpLinkRef}
              >
                {workflow.otp.resendCode}
              </a>
            )}
          </div>
          {!submitting && <div className="text-center">
            {showSubmitBtn
              ?
              (<BtnNext data-testid="next-btn" className={!isValid ? 'opacity-25 inline-block' : ''} onClick={handleSubmit(handleFormSubmit)} />)
              :
              (<div className='text-center text-xl tracking-tight p-2 md:p-5 cursor-pointer'>
                <button
                  className='consent-button-cancel rounded-full py-3 px-28 text-small-btn'
                  onClick={ResendCode}>{workflow.otp.resendCode}</button>
              </div>)
            }
          </div>}
          {submitting && <BtnSpinnerNext />}
        </form>
      </div>
    </div>
  );
}

export default OTP;
