import React from "react";
type Props = {
    onClick?: Function,
    fillColor?: string,
  }
export function ArrowRight(props: Props) {
  const { onClick=()=> {} } = props;
  const fillColor = props.fillColor || "#534B4B";
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
          <g clipPath="url(#clip0_20857_18202)">
            <path d="M6.99635 6.9795C6.88835 6.9795 6.78035 6.9435 6.69235 6.8675L0.660347 1.6115C0.468347 1.4435 0.444347 1.1515 0.612347 0.959498C0.780347 0.767498 1.07235 0.747498 1.26435 0.911498L7.29635 6.1675C7.48835 6.3355 7.50835 6.6275 7.34035 6.8195C7.24835 6.9235 7.12035 6.9795 6.99235 6.9795H6.99635Z" fill={fillColor}/>
            <path d="M0.963957 12.2368C0.835957 12.2368 0.707957 12.1848 0.615957 12.0768C0.447957 11.8848 0.467957 11.5928 0.659957 11.4248L6.69196 6.16875C6.88396 6.00075 7.17596 6.02075 7.34396 6.21275C7.51196 6.40475 7.49196 6.69675 7.29996 6.86475L1.26396 12.1208C1.17596 12.1968 1.06796 12.2328 0.959957 12.2328L0.963957 12.2368Z" fill={fillColor}/>
          </g>
          <defs>
            <clipPath id="clip0_20857_18202">
              <rect width="6.96" height="11.436" fill="white" transform="translate(0.5 0.800781)"/>
            </clipPath>
          </defs>
        </svg>
    )
}