import React, { useEffect } from "react";
import Toast from "../../components/Toast";
import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { clearHomeRouteState, getCachedPhoneNumber, isNewSignInFlowEnabled, trackUTM } from "../../utils/helpers";
import { useHistory, useLocation } from "react-router-dom";
import { NewSignInFlowActions } from "../../components/NewSignInFlowActions";
import { OldSignInFlowActions } from "../../components/OldSignInFlowActions";
import { AutoHideToast } from "../../components/AutohideToast";

function Amc() {
  const location:any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  trackUTM("marketingData", window.location.href); // Track marketing data

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <>
      {title && <AutoHideToast title={title} timeout={6000} />}
      <div className="bg-amc x-sm:min-h-screen p-8 lg:p-0">
        <div className="lg:w-3/4 m-auto relative x-sm:min-h-screen">
          <div className="x-sm:bg-none w-full lg:min-h-screen x-sm:min-h-full md:pt-7">
            {message && <Toast
              cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center"
              text={message} timeout={6000} />}
            <div className="flex justify-between mb-14 x-sm:mb-1 x-sm:items-center">
              <a className="inline-block x-sm:w-44  mb-6 md:w-40 lg:w-40 x-sm:m-auto" href="/">
                <img src={`${IMAGE_PREFIX()}/logo-white.png`} alt="logo" className="w-full" />
              </a>
            </div>
            <div id="middle_content" className="flex center-content md:pt-6 x-sm:flex-col md:flex-row-reverse">
              <div className="text-center md:w-1/2 x-sm:w-full x-sm:mt-10 flex justify-center items-center">
                <img className="inline x-sm:max-w-full w-full" src={`${IMAGE_PREFIX()}/CardPlaceholder.png`} alt="CardPlaceholder" />
              </div>
              <div className="md:flex md:justify-center md:flex-col md:w-1/2 x-sm:w-full">
                {!phoneNumber && <div>
                  <h1 className="text-t1 mb-4 x-sm:my-8 x-sm:text-center">Now paying everywhere</h1>
                  <p className="text-t2 x-sm:mb-10 mt-1 x-sm:mt-0 font-sf-display x-sm:text-center">Find out if you’re approved without <span className="sm:inline x-sm:inline md:block">negatively impacting your credit.*</span></p>
                </div>}
                {phoneNumber && <h1 className="text-t1 x-sm:text-4xl x-sm:leading-9 x-sm:mb-2 font-gordita mb-2.5 x-sm:mt-8 x-sm:text-center font-bold">Welcome back</h1>}
                {phoneNumber && <p className="text-white text-lg font-normal amc-subtext x-sm:mb-2 mt-1 x-sm:mt-0 font-sf-display x-sm:text-center mb-4">Continue with your phone number <span className="block">verification to sign in</span></p>}
                {isNewSignInFlowEnabled() ? <NewSignInFlowActions /> : <OldSignInFlowActions />}
              </div>
            </div>
          </div>
          <p className="text-t3 md:absolute md:bottom-4 md:left-0 x-sm:mt-6 sm:mt-6 x-sm:static sm:static">*Accepting your credit offer will result in a hard credit inquiry which may impact your credit score</p>
        </div>
      </div>
    </>
  );
}

export default Amc;
