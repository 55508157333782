import React, { useContext } from "react";

import SSN from "./SSN";
import Agreement from "./Agreement";
import PhoneUpload from "./PhoneUpload";
import Approval from "./Approval";
import CardDeclined from "./CardDeclined";
import FrozenCreditBureau from "./FrozenCreditBureau";
import { postWithAuthHeader } from "../utils/client"
import Loading from "../components/Loading";
import ApplicationReview from "./ApplicationReview";
import CrunchingNumbers from "./CrunchingNumbers";
import TalkingToBoss from "./TalkingToBoss";
import ApplicationInProgress from "./ApplicationInProgress";
import ErrorOccurred from "./ErrorOccurred";
import ExistingAccount from "./ExistingAccount";
import OTP from "./OTP";
import { StepIdentifier } from "../utils/constants";
import { UI_TEMPLATE_TYPE } from '../utils/ui-template-types';

import {
  ADD_BANK_ENDPOINT, ADDITIONAL_INCOME_API,
  APPLICATION_BASE_URL,
  CREDIT_PULL_CONSENT_ENDPOINT,
  ERROR_PAGE_URL,
  HOUSING_API,
  INCOME_API, LOGIN_URL,
  METADATA_API,
  PHONE_API, REFRESH_TOKEN, WORKFLOW_ENDPOINT
} from "../constants/urls.constants";
import AddBank from "./AddBank";
import UploadBankToken from "./UploadBankToken";
import { EVENT } from "../constants/events.constants";
import WhatsNext from "./WhatsNext";
import DocumentsStatus from "./DocumentsStatus";
import UploadDocs from "./UploadDocs";
import RefreshToken from "./RefreshToken";
import { DASHBOARD_PATH } from "../constants/route-paths.constants";
import OppFiCHA from "./OppFi-CHA";
import LinkBankAccount from "./LinkBankAccount";
import ClientStorage from "../utils/client-storage";
import DashboardBanner from "./DashboardBanner";
import {checkMarketingDataExpired, getApplicationRefreshToken, getUSPhoneNumber, trackUTM} from "../utils/helpers";
import { DeviceDetailsContext } from "../utils/device-details-context";
import TemplateF from "./TemplateF";
import TemplateA from "./TemplateA";
import TemplateM from "./TemplateM";
import TemplateO from "./TemplateO";
import TemplateM3 from "./TemplateM3";
import ErrorAction from "./ErrorAction";
import TemplateH from "./TemplateH";

type ApplicationWorkflowProps = {
  history: any,
  location: {
    search: string,
    state: any
  },
  device_details: {
    browser: string,
    os: string
  }
};

type ApplicationWorkflowState = {
  workflow: Object,
  redirectToLogin: boolean,
  identifierIndex: number,
  payload: object
};

class ApplicationWorkflow extends React.Component<ApplicationWorkflowProps, ApplicationWorkflowState> {
  constructor(props) {
    super(props);
    this.state = {
      workflow: null,
      redirectToLogin: false,
      identifierIndex: 0,
      payload: {}
    };
  }

  componentDidMount() {
    let marketingAllData = trackUTM('marketingData');
    marketingAllData = checkMarketingDataExpired(marketingAllData);

    postWithAuthHeader(WORKFLOW_ENDPOINT, marketingAllData, EVENT.onb_application_failed)
      .then((response) => {
        if (response) {
          let { data } = response;

          if(data.application_id) {
            ClientStorage.setLocal("application_id", data.application_id);
          }

          if (data.is_onb_completed && data.steps.length === 0) {
            this.workflowCompleted(response);
            return null;
          }

          this.setState({
            workflow: data
          });

          if (ClientStorage.getLocal("is_plaid_callback") === "true") {
            ClientStorage.deleteLocal("is_plaid_callback")
            return this.nextStepHandler({});
          }
        } else {
          window.location.href = ERROR_PAGE_URL;
        }
      })
      .catch((err) => {
        if(err.response && err.response.status === 401) {
          window.location.href = LOGIN_URL;
        } else {
          window.location.href = ERROR_PAGE_URL;
        }
      });
  };

  workflowCompleted = (response?: any) => {
    if(this?.props?.location?.state?.phone) {
      ClientStorage.setLocal("phone_number", getUSPhoneNumber(this.props.location.state.phone));
    }
    window.location.replace(window.location.origin + DASHBOARD_PATH);
  }

  handleSubmit = (obj: any) => {
    // @ts-ignore
    let payload = this.state.payload

    for (const [key, value] of Object.entries(obj)) {
      if (key !== 'optional') {
        payload[key] = value;
      }
    }

    this.setState({
      payload: payload
    });

    return this.nextStepHandler(obj);
  }

  setWorkflow = (response: any) => {
    const { data } = response;
    if (data && data.steps) {
      this.setState((nextState) => {
        return {
          workflow: data,
          redirectToLogin: false,
          identifierIndex: 0,
          payload: {}
        }
      });
    }
  }

  nextStepHandler = (currentActionPayloadObj?: any) => {
    let currentStep = { ...this.state.workflow['steps'][this.state.identifierIndex] };

    if ((currentStep.step_info.step_identifier === StepIdentifier.card_pre_approval_agreement) ||
      (currentStep.step_info.step_identifier === StepIdentifier.card_pre_approval_agreement_type_b) ||
      (currentStep.step_info.step_identifier === StepIdentifier.frozen_credit_bureau)) {
      currentStep.step_info.is_last_page = true;
    }

    if (!currentStep.step_info.is_last_page) {
      return new Promise((resolve, reject) => {
        let identifierIndex = this.state.identifierIndex + 1;
        let forward_count = (currentActionPayloadObj && currentActionPayloadObj.optional && currentActionPayloadObj.optional.forward_count) || 0;

        if (forward_count > 1) {
          identifierIndex = this.state.identifierIndex + forward_count;
        }

        this.setState({
          identifierIndex: identifierIndex
        }, () => {
          resolve(currentStep);
        });
      });
    } else {
      switch (currentStep.step_info.step_identifier) {
        case StepIdentifier.search_street_address:
          return postWithAuthHeader(
            METADATA_API,
            {
              identifier: "meta_data",
              step_info: currentStep["step_info"],
              value: this.state.payload,
            },
            EVENT.onb_meta_data_submit_failed
          ).then(this.setWorkflow);
        case StepIdentifier.confirm_name_and_email:
          return postWithAuthHeader(
            METADATA_API,
            {
              identifier: "meta_data",
              step_info: currentStep["step_info"],
              value: this.state.payload,
            },
            EVENT.onb_name_submit_failed
          ).then(this.setWorkflow);
        case StepIdentifier.phone_upload:
          return postWithAuthHeader(
            PHONE_API,
            this.state.payload,
            EVENT.onb_phone_upload_failed
          ).then(this.setWorkflow);
        case StepIdentifier.get_ssn:
          return (window.location.href = APPLICATION_BASE_URL);
        case StepIdentifier.get_income:
          return postWithAuthHeader(
            INCOME_API,
            this.state.payload,
            EVENT.onb_income_submit_failed
          ).then(this.setWorkflow);
        case StepIdentifier.get_income_details:
          return postWithAuthHeader(
            INCOME_API,
            this.state.payload,
            EVENT.onb_income_submit_failed
          ).then(this.setWorkflow);
        case StepIdentifier.get_rent:
          return postWithAuthHeader(
            HOUSING_API,
            this.state.payload,
            EVENT.onb_rent_submit_failed
          ).then(this.setWorkflow);
        case StepIdentifier.get_payment_type:
          return postWithAuthHeader(
            ADDITIONAL_INCOME_API,
            this.state.payload,
            EVENT.onb_additional_income_submit_failed
          ).then(this.setWorkflow);
        case StepIdentifier.card_pre_approval_agreement:
          return postWithAuthHeader(
            CREDIT_PULL_CONSENT_ENDPOINT,
            this.state.payload,
            EVENT.onb_TNC_submit_failed
          ).then(this.setWorkflow);
        case StepIdentifier.card_pre_approval_agreement_type_b:
          return postWithAuthHeader(
            CREDIT_PULL_CONSENT_ENDPOINT,
            this.state.payload,
            EVENT.onb_TNC_submit_failed
          ).then(this.setWorkflow);
        case StepIdentifier.upload_bank_token:
          return postWithAuthHeader(
            ADD_BANK_ENDPOINT,
            this.state.payload,
            EVENT.onb_bank_connect_failed
          ).then(this.setWorkflow);
        case StepIdentifier.refresh_token:
          return postWithAuthHeader(
            REFRESH_TOKEN,
            {
              refresh_token: getApplicationRefreshToken(),
              device_details: {
                device_id: ClientStorage.getLocal(
                  "CUSTOMER_AUTH_LOGIN_IDENTIFIER"
                ),
                model: this.props.device_details.os,
                name: this.props.device_details.browser,
              },
            },
            EVENT.onb_refresh_token_failed
          ).then((res) => {
            ClientStorage.setLocal("application_token", res.data.id_token);
            window.location.href = APPLICATION_BASE_URL
          });
        default:
          return (window.location.href = ERROR_PAGE_URL);
      }
    }
  }

  render() {
    if (this.state.workflow && this.state.workflow['steps']) {
      let currentStep = this.state.workflow['steps'][this.state.identifierIndex];
      let breadcrumbs = this.state.workflow['breadcrumbs']

      switch (currentStep.step_info.step_identifier) {
        case StepIdentifier.onb_steps_instruction:
          return <TemplateH breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep}  event="onb_lets_begin" />
        case StepIdentifier.confirm_name_and_email:
          return <TemplateA breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} additional_details={{ data: this.state.payload }} name="name" showSpinner={false} event="onb_name" />
        case StepIdentifier.get_phone_number:
          return <TemplateA breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} additional_details={{ data: this.state.payload }} name="mobile_number" showSpinner={false} event="onb_phone" />
        case StepIdentifier.phone_verify:
          return <OTP breadcrumbs={breadcrumbs} currentStep={currentStep} onClick={this.handleSubmit} mobile_number={this.state.payload['mobile_number']} />
        case StepIdentifier.phone_upload:
          return <PhoneUpload handleSubmit={this.handleSubmit} mobile_number={this.state.payload['mobile_number']} currentStep={currentStep} />
        case StepIdentifier.get_birthday:
          return <TemplateA breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} additional_details={{ data: this.state.payload }} name="dob" showSpinner={false} event="onb_dob" />
        case StepIdentifier.search_street_address:
          return <TemplateA breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} additional_details={{ data: this.state.payload }} name="address" showSpinner={false} event="onb_address" />
        case StepIdentifier.get_ssn:
          return <SSN handleSubmit={this.handleSubmit} workflow={this.state.workflow} />
        case StepIdentifier.get_employment_status:
          return <TemplateF breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="employment_status" showSpinner={false} event={EVENT.onb_employment_status_selected} />
        case StepIdentifier.get_current_employment_status:
          return <TemplateF breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="current_employment_status" showSpinner={false} event={EVENT.onb_employment_current_status_selected} />
        case StepIdentifier.get_income_type:
          return <TemplateF breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="income_type" showSpinner={false} event={EVENT.onb_income_type_selected} />
        case StepIdentifier.get_primary_income_source:
          return <TemplateF breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="income_source" showSpinner={false} event={EVENT.onb_income_source_selected} />
        case StepIdentifier.get_income:
          return <TemplateA breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} additional_details={{ income_type: this.state.payload['income_type'] }} name="income" showSpinner={false} event="onb_income" />
        case StepIdentifier.get_income_details:
          return <TemplateA breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} additional_details={{ income_type: this.state.payload['income_type'] }} name="income_type_details" showSpinner={false} event="onb_income" />
        case StepIdentifier.get_living_situation:
          return <TemplateF breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="housing_type" showSpinner={false} event={EVENT.onb_living_situation_selected} />
        case StepIdentifier.get_rent:
          return <TemplateA breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="monthly_rent" showSpinner={false} event="onb_rent" additional_details={{ payload: this.state.payload }} />
        case StepIdentifier.get_income_duration:
          return <TemplateF breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="employment_duration" showSpinner={false} event={EVENT.onb_employment_duration_selected} />
        case StepIdentifier.get_payment_type:
          return <TemplateF breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="payroll_type" showSpinner={false} event={EVENT.onb_payment_type_selected} />
        case StepIdentifier.card_pre_approval_agreement:
          return <Agreement workflow={this.state.workflow} handleSubmit={this.handleSubmit} currentStep={currentStep} />
        case StepIdentifier.card_pre_approval_agreement_type_b:
          return <OppFiCHA workflow={this.state.workflow} handleSubmit={this.handleSubmit} currentStep={currentStep} />
        case StepIdentifier.add_bank:
          if (currentStep.ui_template_type === UI_TEMPLATE_TYPE.ui_template_type_l_model) {
            return <LinkBankAccount handleSubmit={this.handleSubmit} breadcrumbs={breadcrumbs} workflow={this.state.workflow} currentStep={currentStep} />;
          }
          return <AddBank breadcrumbs={breadcrumbs} onClick={this.handleSubmit} currentStep={currentStep} />
        case StepIdentifier.get_bank_account_age:
          return <TemplateF breadcrumbs={breadcrumbs} handleSubmit={this.handleSubmit} CurrentStep={currentStep} name="bank_account_age" showSpinner={false} event={EVENT.onb_bank_account_age_selected} />
        case StepIdentifier.upload_bank_token:
          return <UploadBankToken breadcrumbs={breadcrumbs} onClick={this.handleSubmit} currentStep={currentStep} />
        case StepIdentifier.card_approved:
          return <Approval currentStep={currentStep} breadcrumbs={breadcrumbs} workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.card_approved_v3:
          return <TemplateM CurrentStep={currentStep} breadcrumbs={breadcrumbs} workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.card_approved_v4:
          return <TemplateO CurrentStep={currentStep} breadcrumbs={breadcrumbs} workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.prove_v1:
          return <TemplateM3 CurrentStep={currentStep} breadcrumbs={breadcrumbs} workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.processing_docs:
        case StepIdentifier.card_agreement:
          return <DocumentsStatus currentStep={currentStep} breadcrumbs={breadcrumbs} workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.dashboard_whats_next:
          return <DashboardBanner currentStep={currentStep} breadcrumbs={breadcrumbs} workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.whats_next:
          return <WhatsNext workflow={this.state.workflow} currentStep={currentStep} breadcrumbs={breadcrumbs} setWorkflow={this.setWorkflow} />
        case StepIdentifier.upload_docs:
          return <UploadDocs currentStep={currentStep} workflow={this.state.workflow} breadcrumbs={breadcrumbs} setWorkflow={this.setWorkflow} />
        case StepIdentifier.card_declined:
          return <CardDeclined workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.frozen_credit_bureau:
          return <FrozenCreditBureau workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.application_review:
          return <ApplicationReview workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.crunching_numbers:
          return <CrunchingNumbers workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.talking_to_boss:
          return <TalkingToBoss workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.application_in_progress:
          return <ApplicationInProgress workflow={this.state.workflow} setWorkflow={this.setWorkflow} />
        case StepIdentifier.refresh_token:
          return <RefreshToken handleSubmit={this.handleSubmit} currentStep={currentStep} />
        case StepIdentifier.show_error_action_v2:
          return <ErrorAction workflow={this.state.workflow} showDefaultAction={false} />
        case 'onb_duplicate':
          return <ExistingAccount workflow={this.state.workflow} />
        default:
          return <ErrorOccurred />
      }
    } else {
      return (<Loading className="md:w-full" />);
    }
  }
}

function ApplicationWorkflowWithDeviceDetails(props) {
  const { browser, os } = useContext(DeviceDetailsContext);

  return (
      <ApplicationWorkflow {...props} device_details={{ browser, os }} />
  );
}

export default ApplicationWorkflowWithDeviceDetails;
