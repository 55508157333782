import React, { useEffect, useState } from "react";
import BtnNext from "../components/BtnNext";
import { useForm } from "react-hook-form-lts";
import { track } from "../utils/analytics";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { EVENT } from "../constants/events.constants";
import { AxiosError } from "axios";
import { LOGIN_EMAIL_V3_API } from "../constants/urls.constants";
import { post } from "../utils/client";
import { getNewSignInCommonEventProps, setSessionId } from "../utils/helpers";
import { parse } from "query-string";
import { SignInQueryType } from "../types/SignInQueryType";
import { useLocation } from "react-router-dom";
import ClientStorage from "../utils/client-storage";

type CapureEmailProps = {
  stepData: any;
  query: string;
  workflowSubmitCb: Function
};

function CapureEmail(props: CapureEmailProps,) {
  const appConfig = window['appConfig'];
  const location: any = useLocation();
  const query: SignInQueryType | any = parse(location.search.replace("?", ""));
  const {
    register,
    handleSubmit,
    formState: {
      isValid,
      errors
    }
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const [submitting, setSubmitting] = useState(false);

  let { capture_name_email, capture_name_email_amc } = props.stepData.next_screen;
  let validation = capture_name_email?.validation || capture_name_email_amc?.validation;
  let amc_create_account = capture_name_email_amc?.create_account || { type: null, web_detail: { url: null } };

  useEffect(() => {
    track(
      EVENT.dfc_auth_email_screen,
      getNewSignInCommonEventProps(query)
    );
  }, []);

  function onFormSubmit({ email }) {
    const body = {
      email,
      tenant_id: window['appConfig'].PROGRAM_ID
    };

    track(
      EVENT.dfc_auth_signup_email_next_clicked,
      {
        ...getNewSignInCommonEventProps(query),
        email: email
      }
    );

    if (isValid) {
      track(EVENT.onb_name_next_clicked);
      setSubmitting(true);

      post(LOGIN_EMAIL_V3_API, body)
        .then((data) => {
          track(
            EVENT.dfc_auth_email_initiate_succeed,
            {
              ...getNewSignInCommonEventProps(query),
              email: email
            }
          );

          setSessionId(data);

          props.workflowSubmitCb({
            ...props.stepData,
            ...data,
            ...body,
            email,
          });
        })
        .catch((error: AxiosError) => {
          track(
            EVENT.dfc_auth_email_initiate_failed,
            {
              ...getNewSignInCommonEventProps(query),
              email: email,
              error: error?.response?.data
            }
          );
        });

    } else {
      handleSpinner();
    }
  }

  function handleSpinner(resp?: any) {
    setSubmitting(false);
  }

  return (
    <div className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page`}>
      <h1 className="text-main md:mb-2 lg:mb-2 xl:mb-2 leading-8 sm:px-4 text-1-heading">
        {capture_name_email_amc ? "What’s your AMC Stubs® email?" : "What’s your email?"}
      </h1>
      <div className="content-container">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="mb-4 w-full">
            <div className="flex flex-row justify-center">
              <label className="w-full sm:px-4 py-4">
                <div
                  className="uppercase tracking-wide field-caption mb-3">Email</div>
                <input
                  type="email"
                  placeholder="Enter Email"
                  data-testid="email_address"
                  className="text-field bg-transparent w-full h-9 text-3xl border-b-2 text-field"
                  required
                  {...register("email", {
                    required: true,
                    maxLength: validation?.email?.max_length,
                    minLength: validation?.email?.min_length,
                    pattern: new RegExp(validation?.email?.regex, 'g')
                  })}
                />
                {errors.email && <div className={`h-5 mb-2 w-full m-0 ${(errors.email || errors.email) ? 'visible' : 'invisible'}`}>
                  <small className="error-block w-full" data-testid='error-message-email'>
                    {errors.email && "Please enter valid email."}
                  </small>
                </div>}
              </label>
            </div>
            {amc_create_account?.web_detail?.url && <div className="sm:px-4">
              <div className="txt-type-para font-b10 theme-text-color">Not a member? Join AMC Stubs to apply for a card.</div>
              <a
                href={amc_create_account?.web_detail?.url}
                className="font-bn4 color-theme my-6 text-small-btn inline-block"
                target="_blank"
              >
                Create AMC Stubs Account
              </a>
            </div>}
          </div>
        </form>
        {/* Need to keep */}
        {/* <div className="flex flex-row mb-10 w-full space-x-4">
          <span>
            <input
              type="checkbox"
              name="basic-info"
              data-testid="basic-info-checkbox"
              id="basic-info-checkbox"
              className="invisible absolute"
              {...register("basic-info", {
                required: true
              })}
            />
            <label className="df-icon-unchecked" htmlFor="basic-info-checkbox">
              <IconUnCheck />
            </label>
            <label className="df-icon-checked" htmlFor="basic-info-checkbox">
              <IconCheck />
            </label>
          </span>
          <label className="text-xs txt-type-para">
            {ReactHtmlParser(props.stepData?.next_screen?.capture_name_email?.user_consent_model?.consent_description)}
          </label>
        </div> */}
        <div className="text-center">
          {!submitting && <BtnNext data-testid="next-btn" className={!isValid ? 'opacity-25 inline-block' : ''} onClick={handleSubmit(onFormSubmit)} />}
          {submitting && <BtnSpinnerNext />}
        </div>
      </div>
    </div>
  );
}

export default CapureEmail;
