import React, { useEffect, useState } from "react";
import { FirstCharUpperCase } from "../utils/text";
import { workflow } from "../constants/workflow.constants";
import EyeIcon from "../components/EyeIcon";
import EyeCrossIcon from "../components/EyeCrossIcon";

type Props = {
    input_field: Object;
    label: string;
    placeholder?: string;
    key?: string;
    setShowButton: (val: boolean) => void;
    defaultValue?: string;
    index?: number;
    setIndividualFailure: (val: Array<boolean>) => void;
    individualFailure: boolean[];
    wpStyleClass?: string;
    labelTestId?: string;
    secureEyeTestId?: string;
};

export function SecureTextInput(props: Props) {
    const [HasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [inputType, setInputType] = useState('password');
    const [value, setValue] = useState(props.defaultValue);

    let className = props.input_field['is_editable'] ? ' border-b-2' : 'opacity-30'

    useEffect(() => {
        if (props.defaultValue) {
            let individualFailure = props.individualFailure
            individualFailure[props.index] = false
        }
    })

    const onToggleMaskingClick = () => {
        setInputType(inputType === 'text' ? 'password' : 'text');
    };

    function handleChange(event) {
        let newValue = event.target.value;
        setValue(newValue)

        if ((newValue.length >= props.input_field['min_length'] && newValue.length <= props.input_field['max_length']) && new RegExp(props.input_field['validation_regex']).test(newValue)) {
            setHasError(false)
            props.setShowButton(!false);
        } else {
            setHasError(true)
            props.setShowButton(!true);
            setErrorMessage(workflow[props.input_field['identifier']])
        }
    }

    return (
        <div className={`mb-10 ${props?.wpStyleClass ?? ''}`}>
            <label className="relative block">
                <div className="uppercase mb-2 tracking-wide field-caption" data-testid={props.labelTestId}>
                    {FirstCharUpperCase(props.label)}
                </div>
                <input
                    type={`${inputType ?? 'text'}`}
                    name={props.input_field["identifier"]}
                    data-testid={props.input_field["identifier"]}
                    placeholder={props.placeholder ?? ''}
                    max={150}
                    className={`bg-transparent w-full h-12 text-3xl text-field ${className}`}
                    disabled={props.input_field['is_editable'] ? false : true}
                    onChange={handleChange}
                    defaultValue={value}
                    required
                />
                <span className="absolute right-0 top-1/2" data-testid={props.secureEyeTestId} onClick={onToggleMaskingClick}>
                  {inputType === 'password' ? <EyeIcon /> : <EyeCrossIcon /> }
                </span>
            </label>
            {HasError ? (
                <div>
                    <p className="error-block text-xs absolute my-2">{errorMessage}</p>
                </div>
            ) : null}
        </div>
    )
}

export default SecureTextInput;
