import moment from "moment";
import React, {useEffect, useState} from "react";
import {FirstCharUpperCase} from "../utils/text";
import {workflow} from "../constants/workflow.constants";
import IconUnCheck from "../components/IconUnCheck";
import IconCheck from "../components/IconCheck";
import ReactHtmlParser from "html-react-parser";

type Props = { input_field: Object; label: string; key: string; setShowButton: Function, defaultValue: string, index: number, setIndividualFailure: Function, individualFailure: boolean[], setAddress: Function, address: object };

export function CheckboxInputType(props: Props) {
   let identifierToKeyMap = {'company_consent_checkbox': 'accepted_company_policy'}

  useEffect(() => {
     if(props.individualFailure[props.index] === undefined) {
      props.individualFailure[props.index] = !props.individualFailure[props.index]
     }
  })


  function handleChange(event) {
    let newValue = event.target.value === 'on';
    if(props.input_field['is_mandatory'] === 'undefined' || props.input_field['is_mandatory']) {
      let key = identifierToKeyMap[props.input_field['identifier']] ?  identifierToKeyMap[props.input_field['identifier']] : props.input_field['identifier']
      props.address[key] = newValue
      props.setAddress(props.address)

      let individualFailure = props.individualFailure
      individualFailure[props.index] = !individualFailure[props.index]
      props.setShowButton(!individualFailure.includes(true));
    }
  }

  return (
    <div className="flex flex-row mb-10 w-full space-x-4">
          <span>
            <input
              type="checkbox"
              name="basic-info"
              data-testid="basic-info-checkbox"
              id={props.input_field["identifier"]}
              className="invisible absolute"
              onChange={handleChange}
            />
            <label className="df-icon-unchecked" htmlFor={props.input_field["identifier"]}>
              <IconUnCheck/>
            </label>
            <label className="df-icon-checked" htmlFor={props.input_field["identifier"]}>
              <IconCheck/>
            </label>
          </span>

      <label className="text-xs txt-type-para">
        {ReactHtmlParser(props.input_field['value'])}
      </label>
    </div>
  )
}

export default CheckboxInputType;
