import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import {post, postWithAuthHeader} from "../utils/client";
import { REFRESH_TOKEN } from "../constants/urls.constants";
import BtnSpinnerNext from '../components/BtnSpinnerNext';
import {track} from "../utils/analytics";
import {EVENT} from "../constants/events.constants";

type Props = {
  handleSubmit: Function,
  mobile_number: string,
  currentStep: any
};

function PhoneUpload(props: Props) {
  const { handleSubmit, mobile_number, currentStep } = props;
  const template = currentStep["ui_template_type"];
  const step = currentStep[template];

  useEffect(() => {

    postWithAuthHeader(REFRESH_TOKEN, { refresh_token: Cookies.get("application_refresh_token") }, EVENT.onb_refresh_token_failed).then(function (response) {
      track(EVENT.onb_phone_uploaded)
      handleSubmit({ mobile_number: mobile_number });
    }).catch(er => {
      window.location.href = '/apply-card/error'
    })
  }, [handleSubmit, mobile_number]);

  return (
    <div>
      <p className="text-main mb-10">{step.upload_state.title}</p>
      <div className="content-container">
        <BtnSpinnerNext />
      </div>
    </div>
  );
}

export default PhoneUpload;
