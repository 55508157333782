import { useContext, useState } from "react";
import { numberFormatter } from "../../utils/numberFormatter";
import {AccountContext} from "../NewPayments";

const CUSTOM_AMOUNT_MIN_VALUE = 0.01;

const PaymentCard = ({
    card,
    selected,
    updateCard,
    enable_selection,
    amount,
}) => {

    const {partnerName, customAmt, setCustomAmt} = useContext(AccountContext) || {}

    const cardCSS = `w-full paymentCard x-sm:h-32 lg:h-40 ${enable_selection ? 'cursor-pointer' : 'cursor-default'} x-sm:py-3.5 x-sm:px-6 x-sm:mr-0 x-sm:mb-4 md:mb-4 md:py-3.5 lg:pt-4 lg:pb-8 md:px-7 lg:px-5 hover:bg-white hover:text-black rounded-3xl mr-4 dashboard-card-${partnerName}`;
    const cardCSSSelect = `activeCard`;   
    const [customAmtErr, setCustomAmtErr] = useState(false);
    const customErr = customAmt === "" || Number(customAmt) <= CUSTOM_AMOUNT_MIN_VALUE;

    function handleChange(evt) {
        setCustomAmt(evt.target.validity.valid ? evt.target.value : customAmt);
        Number(evt.target.value) <= CUSTOM_AMOUNT_MIN_VALUE || evt.target.value === ""
          ? setCustomAmtErr(true)
          : setCustomAmtErr(false);
      }    

    return (
        <div
          key={card.id}
          data-testid={`payment-card-${card.key}`}
          className={
            selected.key === card.key
              ? `${cardCSSSelect} ${cardCSS}`
              : !enable_selection ? `${cardCSS} cursor-default` : cardCSS
          }
          onClick={() => {
            if(!enable_selection) {
              return false;
            }
            updateCard(card);
            selected.key.includes("FIXED-AMOUNT") && customErr
              ? setCustomAmtErr(true)
              : setCustomAmtErr(false);
          }}
        >
          <div className="flex flex-row justify-between x-sm:mb-3.5 md:mb-0 lg:mb-10 check">
            <div className="x-sm:flex md:flex lg:block">
              <p className="text-xs text-t1-color text-caption-6 uppercase">{card.text1}</p>
              <p className="x-sm:ml-0.5 md:ml-0.5 lg:ml-0 text-xs text-t1-color text-caption-6 uppercase">{card.text2}</p>
            </div>
            {enable_selection && <div className="roundCheckbox">
              <input
                type="checkbox"
                data-testid={`input-${card.key}`}
                id={card.id}
                checked={selected.key === card.key}
              />
              <label htmlFor={card.id}></label>
            </div>}
          </div>
          {card.id !== "card4" ? (
            <div>
              <p className="text-4xl font-bold text-1-heading" data-testid={`amount-${card.key}`}>
                {typeof amount === 'number' ? numberFormatter(amount) : amount}
              </p>
              <div className="flex my-2">
                {(selected.key === card.key && amount <= CUSTOM_AMOUNT_MIN_VALUE) ? (
                  <span className="text-xs cardActionErr font-avenir-book">
                    {`Amount must be more than $${CUSTOM_AMOUNT_MIN_VALUE}`}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div>
                <span className="font-bold text-4xl text-1-heading">$</span>
                {enable_selection && <input
                  className="md:w-36 w-48 bg-transparent font-bold text-4xl text-1-heading"
                  type="text"
                  pattern="^[0-9\.]*$"
                  onChange={handleChange}
                  maxLength={6}
                  value={customAmt}
                />}
              </div>
              <div className="flex my-2">
                {customAmtErr && selected.key === "FIXED-AMOUNT" ? (
                  <span className="text-xs cardActionErr font-avenir-book">
                    {`Enter amount higher than $${CUSTOM_AMOUNT_MIN_VALUE}`}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>        
    )
}

export default PaymentCard;