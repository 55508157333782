import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import BtnNext from '../components/BtnNext';
import { useHistory, useLocation } from "react-router-dom";
import BtnSpinnerNext from '../components/BtnSpinnerNext';
import { PHONE_TRIGGER_ENDPOINT_V3 } from '../constants/urls.constants';
import { post } from '../utils/client';
import { formatMobileNumber, unFormatMobileNumber } from "../utils/mobileNumberFormatter";
import ClientStorage from '../utils/client-storage';
import SignInVerifyPhoneOtp from './SignInVerifyPhoneOtp';
import SignInVerifyEmailOtp from './SignInVerifyEmailOtp';
import CapureEmail from './CapureEmail';
import SignInError from './SignInError';
import {
  logErrorToSentry,
  getNewSignInCommonEventProps,
  getUSPhoneNumber,
  unFormattedPhoneNumber,
  validatePhoneNumber,
  appSentryCaptureError
} from '../utils/helpers';
import { AxiosError } from 'axios';
import { parse } from "query-string";
import SignInVerifyEmailCallback from './SignInVerifyEmailCallback';
import { SignInQueryType } from '../types/SignInQueryType';
import SignInExistingUser from './SignInExistingUser';
import { identifyWithPhone, track } from '../utils/analytics';
import { EVENT } from '../constants/events.constants';
import AmcCreateAccount from "./AmcCreateAccount";
import { loginMessage } from "../utils/constants";
import ReactHtmlParser from "html-react-parser";
import { DASHBOARD_PATH } from "../constants/route-paths.constants";
import jwtDecode from "jwt-decode";
import { getStaticErrorActions } from "../utils/phone-signin-dynamic-actions";
import CaptureSignInSSNDBO from './CaptureSignInSSNDBO';

type Props = {};

function SignInByPhone(props: Props) {
  const appConfig = window['appConfig'];
  const min_length = 10;
  const max_length = 12;
  const pattern = /^[0-9]{10}\d*$/;
  const location: any = useLocation();
  const history: any = useHistory();
  const [isValid, setIsValid] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errors, setErrors] = useState(null);
  const query: SignInQueryType | any = parse(location.search.replace("?", ""));
  const inputEl = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState(formatMobileNumber(query.phone?.replace("+1", "")));
  const [loading, setLoading] = useState(null);
  const [stepData, setStepData] = useState<any>(false);
  const [captureNameEmailStepData, setCaptureNameEmailStepData] = useState<any>(null);
  const isInitialMount = useRef(true);


  const isIsNumberCashed = (query.flow && isSubmitClicked && validatePhoneNumber(ClientStorage.getLocal('phone_number'), pattern, min_length, max_length)) ? 'signin' : null;
  const isSigninFlow = query.flow === "signin" ? true : false;
  const ClickedVerifyEmail = (query.email && query.otp) ? "verify_email_otp" : null;
  const inputTitle = isSigninFlow ? loginMessage('phoneTitle') : "Enter your phone number";
  const isValidSignFlow = (query?.flow !== undefined && query?.flow !== 'signin');
  const verifyingEmailFromLink = (query?.email !== undefined && query?.otp !== undefined);
  const isOppfiTenant = window['appConfig'].PARTNER_NAME === 'oppfi';

  // Below if block redirect page to 404 page based on below criteria.
  // 1. If url has flow query parameter but its value is not signin.
  // 2. Or, If tenant is oppfi and there no flow query parameter.

  if (isValidSignFlow || ((isValidSignFlow && isOppfiTenant) || (isOppfiTenant && query?.flow === undefined && !verifyingEmailFromLink))) {
    window.location.href = '/apply-card/404';
  }

  const handleValidation = useCallback(
    (newValue: string): boolean | undefined | null => {
      let valid = validatePhoneNumber(newValue, pattern, min_length, max_length);
      if (valid) {
        setIsValid(true)
        setHasError(false);
      } else {
        setIsValid(false)
        setHasError(true);
      }
      return valid;
    },
    [pattern]
  );

  function renderTermsOfUse(url?: string) {
    if (url) {
      return (<p className='mt-5'>
        <small>
          By proceeding, you confirm that you agree to the <a data-testid="link-tou" className='underline' href={url} target="_blank" rel="noreferrer">Terms of Use</a>.
        </small>
      </p>);
    }
    return null;
  }

  const submit = useCallback((evet?: FormEvent<HTMLFormElement>) => {
    evet?.preventDefault();

    let phone = getUSPhoneNumber(unFormatMobileNumber(phoneNumber));

    identifyWithPhone(null, phone);

    let payload = {
      phone: phone,
      tenant_id: window['appConfig']['PROGRAM_ID']
    };

    if (query.flow) {
      track(EVENT.dfc_auth_signin_phone_next_clicked, {
        ...getNewSignInCommonEventProps(query),
        phone: phone
      });
    } else {
      track(EVENT.dfc_auth_signup_phone_next_clicked, {
        ...getNewSignInCommonEventProps(query),
        phone: phone
      });
    }

    if (handleValidation(phoneNumber) !== true) {
      return null;
    }

    setLoading(true);
    setIsSubmitClicked(true);
    post(PHONE_TRIGGER_ENDPOINT_V3, payload)
      .then((data) => {
        ClientStorage.setLocal('non_cached_phone_number', phone);
        setIsSubmitClicked(false);
        setStepData({
          ...data,
          phone: phone
        });

        track(
          EVENT.dfc_auth_phone_initiate_succeed,
          {
            ...getNewSignInCommonEventProps(query),
            requestData: payload,
            responseData: data,
          }
        );
      })
      .catch((err: AxiosError) => {

        logErrorToSentry('dfc_auth_phone_initiate_failed', payload, err);

        setErrors(err?.response?.data);
        setHasError(true);
        track(EVENT.dfc_auth_phone_initiate_failed, {
          ...getNewSignInCommonEventProps(query),
          ...err?.response?.data
        });
        workflowSubmitCb({
          ...err?.response?.data,
          ...getStaticErrorActions(err?.response?.data?.code, {
            ...err?.response?.data,
            title: err?.response?.data?.message,
            phone: phone,
            redirectTo: `${window.location.pathname}${window.location.search}`
          })
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleValidation, phoneNumber, query]);

  useEffect(() => {
    ClientStorage.setLocal("isSigninFlow", isSigninFlow.toString());
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (query.flow) {
        track(
          EVENT.dfc_auth_signin_phone_screen,
          getNewSignInCommonEventProps(query)
        );
      } else {
        track(
          EVENT.dfc_auth_signup_phone_screen,
          getNewSignInCommonEventProps(query)
        );
      }
    }

    if (query.phone) {
      submit();
    }
    return () => null;
  }, []);

  const setInputValue = (value: string) => {
    inputEl.current.value = value
  };

  const setValues = (phoneNumberString: string) => {
    let _formattedMobileNumber = formatMobileNumber(phoneNumberString);
    setPhoneNumber(_formattedMobileNumber);
    setInputValue(_formattedMobileNumber);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    let newValue = e.target.value;
    const phoneNumberString = unFormattedPhoneNumber(newValue) || "";
    const countryCodeElm = document.querySelector("#countryCode");

    if (e.target.value !== "") {
      countryCodeElm.classList.remove("hidden");
      e.target.classList.add("pl-11");
    } else {
      countryCodeElm.classList.add("hidden");
      e.target.classList.remove("pl-11");
    }
    handleValidation(newValue);
    setValues(phoneNumberString);
  }

  const workflowSubmitCb = (data: any) => {
    setStepData(data);
    if (data?.next_screen?.identifier === "capture_name_email") {
      setCaptureNameEmailStepData(data);
    }

    if (data?.id_token) {
      ClientStorage.setLocal("application_token", data?.id_token)
      ClientStorage.setLocal("application_refresh_token", data?.refresh_token)
      ClientStorage.setLocal("is_callback_flow", 'true')

      try {
        let account_id = jwtDecode(data?.id_token)['account_id']
        if (window['appConfig'].ENABLE_JWT_TOKEN_PATH_REDIRECTION === 'true' && account_id !== '00000000-0000-0000-0000-000000000000') {
          if (data.phone) {
            ClientStorage.setLocal("phone_number", getUSPhoneNumber(data.phone));
          }
          window.location.replace(window.location.origin + DASHBOARD_PATH);
        } else {
          history.push(`/apply-card`, {
            phone: getUSPhoneNumber(data.phone)
          });
        }
      } catch (e) {
        appSentryCaptureError("id_token_callback", { phone: getUSPhoneNumber(data.phone), id_token: data?.id_token }, e)
      }
      return null;
    }
  };
  switch (stepData?.next_screen?.identifier || isIsNumberCashed || ClickedVerifyEmail) {
    case "capture_phone_otp":
    case "signin":
      return (
        <SignInVerifyPhoneOtp
          query={location.search || ""}
          stepData={stepData || location?.state}
          mobile_number={phoneNumber}
          workflowSubmitCb={workflowSubmitCb}
        />
      );
    case "capture_email_otp":
      return (
        <SignInVerifyEmailOtp
          query={location.search}
          stepData={{
            ...stepData,
            phone: unFormatMobileNumber(phoneNumber)
          }}
          workflowSubmitCb={workflowSubmitCb}
        />
      );
    case "verify_email_otp":
      if (["expired_otp", "invalid_otp"].includes(stepData?.code)) {
        return (
          <SignInError
            stepData={{
              ...stepData,
              title: stepData.message
            }}
          />
        );
      } else if (stepData?.code === "existing_user_email") {
        return (
          <SignInExistingUser
            stepData={{
              ...stepData,
              title: stepData?.message
            }}
            captureNameEmailStepData={captureNameEmailStepData}
            workflowSubmitCb={workflowSubmitCb}
          />
        );
      } else {
        return (
          <SignInVerifyEmailCallback
            stepData={{
              phone: unFormatMobileNumber(ClientStorage.getLocal('non_cached_phone_number'))
            }}
            workflowSubmitCb={workflowSubmitCb}
          />
        );
      }
    case "capture_name_email":
    case "capture_name_email_amc":
      return (
        <CapureEmail
          stepData={stepData}
          query={location.search}
          workflowSubmitCb={workflowSubmitCb}
        />
      );
    case "capture_dob_ssn":
    case "capture_additional_data":
      return (
        <CaptureSignInSSNDBO
          stepData={stepData}
          query={location.search}
          workflowSubmitCb={workflowSubmitCb}
        />
      );
    case "existing_user_email":
      return (
        <SignInExistingUser
          stepData={stepData}
          captureNameEmailStepData={captureNameEmailStepData}
          workflowSubmitCb={workflowSubmitCb}
        />
      );
    case "invalid_user":
    case "send_otp_error":
    case "internal_server_error":
    case "submit_additional_details_error":
    case "invalid_2fa":
    case "web_login_not_allowed":
      return (
        <SignInError
          stepData={stepData}
        />
      );
    case "third_party_account_not_found":
      return (
        <AmcCreateAccount
          stepData={stepData}
          workflowSubmitCb={workflowSubmitCb}
        />
      );
    default:
      return (
        <div className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page`}>
          {!query.phone && <h1 className="text-main md:font-bold sm:font-bold x-sm:font-bold md:mb-2 x-sm:mb-4 text-1-heading text-headline-h1" data-test-id="sign_in_page_title">{inputTitle}</h1>}
          {isSigninFlow && !query.phone && <p className="text-sm txt-type-para font-normal mb-8 text-1-heading-2 text-t2-color text-t2 text-b3" data-testid="text-subtitile">{ReactHtmlParser(loginMessage('phoneSubTitle'))}</p>}
          <div className="content-container md:mt-3.5">
            <form className='mobile-number-form' onSubmit={submit}>
              {!query.phone && <div className="pb-7 relative">
                <label className="">
                  <div
                    className="mb-2 mt-4 tracking-wide field-caption text-c2"
                    data-testid="field-caption">PHONE</div>
                  <div className="relative flex">
                    <div className="absolute top-0 left-0 text-2xl text-color hidden" id="countryCode">+ 1</div>
                    <input
                      type="text"
                      name="phone"
                      minLength={min_length}
                      maxLength={max_length}
                      onChange={handleChange}
                      ref={inputEl}
                      onBlur={e => formatMobileNumber(e.target.value)}
                      className="bg-transparent w-full h-12 text-3xl text-field border-b-2 pb-3.5 text-b1"
                      placeholder="Enter phone number"
                      required
                      data-testid="input-phone"
                    />
                  </div>
                  {renderTermsOfUse(window?.['appConfig']?.TERMS_OF_USE_URL)}
                </label>
                {!errors && <p className={`${hasError ? 'visible' : 'invisible'} error-block text-xs absolute my-2`} data-testid="error-label">Enter valid phone number.</p>}
                {errors?.details?.phone?.map((error: string, idx: number) => {
                  return (<p key={idx} className={`${hasError ? 'visible' : 'invisible'} error-block text-xs absolute my-2`} data-testid="error-label">{error}</p>);
                })}
              </div>}
              <div className="text-center">
                {!loading && <BtnNext data-testid="next-btn" className={!isValid ? 'opacity-25 inline-block pointer-events-none' : ''} onClick={submit} />}
              </div>
              {loading && <BtnSpinnerNext />}
            </form>
          </div>
        </div>
      );
  }
}

export default SignInByPhone
