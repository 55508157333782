import React, { useEffect } from "react";
import emitter from '../utils/emitter';
import { track } from "../utils/analytics";

import {IMAGE_PREFIX} from "../constants/urls.constants";
import DollarIcon from "../components/DollarIcon";
import { numberFormatter } from "../utils/numberFormatter";
import { autoPoll } from "../utils/auto_poll";
import { isOnboardingRefreshV2Enabled } from "../utils/helpers";

type Props = {
  setWorkflow: Function,
  breadcrumbs: Object,
  workflow: Object,
  currentStep: Object | any
};

function UploadDocs(props: Props) {
  const template = props.workflow["steps"][0]["ui_template_type"]
  const step = props.workflow["steps"][0][template];
  const title = step.default_state.title;
  const subtitle = step.subtitle;
  const description = step.description;
  const onbRefreshClasses = 'w-full h-full items-center';

  const partnerName = window['appConfig'].PARTNER_NAME;

  const illustrationOfId = `${IMAGE_PREFIX()}/${step.uploadId.image_name}.png`;
  const unbenanntesProjekt = `${IMAGE_PREFIX()}/${step.selfie.image_name}.png`;

  useEffect(() => {
    const intervalId = autoPoll(props.workflow, props.setWorkflow);

    emitter.emit('progress', { breadcrumbs: props.breadcrumbs, identifier: props.currentStep.step_info.step_identifier, });

    return () => {
      clearInterval(intervalId);
    }

  }, [props.breadcrumbs, props.currentStep, props.setWorkflow, props.workflow]);

  return (
    <div className={`${isOnboardingRefreshV2Enabled() ? onbRefreshClasses : 'theme-approval-page absolute w-full md:w-4/5 bottom-0 left-0 top-12 md:top-0 x-sm:top-36 h-full flex flex-1 items-center'} app-container-${partnerName} onb-workflow-page`}>
      <div className="content-container">
        {!isOnboardingRefreshV2Enabled() &&
          <div className="w-3/5 m-auto">
            <p className='text-white text-center text-xs z-20 mb-1 uppercase' data-testid="title">{title}</p>
            <p className='text-main text-center z-20' data-testid="credit-limit"><sup><DollarIcon /></sup>{numberFormatter(step.credit_limit, false)}</p>
          </div>
        }
        {isOnboardingRefreshV2Enabled() && 
          <div className="w-3/4 m-auto">
            <p data-testid="subtitle" className='text-main text-center z-20 mb-2 w-full m-auto'>{subtitle}</p>
          </div>
        }
        <div className="w-full md:w-3/4 m-auto">
          <div className={`${isOnboardingRefreshV2Enabled() ? 'mt-14' : 'grid grid-flow-col grid-cols-2 grid-rows-1 gap-0 mb-8 mt-8'}`}
          >
            <img src={illustrationOfId} data-testid="illustrationOfId" alt="Illustration of id" className="object-contain mx-auto" />
            {!isOnboardingRefreshV2Enabled() && 
              <img src={unbenanntesProjekt} data-testid="unbenanntesProjekt" alt="unbenanntes project" className="object-contain mx-auto" />
            }
          </div>
        </div>
        {!isOnboardingRefreshV2Enabled() && 
          <>
            <p data-testid="subtitle" className='text-main text-center z-20 mb-2 w-3/5 m-auto'>{subtitle}</p>
            <p data-testid="description" className='text-white text-base text-center z-20 mb-2 mt-10 w-3/5 m-auto'>{description}</p>
          </>
        }
      </div>
    </div>
  );
}

export default UploadDocs;
