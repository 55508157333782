import React from "react";
import {autoPoll} from "../utils/auto_poll";
import emitter from "../utils/emitter";
import {track} from "../utils/analytics";
import {IMAGE_PREFIX} from "../constants/urls.constants";

type ApplicationInProgressProps = {
  setWorkflow: Function
  workflow: Object
};
type ApplicationInProgressState = {intervalId: any};

class ApplicationInProgress extends React.Component<ApplicationInProgressProps, ApplicationInProgressState> {
  componentDidMount() {
    this.setAutoPoll();
  }

  setAutoPoll() {
    const interval = autoPoll(this.props.workflow, this.props.setWorkflow)
    const breadcrumbs = this.props.workflow['breadcrumbs'];
    const identifier = this.props.workflow["steps"][0].identifier;
    const showNextSteps = false;
    emitter.emit('progress', { breadcrumbs, identifier, showNextSteps });
    this.setState({intervalId: interval});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  render() {
    const template = this.props.workflow["steps"][0]["ui_template_type"];
    const step = this.props.workflow["steps"][0][template];
    const imgSrc = `${IMAGE_PREFIX()}/${step.image_name}.png`;

    track('onb_application_in_progress')

    return (
      <div className="text-center">
        <p className="text-main mb-10" data-testid="title">
          {step.default_state.title}
        </p>
        <img className="w-3/4 mx-auto my-4" src={imgSrc} alt="not found"/>
        <p className="theme-text2-color" data-testid="description">
          {step.description}
        </p>
      </div>
    );
  }
}

export default ApplicationInProgress;
