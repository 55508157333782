import React, { useEffect, useRef } from "react";
import FancyShadow from "./FancyShadow";

type Props = {
  text: string,
  cssClasses?: string,
  timeout?: number
};

function Toast(props: Props) {
  const parentElm = useRef(null);
  const { text, cssClasses, timeout } = props;

  useEffect(() => {
    if(parentElm.current) {
      setTimeout(() => {
        parentElm.current.style.display = 'none';
      }, timeout || 10000);
    }
  }, [timeout]);

  return (
    <div ref={parentElm} className={`app-toast ${cssClasses || ''}`}>
      <FancyShadow cssClasses="oppfi-fancy-shadow">
        {text}
      </FancyShadow>
    </div>
  );
}

export default Toast;