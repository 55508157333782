import { useIdleTimer } from "react-idle-timer"

const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
    const idleTimeout = 1000 * idleTime;

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        onIdle,
        debounce: 500
    })

    return {
        idleTimer
    }
}

export default useIdleTimeout;