import {
  CC_BANK_PRODUCTS,
  PRODUCT_ID_ALIAS,
} from "./constants/product-constants";
import {
  IMAGE_BASE_URL,
  IMAGE_PREFIX,
  partnerName,
} from "./constants/urls.constants";
import { setAMCThemeVars } from "./style-vars/amc.vars";
import { setBlockfiThemeVars } from "./style-vars/blockfi.vars";
import {
  setCCBankDefaultThemeVars,
  setCCBankPeakThemeVars,
  setCCBankWarriorsThemeVars,
} from "./style-vars/ccbank.vars";
import { setCustomersBankThemeVars } from "./style-vars/customers_bank.vars";
import { setDefaultThemeVars } from "./style-vars/default.vars";
import { setDeserveThemeVars } from "./style-vars/deserve.vars";
import { setDeserveEduThemeVars } from "./style-vars/deserve_edu.vars";
import { setEarnestThemeVars } from "./style-vars/earnest.vars";
import { setCreditKrowdfitThemeVars } from "./style-vars/krowdfit.vars";
import { setOppfiThemeVars } from "./style-vars/oppfi.vars";
import { setSLMIgniteThemeVars } from "./style-vars/slm_ignite.vars";
import { setSWThemeVars } from "./style-vars/sw.vars";
import { currentProductAlias } from "./core/multiProduct/product-alias";

function loadTheme(context) {
  //TODO: Change submit button color according to color.
  setDefaultThemeVars();

  switch (context) {
    case "deserve":
      setDeserveThemeVars();
      break;
    case "blockfi":
      setBlockfiThemeVars();
      break;
    case "sw":
      setSWThemeVars();
      break;
    case "earnest":
      setEarnestThemeVars();
      break;
    case "oppfi":
      setOppfiThemeVars();
      break;
    case "krowdfit":
      setCreditKrowdfitThemeVars();
      break;
    case "customers_bank":
      setCustomersBankThemeVars();
      break;
    case "amc":
      setAMCThemeVars();
      break;
    case "slm_ignite":
      setSLMIgniteThemeVars();
      break;
    case "deserve_edu":
      setDeserveEduThemeVars();
      break;
    case "ccbank":
      // check here which theme vars should be loaded based on the localstorage value. Peak or Warriors or Default
      let theme_alias: string = "default";
      const product_id_alias_in_local_storage = currentProductAlias();
      switch (product_id_alias_in_local_storage) {
        case PRODUCT_ID_ALIAS.CCBANK_PEAK:
          setCCBankPeakThemeVars();
          break;

        case PRODUCT_ID_ALIAS.CCBANK_WARRIORS:
          setCCBankWarriorsThemeVars();
          break;
        default:
          setCCBankDefaultThemeVars();
          break;
      }
      break;
    default:
      break;
  }
}

export default loadTheme;
