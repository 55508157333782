import React, { useEffect } from 'react';
import Toast from '../../components/Toast';
import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { clearHomeRouteState, getCachedPhoneNumber, isNewSignInFlowEnabled, trackUTM } from "../../utils/helpers";
import { useHistory, useLocation } from 'react-router-dom';
import { NewSignInFlowActions } from '../../components/NewSignInFlowActions';
import { OldSignInFlowActions } from '../../components/OldSignInFlowActions';
import { AutoHideToast } from '../../components/AutohideToast';

const Krowdfit = (props: any) => {
  const location:any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  const footerLinks = {
    termsAndConditions: 'https://www.dropbox.com/s/xb0225k33s51pqr/The%20KrowdFit%20Wellness%20Rewards%20Card%20Cardholder%20Agreement%20APR%20Range%20June%202022.pdf?dl=0',
    electronicConsentPolicy: 'https://website-static.deserve.com/wp-content/uploads/2020/01/Web-Electronic-Consent-Policy-Rev-012020-copy.pdf',
    patriotActUSA: 'https://website-static.deserve.com/wp-content/uploads/2019/11/USA-PATRIOT-Act-Notice.pdf',
    privacyPolicy: 'https://www.deserve.com/privacy-policy',
    privacyNotice: 'https://website-static.deserve.com/wp-content/uploads/2019/11/PrivacyNotice.pdf',
    termsOfService: 'https://www.deserve.com/terms-and-conditions/terms-of-service/'
  };

  trackUTM('marketingData', window.location.href); // Track marketing data

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <>
      {title && <AutoHideToast title={title} timeout={6000} />}
      <div>
        <div className="bg-krowdfit w-full min-h-screen py-7">
          {message && <Toast cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center" text={message} timeout={6000} />}
          <div className="flex justify-between x-sm:items-center x-sm:px-5 px-20">
            <a className="inline-block " href="/"><img src={`${IMAGE_PREFIX()}/logo.svg`} alt="logo" /></a>
            <img className="inline-block x-sm:ml-16 x-sm:text-xs h-7" src={`${IMAGE_PREFIX()}/poweredby.svg`} alt="powered by deserve" />
          </div>
          <div className="kr-content-container x-sm:pt-0.5 md:pt-12 pb-4 flex flex-col x-sm:px-5 px-20">
            {!phoneNumber && <>
              <h1 className="font-aktiv-grotesk-light text-black x-sm:text-4xl text-5xl x-sm:leading-10 x-sm:mt-5 text-center lg:text-left">Fast. Digital. <br /> Contactless.</h1>
              <p className="theme-text-color text-lg mt-2.5 font-aktiv-grotesk-light text-center lg:text-left">It’s more than a credit card,<br /> it’s an experience.</p>
              <p className="theme-text-color x-sm:text-xs text-lg mt-4 font-aktiv-grotesk-regular text-center lg:text-left">See if you qualify with no negative impact to your credit*</p>
            </>}

            {phoneNumber && <>
              <h1 className="text-black text-6xl x-sm:leading-10 font-normal x-sm:text-4xl x-sm:mt-4 x-sm:text-center">Welcome back</h1>
              <p className="theme-text-color text-xl mt-4 mb-4 x-sm:mb-4 font-aktiv-grotesk-light x-sm:text-center">Continue with your phone number <span className='block'>verification to sign in</span></p>
            </>}
            <div className="w-full h-0.5 mt-3 bg-gray-300 lg:hidden" />
            <div className="lg:hidden flex justify-center pt-3 font-aktiv-grotesk-regular">
              <span>Sign in or Apply below</span>
            </div>
            {isNewSignInFlowEnabled() ? <NewSignInFlowActions showOnlySignIn={true} /> : <OldSignInFlowActions />}
            <div className='font-aktiv-grotesk-light text-xs mt-6 text-center lg:text-left'>*Subject to credit approval </div>
          </div>
          <img src={`${IMAGE_PREFIX()}/mob-tab-bg.png`} className='lg:hidden' />
        </div>
        <footer className="bg-white w-full krowdfit-footer pt-14 pb-28 px-20">
          <ul className="font-semibold">
            <li><a href={footerLinks.termsAndConditions}>Terms and Conditions</a></li>
            <li><a href={footerLinks.electronicConsentPolicy}>Electronic Consent Policy</a></li>
            <li><a href={footerLinks.patriotActUSA}>PATRIOT Act Notice</a></li>
            <li><a href={footerLinks.privacyPolicy}>Privacy Policy</a></li>
            <li><a href={footerLinks.privacyNotice}>Privacy Notice</a></li>
            <li><a href={footerLinks.termsOfService}>Terms of Service</a></li>
          </ul>
          <span>&#169; 2022 Deserve</span>
          <div className="mt-3">
            <p className='leading-6'>The Krowdfit Wellness Rewards Card is issued by Celtic Bank, Utah-Chartered Industrial Bank, Member FDIC and Powered by Deserve®.</p>
            <p className='leading-6'>All product names, logos, and brands are property of their respective owners. All company, product and service marks used in this website are for identification purposes only. Use of these names, logos, and brands does not imply endorsement.</p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Krowdfit;
