import React, { useEffect } from 'react';
import Toast from '../../components/Toast';
import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { clearHomeRouteState, getCachedPhoneNumber, isNewSignInFlowEnabled, trackUTM } from "../../utils/helpers";
import { useHistory, useLocation } from 'react-router-dom';
import { NewSignInFlowActions } from '../../components/NewSignInFlowActions';
import { OldSignInFlowActions } from '../../components/OldSignInFlowActions';
import { AutoHideToast } from '../../components/AutohideToast';

const BlockFi = (props: any) => {
  const location:any = useLocation();
  const history: any = useHistory();
  const { title } = location.state || {};
  const params = new URLSearchParams(location.search);
  const message = params.get("message");
  const phoneNumber = getCachedPhoneNumber();

  trackUTM('marketingData', window.location.href); // Track marketing data

  useEffect(() => {
    clearHomeRouteState(title, history);
  }, []);

  return (
    <>
      {title && <AutoHideToast title={title} timeout={6000} />}
      <div>
        <div className="container-blockfi bg-blockfi x-sm:bg-none w-full lg:min-h-screen x-sm:min-h-full pt-7 pb-2 px-20 x-sm:px-8 font-suisseintl-display">
          {message && <Toast
            cssClasses="w-1/3 x-sm:w-full m-auto absolute top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center"
            text={message} timeout={6000} />}
          <div className="flex justify-between mb-14 x-sm:mb-1 x-sm:items-center">
            <a className="inline-block x-sm:w-24 mb-6 w-60" href="/">
              <img src={`${IMAGE_PREFIX()}/logo.png`} alt="logo" />
            </a>
            <a className="inline-block x-sm:w-24 mb-6 w-40" href="/">
              <img src={`${IMAGE_PREFIX()}/evolve-logo.png`} alt="evolve-logo" />
            </a>
          </div>
          <div className="sw-content-container md:flex md:justify-center md:flex-col min-h-screen">
            {!phoneNumber && <div>
              <h1 className="text-5xl x-sm:text-4xl x-sm:leading-9 x-sm:mb-4 mb-6 x-sm:mt-2.5 x-sm:text-center half-width x-sm:w-full"><span>Deserve card servicing portal for <br /> the BlockFi card</span></h1>
              <p className="text-2xl x-sm:mb-2 mt-1 x-sm:mt-0 font-suisseintl-display x-sm:text-center md:pb-7 x-sm:pb-0"><span>Make payments, view transactions, download <br />statements and more.</span></p>
            </div>}
            {phoneNumber && <h1 className="text-5xl x-sm:text-4xl x-sm:leading-9 x-sm:mb-2 mb-6 x-sm:mt-8 x-sm:text-center">Welcome back</h1>}
            {phoneNumber && <p className="text-s x-sm:mb-2 mt-1 x-sm:mt-0 font-suisseintl-display x-sm:text-center mb-4">Continue with your phone number <span className="block">verification to sign in</span></p>}
            <NewSignInFlowActions showOnlySignIn={true} />
          </div>
          <div className="md:hidden lg:hidden 2xl:hidden xl:hidden x-sm:flex x-sm:justify-center x-sm:pb-5 x-sm:pt-10">
            <img src={`${IMAGE_PREFIX()}/hero-card.png`} />
          </div>
          <div className="footer md:pt-6 x-sm:pt-14">
          </div>
        </div>
      </div>
    </>
  );
}

export default BlockFi;
