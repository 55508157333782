import { useEffect, useRef, useState } from 'react';
import * as PDFJS from "pdfjs-dist/es5/build/pdf";
import pdfjsWorker from "pdfjs-dist/es5/build/pdf.worker.entry";
import { DefaultTextLayerFactory } from "pdfjs-dist/lib/web/text_layer_builder";
import { DefaultAnnotationLayerFactory } from "pdfjs-dist/lib/web/annotation_layer_builder";
import { PDFPageView } from "pdfjs-dist/lib/web/pdf_page_view";
import { EventBus } from "pdfjs-dist/lib/web/ui_utils";
import BtnNext from '../components/BtnNext';
import 'pdfjs-dist/web/pdf_viewer.css'

type Props = {
  pdfURL: string;
  onUserReadPDF: (val: boolean) => void;
  showReadNext?: boolean
};

function PDFViewer(props: Props) {
  let nextPageCounter = 1;
  const { pdfURL, onUserReadPDF } = props;
  const [hideNext, setHideNext] = useState(false);
  let pdfTotalPages = useRef(null);

  useEffect(() => {
    PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    const CMAP_URL = "../../node_modules/pdfjs-dist/cmaps/";
    const CMAP_PACKED = true;

    const DEFAULT_URL = pdfURL;
    const SCALE = 0.7;

    const container = document.getElementById("pageContainer");

    const eventBus = new EventBus();

    // Loading document.
    const loadingTask = PDFJS.getDocument({
      url: DEFAULT_URL,
      cMapUrl: CMAP_URL,
      cMapPacked: CMAP_PACKED,
    });

    function renderPages(pdfDocument: any, pageNum: number) {
      pdfDocument.getPage(pageNum)
        .then(async (pdfPage: any) => {
          const pdfPageView = new PDFPageView({
            container,
            id: pageNum,
            scale: SCALE,
            defaultViewport: pdfPage.getViewport({ scale: SCALE }),
            eventBus,
            textLayerFactory: new DefaultTextLayerFactory(),
            annotationLayerFactory: new DefaultAnnotationLayerFactory(),
          });
          pdfPageView.setPdfPage(pdfPage);
          pdfPageView.draw();
        });
    }


    if (!hideNext) {
      loadingTask.promise.then(async (pdfDocument) => {
        let { numPages } = pdfDocument;

        pdfTotalPages.current = numPages;
        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          renderPages(pdfDocument, pageNum);
        }
      });
    }

    if (props.showReadNext === false) {
      setHideNext(true);
    }
  }, [onUserReadPDF, pdfURL, hideNext]);


  function nextBtnClick(event: any) {
    if ((nextPageCounter + 1) === pdfTotalPages.current) {
      onUserReadPDF(true);
      setHideNext(true);
    }

    if (nextPageCounter < pdfTotalPages.current) {
      document.querySelector(`[data-page-number="${++nextPageCounter}"]`).children[1].scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  let handleOnPDFScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      const rootElm = document.querySelector("#pageContainer");
      rootElm.classList.add('pdf-read-done');
      onUserReadPDF(true);
      setHideNext(true);
    }
  }

  return (
    <div className="pdf-wrapper">
      <div id="pageContainer" className="pdfViewer singlePageView" onScroll={handleOnPDFScroll}></div>
      {!hideNext && <BtnNext
        onClick={nextBtnClick}
        text="Read Next"
        className="text-white button-with-custom-text block text-center text-xl tracking-tight h-16 p-5 cursor-pointer w-72 rounded-full ml-auto mr-auto mt-4 z-50"
      />}
    </div>
  );
}

export default PDFViewer;
