import AxiosClient from "./axios-client";

export const getInstitutions = ({ accessToken, filter }) => {
  const url = `/api/bff-pnc/finicity_institutions`;
  return AxiosClient.getData(url, accessToken, filter ? { filter } : {}, {
    platform: "web",
  });
};

export const getFinicityLiteUrl = ({ accessToken, institution_id }) => {
  const url = `/api/bff-pnc/connect-url/institution/${institution_id}`;
  return AxiosClient.getData(url, accessToken, {}, { platform: "web" });
};

export const getFincityAccounts = (accessToken, payload) => {
  const url = `/api/bff-pnc/check-finicity-accounts`;
  return AxiosClient.putData(
    url,
    {
      ...payload,
    },
    accessToken,
    {
      platform: "web",
    }
  );
};

export const syncPaymentMethods = (accessToken, payload) => {
  const url = `/api/bff-pnc/sync-finicity-accounts`;

  return AxiosClient.postData(
    url,
    {
      ...payload,
    },
    accessToken,
    {
      platform: "web",
    }
  );
};

export const startFinicityConnectFix = ({ accessToken, payment_method_id }) => {
  const url = `/api/bff-pnc/v3/payments/${payment_method_id}/fix`;
  return AxiosClient.postData(url, {}, accessToken, {
    platform: "web",
  });
};
