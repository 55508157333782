import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { DeserveCardOverviewNav } from "./DeserveCardOverviewNav";
import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { OldSignInFlowActions } from "../../components/OldSignInFlowActions";
import { NewSignInFlowActions } from "../../components/NewSignInFlowActions";
import { clearHomeRouteState, getCachedPhoneNumber, isNewSignInFlowEnabled, isNewSignInFlowEnabledForDFC, isProveFlowEnabled } from "../../utils/helpers";
import { track } from "../../utils/analytics";
import { EVENT } from "../../constants/events.constants";
import { useLocation, useHistory } from "react-router-dom";
import Toast from "../../components/Toast";
import { QRCodeSVG } from "qrcode.react";


type DeserveCardOverviewProps = {};

export function DeserveCardOverview(props: DeserveCardOverviewProps) {
  const location: any = useLocation();
  const history: any = useHistory();
  const phoneNumber = getCachedPhoneNumber();
  const { title } = location.state || {};
  const toastTime = 6000;
  const QRCodeImageURL = window["appConfig"]["PROVE_FLOW_URL"];

  useEffect(() => {
    track(EVENT.dfc_card_overview_screen_viewed, {
      isNewSignInFlowEnabled: isNewSignInFlowEnabled(),
    });
    clearHomeRouteState(title, history);
  }, [history, title]);

  return (
    <>
      {location?.state?.title && <Toast
        cssClasses="w-1/3 x-sm:w-full fixed m-auto top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center rounded-lg"
        text={location?.state?.title}
        timeout={toastTime}
      />}
      <Helmet>
        <meta charSet="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="cleartype" content="on" />
        <meta name="google-site-verification" content="voZzeFEgZwHd_64qEaKlG03lRBKd4ClfeaSDtSnJWss" />
        <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,700,700i" rel="stylesheet" />

        <link rel="apple-touch-icon" sizes="57x57" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-57x57.png`} />
        <link rel="apple-touch-icon" sizes="60x60" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-60x60.png`} />
        <link rel="apple-touch-icon" sizes="72x72" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-72x72.png`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-76x76.png`} />
        <link rel="apple-touch-icon" sizes="114x114" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-114x114.png`} />
        <link rel="apple-touch-icon" sizes="120x120" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-120x120.png`} />
        <link rel="apple-touch-icon" sizes="144x144" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-144x144.png`} />
        <link rel="apple-touch-icon" sizes="152x152" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-152x152.png`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${IMAGE_PREFIX()}/app-icons/apple-icon-180x180.png`} />
        <link rel="icon" type="image/png" sizes="192x192" href={`${IMAGE_PREFIX()}/app-icons/android-icon-192x192.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${IMAGE_PREFIX()}/app-icons/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="96x96" href={`${IMAGE_PREFIX()}/app-icons/favicon-96x96.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${IMAGE_PREFIX()}/app-icons/favicon-16x16.png`} />
        <link rel="manifest" href={`${IMAGE_PREFIX()}/app-icons/manifest.json`} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content={`${IMAGE_PREFIX()}/app-icons/ms-icon-144x144.png`} />
        <meta name="theme-color" content="#ffffff" />
        <link rel="shortcut icon" href={`${IMAGE_PREFIX()}/app-icons/favicon.ico`} />
        <meta name="theme-color" content="#121212" />
        <title>Deserve Cards - Build Credit and Get Rewards | Deserve</title>

        <meta name="description" content="Deserve Credit Cards offers card options to students, professionals, new to country, and those on work visas. No credit history or deposit required, and no cosigner needed. Refer friends to start earning today!" />
        <meta name="msvalidate.01" content="DAC87732B3ADC9B3F4D720CF31273A5C" />
        <link rel="canonical" href="https://www.deserve.com/deserve_card_overview/" />
        <meta property="og:site_name" content="Deserve" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Deserve Cards - Build Credit and Get Rewards | Deserve" />
        <meta property="og:description" content="Deserve Credit Cards offers card options to students, professionals, new to country, and those on work visas. No credit history or deposit required, and no cosigner needed. Refer friends to start earning today!" />
        <meta property="og:url" content="https://www.deserve.com/deserve_card_overview/" />
        <meta property="fb:app_id" content="176478249732332" />
        <meta property="fb:admins" content="758112297532953" />
        <meta property="og:image" content={`${IMAGE_PREFIX()}/deserve_meta_image.jpg`} />
        <meta property="og:image:secure_url" content={`${IMAGE_PREFIX()}/deserve_meta_image.jpg`} />
        <meta property="article:published_time" content="2021-09-02T05:56:46Z" />
        <meta property="article:modified_time" content="2021-09-23T07:27:44Z" />
        <meta property="article:publisher" content="https://www.facebook.com/DeserveCards/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@DeserveCards" />
        <meta name="twitter:domain" content="www.deserve.com" />
        <meta name="twitter:title" content="Deserve Cards - Build Credit and Get Rewards" />
        <meta name="twitter:description" content="Deserve Credit Cards offers card options to students, professionals, new to country, and those on work visas. No credit history or deposit required, and no cosigner needed. Refer friends to start earning today!" />
        <meta name="twitter:image" content={`${IMAGE_PREFIX()}/deserve_meta_image.jpg`} />
        <script type="application/ld+json" className="aioseo-schema">
          {`{ "@context": "https:\/\/schema.org", "@graph": [{ "@type": "WebSite", "@id": "https:\/\/www.deserve.com\/#website", "url": "https:\/\/www.deserve.com\/", "name": "Deserve", "publisher": { "@id": "https:\/\/www.deserve.com\/#organization" } }, { "@type": "Organization", "@id": "https:\/\/www.deserve.com\/#organization", "name": "Deserve", "url": "https:\/\/www.deserve.com\/", "logo": { "@type": "ImageObject", "@id": "https:\/\/www.deserve.com\/#organizationLogo", "url": "\/wp-content\/uploads\/2019\/11\/logo-deserve-black.svg" }, "image": { "@id": "https:\/\/www.deserve.com\/#organizationLogo" }, "sameAs": ["https:\/\/www.facebook.com\/DeserveCards\/", "https:\/\/twitter.com\/DeserveCards\/", "https:\/\/www.instagram.com\/deservecards\/", "https:\/\/www.linkedin.com\/company\/deservecards\/"] }, { "@type": "BreadcrumbList", "@id": "https:\/\/www.deserve.com\/deserve_card_overview\/#breadcrumblist", "itemListElement": [{ "@type": "ListItem", "@id": "https:\/\/www.deserve.com\/#listItem", "position": "1", "item": { "@type": "WebPage", "@id": "https:\/\/www.deserve.com\/", "name": "Home", "url": "https:\/\/www.deserve.com\/" }, "nextItem": "https:\/\/www.deserve.com\/deserve_card_overview\/#listItem" }, { "@type": "ListItem", "@id": "https:\/\/www.deserve.com\/deserve_card_overview\/#listItem", "position": "2", "item": { "@type": "WebPage", "@id": "https:\/\/www.deserve.com\/deserve_card_overview\/", "name": "DFC", "description": "Deserve Credit Cards offers card options to students, professionals, new to country, and those on work visas. No credit history or deposit required, and no cosigner needed. Refer friends to start earning today!", "url": "https:\/\/www.deserve.com\/deserve_card_overview\/" }, "previousItem": "https:\/\/www.deserve.com\/#listItem" }] }] }`}
        </script>

        <link rel='dns-prefetch' href='//s.w.org' />
        <script type="text/javascript">
          {`
            window._wpemojiSettings = {"baseUrl":"https:\/\/s.w.org\/images\/core\/emoji\/13.0.1\/72x72\/","ext":".png","svgUrl":"https:\/\/s.w.org\/images\/core\/emoji\/13.0.1\/svg\/","svgExt":".svg","source":{"concatemoji":"https:\/\/www.deserve.com\/wp-includes\/js\/wp-emoji-release.min.js?ver=5.7.2"}};
            !function(e,a,t){var n,r,o,i=a.createElement("canvas"),p=i.getContext&&i.getContext("2d");function s(e,t){var a=String.fromCharCode;p.clearRect(0,0,i.width,i.height),p.fillText(a.apply(this,e),0,0);e=i.toDataURL();return p.clearRect(0,0,i.width,i.height),p.fillText(a.apply(this,t),0,0),e===i.toDataURL()}function c(e){var t=a.createElement("script");t.src=e,t.defer=t.type="text/javascript",a.getElementsByTagName("head")[0].appendChild(t)}for(o=Array("flag","emoji"),t.supports={everything:!0,everythingExceptFlag:!0},r=0;r<o.length;r++)t.supports[o[r]]=function(e){if(!p||!p.fillText)return!1;switch(p.textBaseline="top",p.font="600 32px Arial",e){case"flag":return s([127987,65039,8205,9895,65039],[127987,65039,8203,9895,65039])?!1:!s([55356,56826,55356,56819],[55356,56826,8203,55356,56819])&&!s([55356,57332,56128,56423,56128,56418,56128,56421,56128,56430,56128,56423,56128,56447],[55356,57332,8203,56128,56423,8203,56128,56418,8203,56128,56421,8203,56128,56430,8203,56128,56423,8203,56128,56447]);case"emoji":return!s([55357,56424,8205,55356,57212],[55357,56424,8203,55356,57212])}return!1}(o[r]),t.supports.everything=t.supports.everything&&t.supports[o[r]],"flag"!==o[r]&&(t.supports.everythingExceptFlag=t.supports.everythingExceptFlag&&t.supports[o[r]]);t.supports.everythingExceptFlag=t.supports.everythingExceptFlag&&!t.supports.flag,t.DOMReady=!1,t.readyCallback=function(){t.DOMReady = !0},t.supports.everything||(n=function(){t.readyCallback()},a.addEventListener?(a.addEventListener("DOMContentLoaded",n,!1),e.addEventListener("load",n,!1)):(e.attachEvent("onload",n),a.attachEvent("onreadystatechange",function(){"complete" === a.readyState && t.readyCallback()})),(n=t.source||{ }).concatemoji?c(n.concatemoji):n.wpemoji&&n.twemoji&&(c(n.twemoji),c(n.wpemoji)))}(window,document,window._wpemojiSettings);
          `}
        </script>
        <style type="text/css">
          {`
            img.wp-smiley,
              img.emoji {
                display: inline !important;
              border: none !important;
              box-shadow: none !important;
              height: 1em !important;
              width: 1em !important;
              margin: 0 .07em !important;
              vertical-align: -0.1em !important;
              background: none !important;
              padding: 0 !important;
            }
          `}
        </style>
        <link rel="https://api.w.org/" href="https://www.deserve.com/wp-json/" />
        <link rel="alternate" type="application/json" href="https://www.deserve.com/wp-json/wp/v2/pages/22907" />
        <link rel='shortlink' href='https://www.deserve.com/?p=22907' />
        <link rel="alternate" type="application/json+oembed" href="https://www.deserve.com/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwww.deserve.com%2Fdeserve_card_overview%2F" />
        <link rel="alternate" type="text/xml+oembed" href="https://www.deserve.com/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fwww.deserve.com%2Fdeserve_card_overview%2F&#038;format=xml" />

        <script type="application/javascript">
          {`
            (function(w,d,t,r,u){w[u] = w[u] || [];w[u].push({'projectId':'10000','properties':{'pixelId':'10097444'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y = YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){ }};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");
          `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=DC-10954873" />
        <script>
          {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'DC-10954873');
          `}
        </script>
      </Helmet>
      {isProveFlowEnabled()
        ? <div className="ovr-hero-bg-prove text-white">
            <DeserveCardOverviewNav />
            <section className={`ovr-hero flex items-center ${!phoneNumber ? 'lg:px-14' : 'lg:pt-0'}`}>
              <div className="container mx-auto lg:flex mt-7 lg:mt-0">
                <div className={`w-full lg:w-6/12 px-4 md:px-36 lg:px-0 ${phoneNumber ? 'lg:pt-24' : 'lg:pt-10 xl:pt-20 xl:px-20'}`}>
                  <div className={`${phoneNumber ? 'hidden' : ''}`}>
                    <h1 className="font-gt-alpina font-bold text-4xl text-center">A digital credit card you can use within minutes</h1>
                    <div className="font-sf-text text-lg lg:text-xl text-center mt-2 lg:px-10">See if you’re approved without a negative impact to your credit*</div>
                    <div className='font-sf-text hidden lg:block mt-4 mx-auto w-40 h-40 qrCode-block'>
                      <div className="qrCode-title text-xs pt-3.5 text-center">Scan to quick apply</div>
                      <QRCodeSVG className='mx-auto mt-1' size={125} includeMargin={true} value={QRCodeImageURL} />
                    </div>
                    <div className="w-60 h-px my-4 bg-gray-200 lg:hidden mx-auto">
                    </div>
                    <div className="font-sf-text lg:hidden text-lg text-center">
                      <span>Sign in or Apply below</span>
                    </div>
                  </div>
                  <div className={`${phoneNumber ? '' : 'hidden'}`}>
                    {<h1 className="text-4xl leading-9 mb-2 font-gt-alpina text-center font-bold">Welcome back</h1>}
                    {<p className="text-white text-lg mb-2 mt-1 font-sf-text text-center mb-4">Continue with your phone number <span className="block">verification to sign in</span></p>}
                  </div>
                  <div className={`${phoneNumber ? '' : 'lg:hidden'}`}>
                    {isNewSignInFlowEnabled() ? <NewSignInFlowActions showApplyBtn={false} /> : <OldSignInFlowActions />}
                  </div>
                  <div className="font-sf-text pt-3.5 lg:pt-14 lg:px-10 text-sm text-center">*Accepting your credit offer will result in a hard credit inquiry which may impact your credit score</div>
                </div>
                <div className="w-full lg:w-6/12">
                  <img src={`${IMAGE_PREFIX()}/home-img.png`} className='' />
                </div>
              </div>
            </section>
          </div>
        : <div className="ovr-hero-bg">
            <DeserveCardOverviewNav />
            <section className={`ovr-hero flex items-center ${!phoneNumber ? 'lg:py-8' : 'lg:py-32'}`}>
              <div className="container mx-auto">
                <div className="lg:w-5/12 x-sm:w-full x-sm:px-4 mb-4">
                  {!phoneNumber && <>
                    <h1 className="text-7xl leading-tight font-bold py-4 md:py-6 md:text-6xl md:w-5/6 x-sm:w-full x-sm:text-4xl">Fast. Digital. Contactless.</h1>
                    <p className="text-3xl md:text-2xl md:w-3/6">Deserve’s Digital First Card is more than a credit card. It’s an experience.</p>
                    <p className="py-4 text-sm">See if you’re approved without a negative impact to your credit*</p>
                  </>}
                  {phoneNumber && <>
                    <h1 className="text-7xl leading-tight font-bold py-4 md:py-6 md:text-6xl md:w-5/6 x-sm:w-full x-sm:text-4xl x-sm:text-center">Welcome back</h1>
                    <p className="text-xl md:text-xl md:w-4/6 x-sm:text-center">
                      Continue with your phone number <span className="block">verification to sign in </span>
                    </p>
                  </>}
                </div>
                {(isNewSignInFlowEnabled() || isNewSignInFlowEnabledForDFC()) ? <NewSignInFlowActions showApplyBtn={false} /> : <OldSignInFlowActions />}
                <p className="py-8 x-sm:px-4 text-xs">*Accepting your credit offer will result in a hard credit inquiry which may impact your credit score </p>
              </div>
            </section>
          </div>
      }

      <section className="py-16 x-sm:px-4">
        <div className="container mx-auto flex items-center x-sm:flex-wrap">
          <div className="w-2/4 x-sm:w-full">
            <div className="lg:w-2/3 lg:m-auto x-sm:w-full">
              <h1 className="text-5xl leading-snug font-semibold pb-6 x-sm:pb-0 x-sm:text-4xl">The Deserve Digital First Card™</h1>
              <ul className="list-none">
                <li className="text-2xl x-sm:text-xl mt-8 pl-8 ovr-p-list-icon">Instantly add to Apple Pay</li>
                <li className="text-2xl x-sm:text-xl mt-8 pl-8 ovr-p-list-icon">Rewards up to 1.5%†</li>
                <li className="text-2xl x-sm:text-xl mt-8 pl-8 ovr-p-list-icon">$0 Annual Fee</li>
                <li className="text-2xl x-sm:text-xl mt-8 pl-8 ovr-p-list-icon">No foreign transaction fees</li>
                <li className="text-2xl x-sm:text-xl mt-8 pl-8 ovr-p-list-icon">Mastercard World Benefits</li>
              </ul>
              <p className="text-sm mt-8">Physical card coming soon †See <a className="text-blue-500" href="https://static.deserve.com/resources/CHA/Deserve+Digital+Cardholder+Agreement+Terms+%26+Conditions+Range.pdf" target="_blank" rel="noreferrer">The Deserve Card Cardholder Agreement</a></p>
              <div className="flex items-center mt-8">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.deserve.deservecard" rel="noreferrer">
                  <img className="bg-black rounded border border-black" src={`${IMAGE_PREFIX()}/android-download.svg`} alt="playstore" />
                </a>
                <a className="inline-block ml-8" target="_blank" href="https://apps.apple.com/us/app/deserve-cards/id1489277226" rel="noreferrer">
                  <img className="bg-black border rounded border-black" src={`${IMAGE_PREFIX()}/ios-download.svg`} alt="appstore" />
                </a>
              </div>
            </div>
          </div>
          <div className="w-2/4 app-config h-full flex items-center x-sm:w-full x-sm:mt-12">
            <img className="block lg:m-auto lg:max-w-lg md:max-w-full x-sm:w-full" src={`${IMAGE_PREFIX()}/card_config.gif`} alt="card config" />
          </div>
        </div>
      </section>
      <section className="py-16 ovr-section-bg">
        <div className="container mx-auto flex items-center x-sm:flex-col-reverse x-sm:flex-wrap">
          <div className="w-2/4 app-config h-full flex items-center x-sm:w-full">
            <img className="block m-auto x-sm:max-w-full" src={`${IMAGE_PREFIX()}/card_features.png`} alt="deserve card features" />
          </div>
          <div className="w-2/4 x-sm:w-full x-sm:px-4 x-sm:text-center">
            <div className="lg:w-2/4 lg:m-auto">
              <h1 className="text-5xl leading-tight font-semibold pb-6 x-sm:pb-0 x-sm:text-4xl">Quick and easy application.</h1>
              <p className="text-4xl font-thin mt-8 x-sm:mt-4 leading-snug x-sm:text-2xl">Once you’re approved, add your new digital card to your mobile wallet within minutes</p>
              <div className="flex items-center mt-8 x-sm:justify-center">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.deserve.deservecard" rel="noreferrer">
                  <img className="rounded h-12" src={`${IMAGE_PREFIX()}/GPay.png`} alt="GPay" />
                </a>
                <a className="inline-block ml-8" href="https://apps.apple.com/us/app/deserve-cards/id1489277226" target="_blank" rel="noreferrer">
                  <img className="bg-black border rounded border-black" src={`${IMAGE_PREFIX()}/Apple-Pay.png`} alt="Apple Pay" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 x-sm:px-6">
        <div className="container mx-auto flex items-center x-sm:flex-wrap">
          <div className="w-2/4 x-sm:w-full x-sm:text-center">
            <div className="lg:w-2/4 m-auto x-sm:w-full">
              <h1 className="text-5xl leading-tight font-semibold pb-6 x-sm:pb-0 x-sm:text-4xl">Your transactions are no longer a spreadsheet.</h1>
              <p className="text-4xl x-sm:text-2xl font-thin mt-8 x-sm:mt-4 leading-snug">Recognizable icons, clear merchant, tip and amount information.</p>
            </div>
          </div>
          <div className="w-2/4 app-config h-full flex items-center x-sm:w-full">
            <img className="block m-auto" src={`${IMAGE_PREFIX()}/transactions-features.png`} alt="Transactions-Features" />
          </div>
        </div>
      </section>
      <section className="py-16 ovr-section-bg x-sm:px-4">
        <div className="container mx-auto flex items-center x-sm:flex-wrap x-sm:flex-col-reverse">
          <div className="w-2/4 app-config h-full flex items-center x-sm:w-full">
            <img className="block m-auto x-sm:max-w-full" src={`${IMAGE_PREFIX()}/rewards-features.png`} alt="Rewards-Features" />
          </div>
          <div className="w-2/4 x-sm:w-4/5 x-sm:text-center">
            <div className="lg:w-2/4 lg:m-auto x-sm:w-full">
              <h1 className="text-5xl leading-tight font-semibold pb-6 x-sm:pb-0 x-sm:text-4xl">Earn unlimited cash rewards.</h1>
              <p className="text-4xl x-sm:text-2xl font-thin mt-8 x-sm:mt-4 leading-snug">Level up as you earn more rewards with every purchase.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 x-sm:px-4">
        <div className="container mx-auto flex items-center x-sm:flex-wrap">
          <div className="w-2/4 x-sm:w-full x-sm:text-center">
            <div className="lg:w-2/4 x-sm:w-4/5 m-auto">
              <h1 className="text-5xl leading-tight font-semibold pb-6 x-sm:pb-0 x-sm:text-4xl">Visualize and understand your habits.</h1>
              <p className="text-4xl x-sm:text-2xl font-thin mt-8 x-sm:mt-4 leading-snug">Compare your monthly spending and see where you can cut or splurge.</p>
            </div>
          </div>
          <div className="w-2/4 app-config h-full flex items-center x-sm:w-full">
            <img className="block m-auto" src={`${IMAGE_PREFIX()}/bubbles-features.png`} alt="Bubbles Features" />
          </div>
        </div>
      </section>
      <section className="py-16 ovr-section-bg">
        <div className="container mx-auto flex items-center x-sm:flex-wrap x-sm:flex-col-reverse">
          <div className="w-2/4 app-config h-full flex items-center x-sm:w-full">
            <img className="block m-auto" src={`${IMAGE_PREFIX()}/id-features.png`} alt="Id Features" />
          </div>
          <div className="w-2/4">
            <div className="lg:w-2/4 lg:m-auto x-sm:text-center">
              <h1 className="text-5xl leading-tight font-semibold pb-6 x-sm:pb-0 x-sm:text-4xl">Let’s get started</h1>
              <p className="text-4xl x-sm:text-2xl font-thin mt-8 x-sm:mt-4 leading-snug">Grab your ID! This helps us verify you quickly and easily.</p>

              <div className="flex items-center mt-8">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.deserve.deservecard" rel="noreferrer">
                  <img className="bg-black rounded border border-black" src={`${IMAGE_PREFIX()}/android-download.svg`} alt="playstore" />
                </a>
                <a className="inline-block ml-8" target="_blank" href="https://apps.apple.com/us/app/deserve-cards/id1489277226" rel="noreferrer">
                  <img className="bg-black border rounded border-black" src={`${IMAGE_PREFIX()}/ios-download.svg`} alt="appstore" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 md:px-4 ovr-section-bg" id="partners">
        <div className="lg:w-4/6 m-auto flex justify-center x-sm:flex-wrap">
          <ul className="list-none flex x-sm:flex-wrap">
            <li className="py-2 x-sm:w-3/6">
              <img className="h-14 pr-6 x-sm:pr-0 inline" src={`${IMAGE_PREFIX()}/nerdwallet_logo_footer.png`} alt="nerdwallet_logo_footer" />
            </li>
            <li className="py-2 x-sm:w-3/6">
              <img className="h-14 pr-6 x-sm:pr-0 inline" src={`${IMAGE_PREFIX()}/money_logo_footer.png`} alt="money_logo_footer" />
            </li>
            <li className="py-2 x-sm:w-3/6">
              <img className="h-14 pr-6 x-sm:pr-0 inline" src={`${IMAGE_PREFIX()}/credit_karma_logo_footer.png`} alt="credit_karma_logo_footer" />
            </li>
            <li className="py-2 x-sm:w-3/6">
              <img className="h-14 pr-6 x-sm:pr-0 inline" src={`${IMAGE_PREFIX()}/fastcompany_logo_footer.png`} alt="fastcompany_logo_footer" />
            </li>
            <li className="py-2 x-sm:w-3/6">
              <img className="h-14 pr-6 x-sm:pr-0 inline" src={`${IMAGE_PREFIX()}/techcrunch_logo_footer.png`} alt="techcrunch_logo_footer" />
            </li>
            <li className="py-2 x-sm:w-3/6">
              <img className="h-14 pr-6 x-sm:pr-0 inline" src={`${IMAGE_PREFIX()}/business_insider_logo_footer.png`} alt="techcrunch_logo_footer" />
            </li>
          </ul>
        </div>
      </section>

      <footer className="ovr-footer-bg py-16 text-gray-400 md:px-4 x-sm:px-4 x-sm:py-8">
        <div className="lg:w-4/6 md:w-full m-auto flex justify-between x-sm:flex-wrap">
          <ul className="list-none mb-8">
            <li className="py-2">
              <a href="https://www.deserve.com/login/?activate=1" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Activate Your Card</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/about-us/press" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Press</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/platform/security/" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Security</a>
            </li>
          </ul>
          <ul className="list-none">
            <li className="py-2">
              <a href="https://www.deserve.com/about-us/about-us" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Company</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/partners/" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Partnerships</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/deserve_card_overview/#" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Scholarship</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/about-us/careers" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Careers</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/about-us/blog" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Blog</a>
            </li>
          </ul>
          <ul className="list-none mb-8">
            <li className="py-2">
              <a href="https://www.deserve.com/terms-and-conditions/" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Terms and Conditions</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/terms-and-conditions/electronic-consent-policy/" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Electronic Consent Policy</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/terms-and-conditions/usa-patriot-act-notice/" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">PATRIOT Act Notice</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/privacy-policy" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Privacy Policy</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/terms-and-conditions/privacy-notice/" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Privacy Notice</a>
            </li>
            <li className="py-2">
              <a href="https://www.deserve.com/terms-and-conditions/terms-of-service/" className="hover:text-blue-500 font-bold lg:text-sm" target="_blank" rel="noreferrer">Terms of Service</a>
            </li>
          </ul>
          <div>
            <img className="pb-3 mt-3 h-10" src={`${IMAGE_PREFIX()}/logo-deserve-white.svg`} alt="logo-deserve-white" />
            <address className="py-2">PO Box 57780 Murray,<br />UT 84157</address>
            <p className="py-1 text-blue-500">
              <a href="tel:+1.800.418.2362">1.800.418.2362</a>
            </p>
            <p className="py-1 text-blue-500">
              <a href="tel:+1-801-981-4334">International: 1-801-981-4334</a>
            </p>
            <p className="py-1 text-blue-500">
              <a href="mailto:hello@deserve.com">hello@deserve.com</a>
            </p>
            <div className="flex mt-8 items-center">
              <a target="_blank" href="https://www.facebook.com/DeserveCards/" rel="noreferrer">
                <img src={`${IMAGE_PREFIX()}/facebook-blue_44c2f8abd6be95a687be369a0642ef60.svg`} alt="facebook" />
              </a>
              <a className="inline-block ml-8" target="_blank" href="https://twitter.com/DeserveCards" rel="noreferrer">
                <img src={`${IMAGE_PREFIX()}/twitter-blue_bbc0494ec0306e7743fe332af52502b6.svg`} alt="twitter" />
              </a>
              <a className="inline-block ml-8" target="_blank" href="https://www.instagram.com/deservecards/" rel="noreferrer">
                <img src={`${IMAGE_PREFIX()}/ig-blue_ccb11047a90a4025418d0cb5e710566f.svg`} alt="instagram" />
              </a>
              <a className="inline-block ml-8" target="_blank" href="https://www.linkedin.com/company/deservecards/" rel="noreferrer">
                <img src={`${IMAGE_PREFIX()}/linked-in-blue_05d800fbef887334c73cf67c611dbc0d.svg`} alt="Linkedin" />
              </a>
              <a className="inline-block ml-8" target="_blank" href="https://wechat.com" rel="noreferrer">
                <img src={`${IMAGE_PREFIX()}/we-chat-blue_29ab6d83456186b77daf6c0c4d8494b4.svg`} alt="we-chat" />
              </a>
            </div>
          </div>
        </div>
        <div className="lg:w-4/6 m-auto flex mt-8">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.deserve.deservecard" rel="noreferrer">
            <img className="bg-black rounded border border-black" src={`${IMAGE_PREFIX()}/android-download.svg`} alt="playstore" />
          </a>
          <a className="inline-block ml-8" target="_blank" href="https://apps.apple.com/us/app/deserve-cards/id1489277226" rel="noreferrer">
            <img className="bg-black border rounded border-black" src={`${IMAGE_PREFIX()}/ios-download.svg`} alt="appstore" />
          </a>
          <a className="inline-block ml-8" target="_blank" href="https://www.deserve.com/login/" rel="noreferrer">
            <img className="bg-black border rounded border-black" src={`${IMAGE_PREFIX()}/web-browser.svg`} alt="webstore" />
          </a>
        </div>
        <div className="lg:w-4/6 m-auto flex mt-8 items-center justify-between">
          <p>© {new Date().getFullYear()} Deserve</p>
        </div>
        <div className="lg:w-4/6 m-auto x-sm:text-sm">
          <p className="py-2">Deserve is a trademark of Deserve, Inc. Restrictions apply, see terms and conditions for more detail. Deserve reserves the right to modify or discontinue products or offerings at any time and without notice. Deserve Digital First Card is issued by Celtic Bank, a Utah-Chartered Industrial Bank, Member FDIC.</p>
          <p className="py-2">All product names, logos, and brands are property of their respective owners. All company, product and service marks used in this website are for identification purposes only. Use of these names, logos, and brands does not imply endorsement.</p>
          <p className="py-2">MONEY® is a registered trademark of Time Inc. and is used under license. From MONEY® Magazine, October 2018. ©2018 Time Inc. Used under license. MONEY® and Time Inc. are not affiliated with, and do not endorse products or services of, Deserve.</p>
          <p className="py-2">©2017-2020 and TM, NerdWallet, Inc. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
}