export const setDefaultThemeVars = () => {
    document.body.style.setProperty("--text-h1", "40px");
    document.body.style.setProperty("--opacity-2", "0.2");

    document.body.style.setProperty("--opacity-4", "0.4");
    document.body.style.setProperty("--text-h1", "40px");
    document.body.style.setProperty("--text-h1-sm", "30px");
    document.body.style.setProperty("--text-h1-lh", "46.6px");
    document.body.style.setProperty("--text-h1-lh-sm", "32px");
    document.body.style.setProperty("--text-size-br1", "10.3051px");
    document.body.style.setProperty("--text-size-t1", "26px");
    document.body.style.setProperty("--text-size-t1-sm", "24px");
    document.body.style.setProperty("--text-small-btn-sm", "16px");
    document.body.style.setProperty("--text-large-btn-sm", "20px");
    document.body.style.setProperty("--text-size-b10", "13px");
    document.body.style.setProperty("--text-size-bn4", "16px");
    document.body.style.setProperty("--theme-error", "#7893FF");
    document.body.style.setProperty("--theme-dashboard-content", "#231841");
    document.body.style.setProperty("--theme-dashboard-t1", "#112949");
    document.body.style.setProperty("--slider-bg", "white");
    document.body.style.setProperty("--slider-off", 'rgb(255, 255, 255, 0.2)');
    document.body.style.setProperty("--theme-cardaction-error", "#FF5D5D");
    document.body.style.setProperty("--text-bold", "none");
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#FFFFFF");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#000000");
    document.body.style.setProperty("--theme-typehead-menu-bg", "rgb(255, 255, 255)");
    document.body.style.setProperty("--sub-text-color", "#000000");
    document.body.style.setProperty("--text2", "13px");

    document.body.style.setProperty("--theme-card-active-background", "#fff");
    document.body.style.setProperty("--theme-card-active-color", "#000000");
    document.body.style.setProperty("--app-banner-bg-color", "#328DFD");
    document.body.style.setProperty("--app-banner-bg-br1-color", "#FF5D5D");
    document.body.style.setProperty("--app-banner-btn-t1-bg-color", "#FFFFFF");
    document.body.style.setProperty("--app-banner-btn-t1-color", "#000000");
    document.body.style.setProperty("--app-banner-title-color", "#FFFFFF");
    document.body.style.setProperty("--app-banner-title-t1-color", "#1E1F26");
    document.body.style.setProperty("--app-banner-title-fw", "400");
    document.body.style.setProperty("--app-banner-sub-title-color", "#FFFFFF");
    document.body.style.setProperty("--btn-outline-border-color", "#FF5D5D");
    document.body.style.setProperty("--theme-text-small-btn_font_family", "sf-ui-display");
    document.body.style.setProperty("--input-text-border_radius", "0px");
};