import React, { useEffect, useState } from "react";
import BtnNext from "../components/BtnNext";
import emitter from '../utils/emitter';
import { track } from "../utils/analytics";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { findInCollection } from "../utils/find-in-collection";
import { StepIdentifier } from "../utils/constants";
import TickInCircleIcon from "../components/TickInCircleIcon";
import {autoPoll} from "../utils/auto_poll";


type Props = {
  breadcrumbs: Object,
  workflow: Object,
  currentStep: Object | any
  setWorkflow: Function
};

function Approval(props: Props) {
  const [submitting, setSubmitting] = useState(false);
  const template = props.workflow["steps"][0]["ui_template_type"]
  const step = props.workflow["steps"][0][template];
  const title = step.default_state.title;
  const nextActionTitle = step.default_state.first_action_title;
  const description = step.description;
  const partnerName = window["appConfig"]["PARTNER_NAME"] || "deserve";
  const containerClasses = `app-container-${partnerName} onb-workflow-page approval-page-${partnerName} theme-approval-page absolute w-full md:w-4/5 bottom-0 left-0 top-12 md:top-0 x-sm:top-44 h-full flex flex-1 items-center`;

  useEffect(() => {
    const intervalId = autoPoll(props.workflow, props.setWorkflow);

    emitter.emit('progress', { breadcrumbs: props.breadcrumbs, identifier: props.currentStep['identifier'] });

    return () => {
      clearInterval(intervalId);
    }
  }, [props.breadcrumbs, props.currentStep, props.setWorkflow, props.workflow])

  track('onb_card_approved');

  function onNextClick() {
    let nextStep = findInCollection(props.workflow["steps"], 'identifier', StepIdentifier.whats_next);

    setSubmitting(true);
    if (nextStep) {
      props.setWorkflow({
        data: {
          "breadcrumbs": props.breadcrumbs,
          "steps": [nextStep]
        }
      });
    }
    return null;
  }

  return (
    <div className={containerClasses}>
      <div className="content-container m-auto">
        <div className="text-center mb-8">
          <TickInCircleIcon />
        </div>
        <p className='text-main text-center z-20 md:mb-2 w-3/5 m-auto'>
          {title} ${step.credit_limit}
        </p>
        <p className='text-white text-center z-20 md:mb-2 mt-10 w-3/5 m-auto'>{description}</p>
        {!submitting && <div className='mx-auto mt-10 mb-10 z-20'>
          <BtnNext text={nextActionTitle}
            onClick={onNextClick}
            className='bg-white block text-center text-xl tracking-tight button-with-custom-text h-16 p-5 cursor-pointer w-72 mx-auto'
          />
        </div>}
        {submitting && <BtnSpinnerNext />}
      </div>
    </div>
  );
}

export default Approval;
