export const ROUTE_BASE_PATH='/';
export const SLM_SIGN_IN_LANDING_PAGE_PATH='/home';
export const DASHBOARD_PATH ='/dfc/dashboard';
export const SERVICING_PATH ='/servicing/dashboard';
export const PROFILE_PATH ='/dfc/profile';
export const EDIT_ADDRESS_PATH ='/dfc/editaddress';
export const CONTACT_US_PATH = '/dfc/contact-us';
export const PAYMENT_METHOD = '/dfc/payments';
export const AUTO_PAY = '/dfc/autopay';
export const ROUTE_CHA=`/dfc/cha`;
export const DESERVE_CARD_OVERVIEW_PATH='/deserve_card_overview';

export const ROUTE_APPLY_CARD_PATH=`/apply-card`;
export const ROUTE_EMAIL_SINGN_IN_PATH=`${ROUTE_APPLY_CARD_PATH}/email-sign-in`;

export const ROUTE_SINGN_IN_PATH=`${ROUTE_APPLY_CARD_PATH}/sign-in`;
export const ROUTE_VERIFY_PHONE_OTP_PATH=`${ROUTE_APPLY_CARD_PATH}/verify-phone-otp`;
export const ROUTE_VERIFY_EMAIL_OTP_PATH=`${ROUTE_APPLY_CARD_PATH}/verify-email-otp`;
export const ROUTE_BASIC_DETAILS_PATH=`${ROUTE_APPLY_CARD_PATH}/basic-details`;

export const ROUTE_NEXT_STEPS_PATH=`${ROUTE_APPLY_CARD_PATH}/next-steps`;
export const ROUTE_GOOGLE_AUTH_CALLBACK_PATH=`${ROUTE_APPLY_CARD_PATH}/auth/google/callback`;
export const ROUTE_EMAIL_SIGNIN_CALLBACK_PATH=`${ROUTE_APPLY_CARD_PATH}/verify/email/otp`;
export const ROUTE_EXISTING_ACCOUNT_PATH=`${ROUTE_APPLY_CARD_PATH}/existing-account`;
export const ROUTE_ERROR_PATH=`${ROUTE_APPLY_CARD_PATH}/error`;
export const ROUTE_404_PATH=`${ROUTE_APPLY_CARD_PATH}/404`;
export const ADD_BANK_CALLBACK_PATH = `${ROUTE_APPLY_CARD_PATH}/add-bank`;
export const APP_DOWNLOAD_QR_CODE_PATH = `${ROUTE_APPLY_CARD_PATH}/qr-code`;


