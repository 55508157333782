import React, { useCallback, useEffect, useState } from "react";
import { usePlaidLink, PlaidLinkOnEvent, PlaidLinkOnEventMetadata } from 'react-plaid-link';
import { IMAGE_PREFIX } from "../constants/urls.constants";
import ClientStorage from "../utils/client-storage";
import emitter from "../utils/emitter";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { workflow } from "../constants/workflow.constants";
import { trackPlaidLinking } from "../utils/helpers";
import { PLAID_EVENTS } from "../constants/plaid-event.constants";

type Props = { currentStep: Object, onClick: Function, breadcrumbs: Object };
function AddBank(props: Props) {

  const step = props.currentStep[props.currentStep['ui_template_type']]
  const [submitting, setSubmitting] = useState(false);
  let linkToken = step.plaid_link_token.link_token;

  useEffect(() => {
    ClientStorage.setLocal("link_token", linkToken)
    ClientStorage.setLocal("redirectUrl", window.location.href)
    emitter.emit('progress', { breadcrumbs: props.breadcrumbs, identifier: props.currentStep['identifier'] });
  }, [props.breadcrumbs, props.currentStep])

  const onSuccess = useCallback((token, metadata) => {
    track(EVENT.onb_bank_connected);
    ClientStorage.setLocal("bank_token", token)
    ClientStorage.setLocal("plaid-metadata", JSON.stringify(metadata))
    ClientStorage.setLocal("redirectUrl", window.location.href)
    props.onClick({});
  }, [props]);

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName: string, metadata: PlaidLinkOnEventMetadata) => {
    if(eventName === PLAID_EVENTS.EXIT) {
      setSubmitting(false);
    }
    trackPlaidLinking(eventName, metadata);
  }, []);

  const config = {
    token: linkToken,
    onSuccess,
    onEvent
  };

  const { open } = usePlaidLink(config);

  function handleClick() {
    track(EVENT.onb_bank_next_clicked)
    setSubmitting(true);
    open();
  }

  return (
    <div className='add-bank-page'>
      <div className='text-main'>
        <p className='text-center text-3xl md:text-4xl pt-9 md:pt-0'>
          {step.success_state.title}
        </p>
      </div>
      <p className='text-center text-lg font-light tracking-tight px-5 theme-text-color'>
        {step.subtitle}
      </p>
      <div className='pt-5 pb-5'>
        <img className='mx-auto' src={`${IMAGE_PREFIX()}/${step.image_name}.png`} alt='Add Bank' />
      </div>
      <div className="flex flex-row mb-5 w-full space-x-4">
        <label className="text-sm theme-text-color">
          {step.legal_text}
        </label>
      </div>

      <div className="text-center">
        {!submitting && <button className="bg-theme rounded-full py-3 px-28 text-white block mx-auto" onClick={handleClick}>{workflow.addBank.btnText}</button>}
        {submitting && <BtnSpinnerNext />}
      </div>
    </div>
  );
};

export default AddBank;

