import React, {useEffect, useRef, useState} from 'react';
import moment from "moment";
import ReactHtmlParser from "html-react-parser";

import BtnNext from "../components/BtnNext";
import emitter from "../utils/emitter";
import {track, trackFailure} from "../utils/analytics";
import BtnSpinnerNext from '../components/BtnSpinnerNext';
import {EVENT} from "../constants/events.constants";
import {workflow} from "../constants/workflow.constants";
import {FirstCharUpperCase} from "../utils/text";
import {DateInputType} from "./DateInputType";
import {CurrencyInputType} from "./CurrencyInputType";
import AutoCompleteInputType from "./AutoCompleteInputType";
import TextInputType from "./TextInputType";
import CheckboxInputType from "./CheckboxInputType";
import {checkMarketingDataExpired, getApplicationToken, parseQueryString, trackUTM} from "../utils/helpers";
import {StepIdentifier} from "../utils/constants";
import {MobileInputType} from "./MobileInputType";
import {unFormatMobileNumber} from "../utils/mobileNumberFormatter";
import {post} from "../utils/client";
import {PHONE_TRIGGER_ENDPOINT} from "../constants/urls.constants";

type Props = { CurrentStep: { ui_template_type: string }; handleSubmit: Function; breadcrumbs: Object; showSpinner: boolean; name: string, event: string; additional_details: object };

function TemplateA(props: Props) {
  const [HasError, setHasError] = useState(false);

  const [showErrors, setShowErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [individualFailure, setIndividualFailure] = useState([true, true]);
  const [dataObj, setDataObj] = useState({});
  const ModelName = props.CurrentStep.ui_template_type;
  const ModelData = props.CurrentStep[ModelName];
  const input_fields = ModelData.input_fields;
  const subtitle = ModelData.subtitle || ModelData['default_state'].subtitle;
  const FormRef = useRef(null);
  const template = props.CurrentStep["ui_template_type"];
  const chapter = props.CurrentStep[template];
  const footerTitleText = ModelData.footer && ModelData.footer.title && ModelData.footer.title.text;
  const description = ModelData.input_fields[1] && ModelData.input_fields[1].value;
  let key = props.name;
  let isConfirmNameAndEmailStep = props.CurrentStep['step_info'].step_identifier === StepIdentifier.confirm_name_and_email;

  const partnerName = window['appConfig'].PARTNER_NAME;

  useEffect(() => {
    setShowButton(false)
    setSubmitting(false)
    let userAgent = navigator.userAgent;

    if (isConfirmNameAndEmailStep) {
      let marketingAllData: any = trackUTM('marketingData') || {"empty": true};
      let trackProperties = marketingAllData ? parseQueryString(marketingAllData.marketing_url) : {"empty": true};

      marketingAllData = checkMarketingDataExpired(marketingAllData);

      if (
        userAgent.match(/Android/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPad/i)
      ){
        track(EVENT.onb_mobile_web);
      }else{
        track(EVENT.onb_desktop_web);
      }

      track(EVENT.onb_application_started, {
        ...trackProperties,
        ...marketingAllData ? {"marketingAllData": marketingAllData} : {}
      });
    }

    emitter.emit('progress', {breadcrumbs: props.breadcrumbs, identifier: props.CurrentStep['identifier']});
  }, [props.breadcrumbs, props.CurrentStep]);

  async function handleSubmit(e) {
    e.preventDefault();
    let input_field = ModelData.input_fields[0];
    let inputEl = FormRef.current && FormRef.current.querySelector(`input[name="${input_field.name}"]`);

    if(key === 'income_type_details'){
      inputEl = FormRef.current && FormRef.current.querySelector(`input[name="${key}"]`);
    };

    let data = {}
    let inErrorState = false

    if (showButton) {
      if (input_field.name === 'dob') {
        const dateEntered = inputEl.value?.split('-') // YYYY-MM-DD
        const mm_dd_yyy = dateEntered.length >= 3 && `${dateEntered[1]}/${dateEntered[2]}/${dateEntered[0]}`; // MM/DD/YYYY

        track("onb_dob_data", {'raw_data': inputEl && inputEl.value})
        track("onb_formatted_dob_data", {'raw_data': mm_dd_yyy})

        data[key] = mm_dd_yyy || moment(new Date(inputEl && inputEl.value)).format("MM/DD/YYYY");
      } else if (input_field.name === 'address_line1') {
        data = {
          'address': {
            'zip_code': dataObj['zip'],
            'city': dataObj['city'],
            'state': dataObj['state'],
            'address_line1': dataObj['address_line1'],
            'address_line2': dataObj['address_line2']
          }
        }
      } else if (['monthly_rent'].includes(input_field.name)) {
        data[key] = parseFloat((inputEl.value).replace('$', '').replace(/,/g, ''));
      } else if (input_field.name === 'income' ) {
        data[input_field.name] = parseFloat((inputEl.value).replace('$', '').replace(/,/g, ''));
      } else if (input_field.name === 'mobile_number') {
        const applicationToken = getApplicationToken();

        let payload = {
          phone: `+1${unFormatMobileNumber(inputEl.value)}`,
          idToken: applicationToken
        };
        try {
          setSubmitting(true);
          await post(PHONE_TRIGGER_ENDPOINT, payload)
          data['mobile_number'] = inputEl && unFormatMobileNumber(inputEl.value)
        }catch (e) {
          setSubmitting(false);
          setShowErrors(true)
          setShowButton(false)
          inErrorState = true
          trackFailure(EVENT.onb_phone_submit_failed, e);
          setHasError(true);
        }
      } else {
        data = dataObj
      }

      track(EVENT[`${props.event}_next_clicked`]);
      if (!inErrorState) {
        setSubmitting(true);
        props.handleSubmit(data).then(res => track(EVENT[`${props.event}_submitted`]))
          .catch(handleSpinner);
      } else {
        setSubmitting(false);
      }

    } else {
      handleSpinner();
      setHasError(true);
    }
  }


  function handleSpinner(resp?: any) {
    setSubmitting(false);
  }

  function renderTitle() {
    if (key === 'income') {
      return workflow[key].title + " " + (props.additional_details['income_type'] === "YEARLY_INCOME" ? "annual income?" : " monthly income?");
    }

    return (workflow[key] && workflow[key].title) || chapter["default_state"].title;
  }


  function renderLabel(input_field) {
    if (key === 'income' && input_field.name === 'income') {
      return props.additional_details['income_type'] === "YEARLY_INCOME" ? "annual income" : "monthly income"
    }


    return input_field.title;
  }

  function renderInput(input_type, input_field, index) {
    switch (input_type) {
      case 'autocomplete':
        return (<AutoCompleteInputType input_field={input_field} label={renderLabel(input_field)}
                                       key={input_field.name}
                                       setShowButton={setShowButton} setAddress={setDataObj}/>);
      case 'date':
        return (<DateInputType input_field={input_field} label={renderLabel(input_field)} key={input_field.name}
                               setShowButton={setShowButton}/>)
      case 'amount':
        return (<CurrencyInputType input_field={input_field} label={renderLabel(input_field)} name={props.name}
                                   setShowButton={setShowButton} defaultValue="$"/>)
      case 'checkbox':
        return (<CheckboxInputType input_field={input_field} label={renderLabel(input_field)} key={input_field.name}
                                   setShowButton={setShowButton} defaultValue={input_field.value} index={index}
                                   setIndividualFailure={setIndividualFailure} individualFailure={individualFailure}
                                   setAddress={setDataObj} address={dataObj}
        />)
      case 'mobile_number':
        return (<MobileInputType input_field={input_field} label={renderLabel(input_field)} name={input_field.name}
                                 setShowButton={setShowButton} defaultValue=""/>)

      case 'text':
        return (<div/>)

      default:
        if (dataObj && dataObj['address_line1']) {
          return (
            <TextInputType 
              input_field={input_field} 
              label={renderLabel(input_field)} 
              key={input_field.name}
              setShowButton={setShowButton} 
              defaultValue={dataObj[input_field.name]} 
              index={index}
              setIndividualFailure={setIndividualFailure}
              individualFailure={individualFailure} 
              setAddress={setDataObj} 
              address={dataObj} 
              placeholder={input_field?.placeholder}
          />);
        } else {
          let should_hide = input_field['should_hide'] === undefined ? false : input_field['should_hide']
          return (
            !should_hide &&
            <TextInputType 
              input_field={input_field} 
              label={renderLabel(input_field)} 
              key={input_field.name}
              setShowButton={setShowButton} 
              defaultValue={input_field.value} 
              index={index}
              setIndividualFailure={setIndividualFailure} 
              individualFailure={individualFailure}
              setAddress={setDataObj} 
              address={dataObj}
              placeholder={input_field?.placeholder}
            />)
        }
    }
  }

  function renderInputFields() {
    const fieldsByRow: object = input_fields.reduce((catsSoFar, x) => {
      if (!catsSoFar[x.display_row]) catsSoFar[x.display_row] = [];
      catsSoFar[x.display_row].push(x);
      return catsSoFar;
    }, {});

    if (Object.keys(fieldsByRow).length > 1) {
      return Object.values(fieldsByRow).map((input_field, index) => {
        if (input_field.length > 1) {
          return (<div key={index} className="flex flex-row x-sm:flex-col md:space-x-4">
            {input_field.map((x, index) => renderInput(x.input_type, x, index))}
          </div>)
        } else {
          return renderInput(input_field[0].input_type, input_field[0], input_field[0].display_row)
        }
      })
    } else {
      return input_fields.map((input_field, index) => {
        return renderInput(input_field.input_type, input_field, index)
      })
    }
  }

  return (
    <div className={`app-container-${partnerName} onb-workflow-page`}>
      <p className="text-main mb-4">{FirstCharUpperCase(renderTitle())}</p>
      {subtitle && <p className="mb-11 text-sm sub-text" data-testid='subtitle'>{subtitle}</p>}
      <div className="content-container name-email-container md:mt-10">
        <form onSubmit={handleSubmit} ref={FormRef} noValidate>
          <div className="pb-4 relative">
            {renderInputFields()}
            {showErrors && <p className="error-block absolute my-2 text-xs">{workflow.phone.errorMessage}</p>}
          </div>
          {/* {!isConfirmNameAndEmailStep && description &&
          <div data-testid="footerTitle" className='mb-10 txt-type-para text-sm'>{ReactHtmlParser(description)}</div>} */}
          {!submitting && <div className="text-center">
            <BtnNext data-testid="next-btn" className={!showButton ? 'opacity-25 inline-block' : ''}
                     onClick={handleSubmit}/>
          </div>}
          {submitting && <BtnSpinnerNext/>}
          {!isConfirmNameAndEmailStep && description &&
          <div data-testid="footerTitle" className='my-10 txt-type-para text-sm'>{ReactHtmlParser(description)}</div>}
          {isConfirmNameAndEmailStep && !description && footerTitleText && <p className="my-10 txt-type-para text-sm">{footerTitleText}</p>}
        </form>
      </div>
    </div>
  )
}

export default TemplateA;
