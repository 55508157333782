import React from "react";
import Spinner from "./Spinner";

function BtnSpinnerNext(props) {
  //TODO load assets based on partner name.

  return (
    <div className="bg-theme rounded-full py-3 px-28 loading-wp" data-testid="next-btn-spinner">
      <Spinner />
    </div>
  );
}

export default BtnSpinnerNext;
