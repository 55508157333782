export const workflow = {
  email: {
    emailErrorMessage: "Email is not valid."
  },

  first_name: "Please enter valid first name.",
  last_name: "Please enter valid last name.",

  otp: {
    resendCodeMessage: "Didn’t get the code?",
    codeExpiration: "The code will expire in",
    sendTheOtpTo: "We'll send the code to",
    dataRatesMayApply: "Message and data rates may apply.",
    enterValidCode: "Enter a valid 6 digit code",
    resendCode: "Resend Code",
  },

  phone: {
    errorMessage: "Must be a valid 10 digit number",
  },

  address: {
    city: "City",
    state: "State",
    zip: "Zip"
  },

  ssn: {
    ssnErrorMessage: "Please enter a valid 9 digit SSN."
  },
  
  ssn_last_four: "Please enter last 4 digit of ssn.",

  birthdate: {
    minAge: 'Minimum Age requirement not fulfilled',
    inValidDate: 'Please Enter a valid date of birth'
  },

  monthly_rent: {
    errorMessage: "Enter amount higher than $1",
  },

  income: {
    title: "What's your total",
    errorMessage: "Enter amount higher than $1",
  },

  annualIncome: {
    title: "What's your total",
    errorMessage: "Enter amount higher than $1",
  },

  housingExpense: {
    title: "and how much do you pay per month?",
    subtitle: "monthly housing expense"
  },

  notFound: {
    title: "Uh oh, there’s nothing here. Make sure you typed the right address.",
    buttonText: "Home"
  },

  addBank: {
    btnText: "Securely link my account"
  },
  verify_phone_otp: {
    next_screen: {
      identifier: "capture_phone_otp",
      capture_phone_otp: {
        timer: 180,
        validation: {
          phone_otp: {
            min_length: 6,
            max_length: 6,
            regex: "^[0-9]\\d*$"
          }
        }
      }
    }
  }
}
