import React from 'react';
import { APPLICATION_BASE_URL, IMAGE_PREFIX } from "../constants/urls.constants";
import {track} from "../utils/analytics";
import {EVENT} from "../constants/events.constants";

function ErrorAction(props: any) {
  let workflow = props.workflow || {}
  const template = workflow && workflow.steps[0]["ui_template_type"] || '';
  const chapter =  workflow && workflow.steps[0][template] || {};
  const currentState = chapter && chapter["current_state"];
  const errorMessage = chapter && chapter[currentState+"_state"]["title"];
  const errorAction = chapter && chapter[currentState+"_state"]["first_action_title"];
  const image = chapter["image"];
  const imgPath = `${IMAGE_PREFIX()}/${image.name}.${image.type}`;

  const partnerName = window['appConfig'].PARTNER_NAME;

  function handleClick(e: any) {
    e.preventDefault();
    window.location.href = APPLICATION_BASE_URL;
  }

  return (
    <div className={`app-container-${partnerName} onb-workflow-page`}>
      <div className="text-center" data-testid="title">
        {track(EVENT.onb_application_creation_unavailable)}
        {errorMessage ? (
          <p className="text-main">{errorMessage}</p>
        ) : (
          <p className="text-main">
            Something went wrong. <br /> Try reloading the page.
          </p>
        )}
        <img
          data-testid="image"
          src={imgPath}
          alt="error occurred"
          className="w-2/3 mx-auto my-4"
        />

        {errorAction && (
          <button
            data-testid="button-title"
            className="bg-theme rounded-full py-3 px-28 text-white focus:outline-none"
            onClick={handleClick}
          >
            <label>{errorAction}</label>
          </button>
        )}
      </div>
    </div>
  );
}

export default ErrorAction;
