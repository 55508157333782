import React from "react";

export function ButtonDone(props) {
    return (
        <button id="doneBtn" type="button"  {...props} className="button-done outline-none brand-btn-solid">
            <span>
                Done
            </span>
        </button>
    )
}