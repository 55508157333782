import { DESERVE_CARD_OVERVIEW_PATH, ROUTE_SINGN_IN_PATH } from "../constants/route-paths.constants";

export const getStaticErrorActions = (code: string, data: any = {}) => {
  const appConfig = window['appConfig'];
  let otp_error = {
    title: data.title,
    subtitle: data.subtitle,
    next_screen: {
      identifier: "send_otp_error"
    },
    actions: [
      {
        name: 'try_again',
        title: 'Try Again',
        redirectTo: appConfig.PARTNER_NAME === 'deserve' ? DESERVE_CARD_OVERVIEW_PATH : '/',
        light: true
      }
    ]
  };

  let details = {
    invalid_user: {
      title: "We couldn't find an account with this phone number",
      subtitle: `The entered phone number is ${data?.phone}`,
      next_screen: {
        identifier: "invalid_user"
      },
      actions: [
        {
          name: 'exit',
          title: 'Exit',
          redirectTo: appConfig.PARTNER_NAME === 'deserve' ? DESERVE_CARD_OVERVIEW_PATH : '/',
          light: true
        },
        {
          name: 'try_another_number',
          title: 'Try Another Number',
          redirectTo: `${ROUTE_SINGN_IN_PATH}?flow=signin`
        }
      ]
    },
    existing_user_email: {
      title: `${data?.error?.display_message}`,
      subtitle: `${data?.error?.display_description}`,
      next_screen: {
        identifier: "existing_user_email"
      },
      actions: [
        {
          name: 'exit',
          title: 'Exit',
          redirectTo: appConfig.PARTNER_NAME === 'deserve' ? DESERVE_CARD_OVERVIEW_PATH : '/',
          light: true
        },
        {
          name: 'try_another_emaiil',
          title: 'Try Another Email',
          redirectTo: `${ROUTE_SINGN_IN_PATH}`
        }
      ]
    },
    send_otp_error: otp_error,
    expired_otp: otp_error,
    invalid_otp: otp_error,
    web_login_not_allowed: otp_error,
    verify_in_same_browser_error: {
      title: "Looks like you’re trying to confirm your email in another browser. Please enter the OTP code there or start again here.",
      next_screen: {
        identifier: "verify_in_same_browser_error"
      },
      actions: [
        {
          name: "start_here",
          title: "Start Here",
          redirectTo: appConfig.PARTNER_NAME === 'deserve' ? DESERVE_CARD_OVERVIEW_PATH : '/',
          light: true
        }
      ]
    },
    internal_server_error: {
      title: "Oops! something went wrong, please try again",
      subtitle: `The entered phone number is ${data?.phone}`,
      next_screen: {
        identifier: "internal_server_error"
      },
      actions: [
        {
          name: 'exit',
          title: 'Exit',
          redirectTo: appConfig.PARTNER_NAME === 'deserve' ? DESERVE_CARD_OVERVIEW_PATH : '/',
          light: true
        },
        {
          name: 'try_again',
          title: 'Try Again',
          redirectTo: data?.redirectTo
        }
      ]
    },
  };

  return details[code];
}