import React, { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";

import BtnNext from "../components/BtnNext";
import emitter from "../utils/emitter";
import { track } from "../utils/analytics";
import AppModal from "../components/AppModal";
import DownloadCircleIcon from "../components/DownloadCircleIcon";
import { autoPoll } from "../utils/auto_poll";

type Props = {
  workflow: Object;
  setWorkflow: Function;
  breadcrumbs: Object | any;
  currentStep: Object | any;
};

function WhatsNext(props: Props) {
  const [showModal, setShowModal] = useState(false);

  const template = props.workflow["steps"][0]["ui_template_type"];
  const step = props.workflow["steps"][0][template];
  const title = step.default_state.title;
  const downloadUrl = window["appConfig"]["CLIENT_APP_DOWNLOAD_URL"];
  const nextActionTitle = step.default_state.first_action_title;
  const description = step.description;
  const {
    default_state: { title: popupTitle },
  } = step.popup.desktop_mode;

  const partnerName = window["appConfig"]["PARTNER_NAME"] || "deserve";
  const containerClasses = `approval-page-${partnerName} theme-approval-page absolute w-full md:w-4/5 bottom-0 left-0 top-12 md:top-0 x-sm:top-44 h-full flex flex-1 items-center`;

  const QRCodeImageURL = window["appConfig"]["CLIENT_APP_DOWNLOAD_URL"];

  useEffect(() => {
    const intervalId = autoPoll(props.workflow, props.setWorkflow);

    emitter.emit("progress", {
      breadcrumbs: props.breadcrumbs,
      identifier: props.currentStep["identifier"],
    });

    return () => {
      clearInterval(intervalId);
    };
  }, [props.breadcrumbs, props.currentStep, props.setWorkflow, props.workflow]);

  track("onb_whats_next");

  function onNextClick() {
    if (showModal) {
      track("onb_app_download_button_clicked", {download_url: downloadUrl});
    }
    let userAgent = navigator.userAgent;

    if (
      userAgent.match(/Android/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPad/i)
    ) {
      track("onb_app_download_mobile_web_button_clicked", {download_url: downloadUrl});
      window.location.href = downloadUrl;
    } else {
      setShowModal(!showModal);
    }
    return null;
  }

  return (
    <div className={containerClasses}>
      <div className="content-container m-auto">
        <div className="text-center mb-8">
          <DownloadCircleIcon />
        </div>
        <p className="text-main text-center z-20 md:mb-2 w-3/5 m-auto">
          {title}
        </p>
        <p className="text-white text-center z-20 md:mb-2 mt-10 w-3/5 m-auto">
          {description}
        </p>
        <div className="mx-auto mt-10 mb-10 z-20">
          <BtnNext
            text={nextActionTitle}
            onClick={onNextClick}
            className={`bg-white block text-center text-xl tracking-tight button-with-custom-text h-16 p-5 cursor-pointer ${partnerName === 'amc' ? 'w-80' : 'w-72'} mx-auto`}
          />
        </div>
      </div>
      <AppModal open={showModal} onClose={onNextClick}>
        <div className="px-20 py-4">
          <p className="my-4 text-gray-900 text-2xl text-center w-full m-auto">
            {popupTitle}
          </p>
          <div className="flex justify-center m-auto p-4 mb-8">
            { track("onb_app_qrcode_displayed", {download_url: QRCodeImageURL}) }
            <QRCodeSVG size={200} value={QRCodeImageURL} />
          </div>
        </div>
      </AppModal>
    </div>
  );
}

export default WhatsNext;
