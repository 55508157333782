const InstitutionsSkeleton = () => {
  const arr = new Array(4).fill(0);
  return (
    <>
      {arr.map(() => {
        return (
          // Rahul: Consumer Finicity - CSS updates applied old tailwind classes along with current ones
          <div className="flex p-4 animate-pulse rounded-lg items-center gap-4 mb-2 mr-2">
            <div className="w-96 h-10 cursor-pointer rounded-full bg-slate-700 opacity-30"></div>
            <div className="w-10 h-8 rounded-lg bg-slate-700 flex-1 opacity-30"></div>
          </div>
        );
      })}
    </>
  );
};

export default InstitutionsSkeleton;
