import React from "react";

type Props = {
  onClick?: Function;
  fillColor?: string;
};

const EyeCrossIcon = (props: Props) => {
  const { fillColor = "#534B4B" } = props;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 14"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.908 10.678a.445.445 0 0 1-.15-.03 7.68 7.68 0 0 1-1.224-.594.427.427 0 0 1-.144-.582.427.427 0 0 1 .582-.144c.348.21.714.384 1.086.528.222.084.33.33.246.546a.433.433 0 0 1-.396.276Z"
        fill={fillColor}
      />
      <path
        d="M7.752 11.176c-1.068 0-2.07-.18-2.994-.528a.421.421 0 0 1-.246-.546.426.426 0 0 1 .546-.246 7.613 7.613 0 0 0 2.694.474 7.952 7.952 0 0 0 6.708-3.498c-.396-.492-1.494-1.728-3.144-2.58a.428.428 0 0 1-.186-.57.428.428 0 0 1 .57-.186c2.298 1.182 3.564 2.988 3.618 3.066.09.132.102.306.024.444-.096.168-2.352 4.17-7.596 4.17h.006ZM3.75 10.114a.406.406 0 0 1-.216-.06C1.404 8.77.57 6.856.534 6.778a.432.432 0 0 1 .024-.384c.096-.156 2.388-3.876 7.152-3.876 1.104 0 1.836.12 2.712.444.222.084.33.324.252.546a.427.427 0 0 1-.546.252c-.78-.288-1.41-.39-2.412-.39-3.69 0-5.784 2.556-6.3 3.282a7.23 7.23 0 0 0 2.556 2.682c.198.12.264.384.144.582a.425.425 0 0 1-.366.204v-.006Z"
        fill={fillColor}
      />
      <path
        d="M.924 13.072a.413.413 0 0 1-.3-.127.431.431 0 0 1 0-.6L12.546.621a.431.431 0 0 1 .6 0 .431.431 0 0 1 0 .6L1.218 12.952a.417.417 0 0 1-.3.12h.006ZM7.608 9.274c-.42 0-.84-.102-1.212-.3a.424.424 0 0 1-.174-.576.424.424 0 0 1 .576-.174c.27.144.576.216.888.198a1.725 1.725 0 0 0 1.482-2.466.423.423 0 1 1 .762-.366 2.579 2.579 0 0 1-2.214 3.678h-.108v.006Z"
        fill={fillColor}
      />
      <path
        d="M5.622 7.773a.428.428 0 0 1-.414-.33 2.611 2.611 0 0 1 .06-1.374 2.551 2.551 0 0 1 1.278-1.5 2.55 2.55 0 0 1 1.968-.156.427.427 0 0 1 .276.534.427.427 0 0 1-.534.276 1.715 1.715 0 0 0-1.32.108 1.74 1.74 0 0 0-.9 1.926.425.425 0 0 1-.414.522v-.006Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default EyeCrossIcon;
