import React from 'react';
import { APPLICATION_BASE_URL, IMAGE_PREFIX } from "../constants/urls.constants";
import { workflow } from '../constants/workflow.constants';

function NotFound() {

  function onBtnClick(e: any) {
    e.preventDefault();
    window.location.href = APPLICATION_BASE_URL;
  }

  return (
    <div>
      <p className="text-main mb-10 pt-12" data-testid='title'>
        {workflow.notFound.title}
      </p>
      <img className="w-3/6 mx-auto my-4" src={`${IMAGE_PREFIX()}/404.png`} alt="not found" />
      <button data-testid='button-title' onClick={onBtnClick} className="bg-theme rounded-full py-3 px-28 text-white block mx-auto focus:outline-none">
        {workflow.notFound.buttonText}
      </button>
    </div>
  );
}

export default NotFound;
