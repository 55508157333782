import parse from 'html-react-parser';

const renderContentList = (content: string, items: Array<string>) => {
  return (
    <>
      {content && <p>{parse(content)}</p>}
      <ul className="list-disc py-2 pl-4">
        {items.map((item: string, idx: number) => {
          return (
            <li className="py-1" key={idx}>
              {parse(item)}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export const IGINITE_L_QNS = () => {
  return [
    {
      title: "What happened to my Sallie Mae Credit Card?",
      content: `Your old Sallie Mae Credit Card has been deactivated. Please activate your new ignite card by calling us at <a href="tel:+18882953447">1-888-295-3447</a>, 24 hours a day, 7 days per week.`,
    },
    {
      title: "What if I have not received my new ignite card?",
      content: `Please call us at <a href="tel:+18882953447">1-888-295-3447</a>, 24 hours a day, 7 days per week to discuss mailing you a new card.`
    },
    {
      title: "How do I log in to my ignite account?",
      content: `You can access your mobile and web account at www.theignitecard.com using the phone number on file and follow the instructions to receive a one-time passcode to access your account.`,
    },
    {
      title: "How do I change or update my account information?",
      content: `Please call us at <a href="tel:+18882953447">1-888-295-3447</a> 24 hours a day, 7 days a week or email us at <a href="mailto:support@theignitecard.com">support@theignitecard.com</a> if you need to update your cell phone number, email address, or home address. Please note P.O. Boxes are not accepted.`
    },
    {
      title: "How can I view my previous statements?",
      content: `In the ignite mobile app click on the profile button in the top right corner, scroll down to click on ‘Statements’ and then click on the month you wish to view.  At theignitecard.com click on the clipboard icon in the top right corner above ‘Recent Transactions’ and then click on the download button next to the month you wish to view. You have access to up to 18 months of historical statements online and in the mobile app.`,
    },
    {
      title: "How do I make a payment?",
      content: `Once you add a bank account in the ignite mobile app or at theignitecard.com using the ‘Add Bank’ button, you can click on the ‘SETUP AUTO PAY” link on the homepage or make a one-time payment by scheduling a payment for a future date or pay now by choosing the date and amount of your payment.`
    }
  ];
};

export const IGINITE_R_QNS = () => {
  return [
    {
      title: "What kind of alerts are available for my ignite card?",
      content: renderContentList(
        'Under your profile in the ignite mobile app you can create notifications for:',
        [
          'Late Payment Fees',
          'Refunds',
          'Any transaction over an amount you have selected',
          'Your statement due date',
          'Payment receipts',
        ]
      ),
    },
    {
      title: "What card controls are available in the ignite mobile app and at theignitecard.com?",
      content: `You can freeze your card to block it from being used or mark it available for international use in other countries.`
    },
    {
      title: "How can I create/change my Personal Identification Number (PIN)?",
      content: ` As a reminder, your current PIN number has not changed. If you’d like to change your current PIN you can do so in the ignite mobile app by clicking on the profile button and then My PIN.`
    },
    {
      title: "Where can I view important disclosures about my account?",
      content: `You can view your Cardholder Agreement & Pricing Disclosures, as well as your Rewards Agreement under your profile at theignitecard.com. You can also view additional notices governing your account under the ‘Legal’ link under ‘Profile’ in the ignite mobile app.`
    },
    {
      title: "Can my ignite card be used for contactless transactions?",
      content: `Yes, your ignite card will now have contactless payment capabilities at participating merchants.`
    }
  ];
};
