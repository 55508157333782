import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import { post } from "../utils/client";

import Loading from "../components/Loading";

import {
  EXISTING_ACCOUNT_PAGE_URL,
  VERIFY_EMAIL_OTP_API_URL
} from '../constants/urls.constants';
import * as queryString from "query-string";
import { checkMarketingDataExpired, isNewSignInFlowEnabled, isNewSignInFlowEnabledForDFC, parseQueryString, trackUTM } from "../utils/helpers";
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { ROUTE_SINGN_IN_PATH } from "../constants/route-paths.constants";

interface EmailSignInAuthProps { }

const EmailSignInAuth: React.FC<EmailSignInAuthProps> = (props) => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    trackUTM("marketingData", window.location.href); // Store marketing url

    if (isNewSignInFlowEnabledForDFC() || isNewSignInFlowEnabled()) {
      history.push(`${ROUTE_SINGN_IN_PATH}/${search}`);
      return null;
    } else {
      const url = VERIFY_EMAIL_OTP_API_URL + search;
      let params = queryString.parse(search);
      let marketingAllData: any = trackUTM('marketingData') || { "empty": true };
      let trackProperties = marketingAllData ? parseQueryString(marketingAllData.marketing_url) : { "empty": true };

      let data = {
        email: params['?email'] || params['email'],
        session_id: uuidv4(),
        otp: params.otp || params['?otp']
      }

      marketingAllData = checkMarketingDataExpired(marketingAllData);

      track(EVENT.onb_marketing_url_data_on_confirm_email_link, {
        ...trackProperties,
        ...marketingAllData ? { "marketingAllData": marketingAllData } : {}
      });

      post(url, data)
        .then((resp) => {
          if (resp && resp.success) {
            history.push("/apply-card");
          } else {
            history.push("/apply-card/error");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            window.location.href = EXISTING_ACCOUNT_PAGE_URL;
          } else {
            history.push(`/apply-card/error`);
          }
        });
    }
  }, [history, search]);

  return (
    <div className="content-container">
      <Loading />
    </div>
  );
}

export default EmailSignInAuth;
