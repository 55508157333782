
import { IMAGE_BASE_URL } from "../../constants/urls.constants";

export interface ITenantConfiguration {
  imagePrefix: string;
  partnerName: string;
}


/**
 * Dev note: This is Tenant configuration class
 * This class will be used to get the image prefix for the tenant
 * This class will be used to get the partner name for the tenant
 * This class will be used to get the product alias for the tenant
 * Ideally all the tenant specific configurations should be here
 * Also every tenant should have a configuration class like this but due to time limitation we are using this class for all tenants
 *
 */
export class TenantConfiguration implements ITenantConfiguration {
  productAlias: string = "default";
  partnerName: string = "";

  constructor(productAlias: string, partnerName: string) {
    this.productAlias = productAlias;
    this.partnerName = partnerName;
  }

  get imagePrefix(): string {
    /**
     * Dev Note: Instead of having tenant check here. We should have
     * seperate class for all tenants.
     */
    if (this.partnerName === "ccbank") {

      return `${IMAGE_BASE_URL}/${this.partnerName}/${this.productAlias}`;
    }

    return `${IMAGE_BASE_URL}/${this.partnerName}`;
  }
}
