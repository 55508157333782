export let formatMobileNumber = (phoneNumber) => {
  let cleaned = ('' + phoneNumber).replace(/\D/g, '');

  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + ' ' + match[3];
  } else {
    return phoneNumber;
  }
}

export let unFormatMobileNumber = (phoneNumber) => {
  if (phoneNumber) {
    return phoneNumber.replace('(', '').replace(')', '').replace(/ /g, '');
  } else {
    return phoneNumber;
  }
}

