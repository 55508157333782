import React from "react";


type ServicingStatusScreenContainerProps = {
  children: React.ReactNode
};

export const ServicingStatusScreenContainer = ({ children }: ServicingStatusScreenContainerProps) => {
  return (
    <div className="flex md:-ml-12 lg:-ml-12 md:-mr-12 lg:-mr-12 md:-mt-10 lg:-mt-10 x-sm:-mt-3 x-sm:-ml-7 x-sm:-mr-7 min-h-screen">
      <div className="flex w-full">
        <div className="flex x-sm:flex-col md:flex-col lg:flex-row lg:items-center lg:vertical-center w-full md:pl-12 lg:pl-12 x-sm:min-h-screen x-sm:items-center x-sm:justify-center x-sm:p-4">
          {children}
        </div>
      </div>
    </div>
  )
};