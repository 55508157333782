export const setDeserveThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#BC9966");
    document.body.style.setProperty("--primary-font", "gt-alpina");
    document.body.style.setProperty("--secondary-font", "sf-ui-text");
    document.body.style.setProperty("--theme-input_label_font_family", "sf-ui-text");
    document.body.style.setProperty("--theme-input_font_family", "sf-ui-text");
    document.body.style.setProperty("--theme-color", "#BC9966");
    document.body.style.setProperty("--progress-line-over-color", "#BC9966");
    document.body.style.setProperty("--text-color", "#1A1717");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#1A1717");
    document.body.style.setProperty("--text-t1-size", "20px");
    document.body.style.setProperty("--text2-color", "#534B4B");
    document.body.style.setProperty("--text2", "13px");
    document.body.style.setProperty("--theme-background", "linear-gradient(243.01deg, #F3E0C9 100%, #FCEEDF 100%)");
    document.body.style.setProperty("--theme-background-dark", "#FF512E");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-color-rgb", "188, 153, 102");
    document.body.style.setProperty("--theme-error", "#7893FF");
    document.body.style.setProperty("--theme-approval-text", "#FDF0E0");
    document.body.style.setProperty("--theme-checkbox-background", "#FF512E");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#FF512E");
    document.body.style.setProperty("--theme-card-background", "#ff745470");
    document.body.style.setProperty("--theme-cardaction-error", "#ff512e");
    document.body.style.setProperty("--theme-card-text", "#FFFFFF");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(188, 153, 102, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    // New sign in button style vars
    document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#000000");
    document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_border_color", "transparent");
    document.body.style.setProperty("--theme-signin_btn_bg_color", "#ffffff");
    document.body.style.setProperty("--theme-signin_btn_text_color", "#000000");
    document.body.style.setProperty("--theme-signin_btn_devider_color", "#ffffff");
    document.body.style.setProperty("--theme-devider_opacity", "0.5");
    document.body.style.setProperty("--theme-signin_diff_option_color", "#ffffff");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "sf-ui-text");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "350");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#FFF");

    document.body.style.setProperty("--text-b5-font_family", "sf-ui-text");
    document.body.style.setProperty("--text-b5-font_size", "16px");
    document.body.style.setProperty("--text-b5-font_weight", "350");
    document.body.style.setProperty("--text-b5-line_height", "20px");
    document.body.style.setProperty("--text-b5-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b5-color", "#FFF");
};