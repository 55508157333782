import React, { useState, useEffect } from "react";
import { partnerName } from "../constants/urls.constants";

type Props = {
  open: boolean;
  onClose?: Function;
  children?: Array<Object | any> | Object | any;
};

function AppModal(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const deserveCSS = 'w-3/6 lg:w-4/6 xl:w-3/5';
  useEffect(() => {
    setShowModal(props.open);
  }, [props.open]);

  function closeModal(e: any) {
    setShowModal(false);
    if (props && props.onClose) {
      props.onClose(e);
    }
  }

  return (
    <>
      {showModal && (
        <div className={`w-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}>
          <div className={`relative my-6 mx-auto modal-container-${partnerName}`}>
            <div className={`border-0 rounded-xl shadow-lg relative flex flex-col w-full modal-content-${partnerName} outline-none focus:outline-none`}>
              <div className="flex items-start justify-between pt-4 px-4 rounded-t-full">
                <button
                  className={`p-1 close-modal-${partnerName} ml-auto w-8 h-8 bg-transparent rounded-full flex justify-center items-center align-middle border-0 float-right leading-none outline-none focus:outline-none`}
                  onClick={closeModal}
                >
                  ×
                </button>
              </div>
              <div className="relative w-full flex-auto">{props.children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AppModal;
