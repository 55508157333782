import React from "react";
import { get } from "../utils/client"

import Loading from "../components/Loading";
import OTP from "./OTP";
import EmailSignIn from "./EmailSignIn";
import ErrorOccurred from "./ErrorOccurred";

import { StepIdentifier } from "../utils/constants";
import {
  EMAIL_SIGIN_IN_WORKFLOW_ENDPOINT,
} from "../constants/urls.constants";
import emitter from "../utils/emitter";

type EmailSignInWorkflowProps = {};
type EmailSignInWorkflowState = {
  workflow: Object,
  redirectToLogin: boolean,
  identifierIndex: number,
  payload: object
};

class EmailSignInWorkflow extends React.Component<EmailSignInWorkflowProps, EmailSignInWorkflowState> {
  constructor(props) {
    super(props);
    this.state = {
      workflow: null,
      redirectToLogin: false,
      identifierIndex: 0,
      payload: {}
    };
  }

  componentDidMount() {
    get(EMAIL_SIGIN_IN_WORKFLOW_ENDPOINT)
      .then(response => {
        const breadcrumbs = response['breadcrumbs'];
        const identifier = StepIdentifier.confirm_name_and_email;

        emitter.emit('progress', { breadcrumbs,  identifier });

        this.setState({
          workflow: response
        });
      });
  };

  handleSubmit = (obj: object) => {
    this.setState((state) => {
      return {
        ...state,
        payload: obj
      }
    });
    this.nextStepHandler();
  }

  nextStepHandler = () => {
    let currentStep = this.state.workflow['steps'][this.state.identifierIndex];

    if (!currentStep.step_info.is_last_page) {
      this.setState({
        identifierIndex: this.state.identifierIndex + 1
      });
    }
  }

  render() {
    if (this.state.workflow && this.state.workflow['steps']) {
      let currentStep = this.state.workflow['steps'][this.state.identifierIndex];

      switch (currentStep.identifier) {
        case StepIdentifier.email:
          return <EmailSignIn currentStep={currentStep} onClick={this.handleSubmit} />
        case StepIdentifier.email_verify:
          return <OTP breadcrumbs={this.state.workflow['breadcrumbs']} currentStep={currentStep} onClick={this.handleSubmit} user={this.state.payload} />
        default:
          return <ErrorOccurred />
      }
    } else {
      return (<Loading />);
    }
  }
}

export default EmailSignInWorkflow;
