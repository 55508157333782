import decode from "jwt-decode";
import ClientStorage from "../../utils/client-storage";
import { CC_BANK_PRODUCTS, PRODUCT_ALIAS_KEY } from "../../constants/product-constants";

/**
 * Dev Note:
 * Ideally, We should incorporate the product alias in TenantConfiguration class itself
 * which will hold all information about the tenant
 */
export const currentProductAlias = () => {
  const inToken = productAliasFromAuthTokenIfAvailable();

  if(inToken) {
    return inToken;
  }

  const inStore = getProductIdAlias();

  if (!inStore) {
    setProductIdAlias("default");
    return "default";
  }

  return inStore;
};

/**
 * DevNote:
 * Ideally, We should incorporate the product alias in TenantConfiguration class itself
 * which will hold all information about the tenant
 */
export const currentThemeId = () => {
  const partnerName = window["appConfig"].PARTNER_NAME;
  if(partnerName === 'ccbank') {
    return currentProductAlias() !== 'default' ? currentProductAlias() : 'ccbank_peak';
  }

  if(partnerName === 'deserve') {
    return 'default';
  }

  return partnerName;
};

export const processQueryParamForProductAlias = () => {
  const params = new URLSearchParams(window.location.search);
  const receivedParam = params.get(PRODUCT_ALIAS_KEY);

  if (CC_BANK_PRODUCTS.includes(receivedParam)) {
    setProductIdAlias(receivedParam);
  } else {
    setProductIdAlias("default");
  }
};

const productAliasFromAuthTokenIfAvailable = () => {
  const authToken = ClientStorage.getLocal("application_token");
  if(!authToken) return null;

  const product_id_alias = (decode(authToken) as any).product_id_alias;
  return product_id_alias;
}

const setProductIdAlias = (productAlias) => {
  ClientStorage.setLocal(PRODUCT_ALIAS_KEY, productAlias);
}

const getProductIdAlias = () => {
  return ClientStorage.getLocal(PRODUCT_ALIAS_KEY);
}