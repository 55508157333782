import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index-output.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import loadTheme from "./app_theme";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { v4 as uuidv4 } from "uuid";
import ClientStorage from "./utils/client-storage";
import { DeviceDetailsContext } from "./utils/device-details-context";
import parser from "ua-parser-js";
import { TenantConfigurationProvider } from "./core/multiProduct/TenantConfigurationProvider";
import { TenantConfiguration } from "./core/multiProduct/TenantConfiguration";
import {
  currentProductAlias,
  processQueryParamForProductAlias,
} from "./core/multiProduct/product-alias";
import { clearApplicationRefreshToken, clearApplicationToken, getCachedPhoneNumber } from "./utils/helpers";

Sentry.init({
  dsn: window["appConfig"].SENTRY_DSN,
  initialScope: {
    tags: { user_id: localStorage.getItem("user_id") },
  },
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ["error", "warn"],
    }),
  ],
  sampleRate: 1.0,
  tracesSampleRate: 1.0,
  environment: window["appConfig"].ENVIRONMENT,
});

const { browser, os } = parser();

const setLoginIdentifier = () => {
  const key = "CUSTOMER_AUTH_LOGIN_IDENTIFIER";
  const identifier = ClientStorage.getLocal(key);
  if (!identifier) {
    ClientStorage.setLocal(key, uuidv4());
  }
};

setLoginIdentifier();

const Root = () => {
  const [currentTenantConfiguration, setCurrentTenantConfiguration] =
    useState(null);

  useEffect(() => {
    if (window.location.pathname === "/") {
      //If Root url, We have to clear all tokens if any


      const phoneNumber = getCachedPhoneNumber();
      if(!phoneNumber) {
        /**
          * Dev Note: This is a temporary solution to clear tokens on root url
          *  // TODO (2): Rahul - WWT-5134 - Needs discussion on, should we persist the token on root url and redirect to dashboard if token available?
          *
        */
        clearApplicationToken();
        clearApplicationRefreshToken();

        //Root url with new user
        processQueryParamForProductAlias();
      }
    }

    const currentProduct = currentProductAlias();
    loadTheme(window["appConfig"].PARTNER_NAME);

    const tenantConfiguration = new TenantConfiguration(
      currentProduct,
      window["appConfig"].PARTNER_NAME
    );
    setCurrentTenantConfiguration(tenantConfiguration);
  }, []);

  return (
    <React.StrictMode>
      <TenantConfigurationProvider
        currentTenantConfig={currentTenantConfiguration}
      >
        <DeviceDetailsContext.Provider
          value={{
            browser: browser.name,
            os: os.name,
          }}
        >
          <App partnerName={window["appConfig"].PARTNER_NAME} />
        </DeviceDetailsContext.Provider>
      </TenantConfigurationProvider>
    </React.StrictMode>
  );
};
ReactDOM.render(<Root></Root>, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
