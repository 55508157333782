import React, {useState} from "react";
import {numberFormatter} from "../utils/numberFormatter";
import {FirstCharUpperCase} from "../utils/text";

type Props = { input_field: Object; label: string; name: string; setShowButton: Function, defaultValue: string };

export function CurrencyInputType(props: Props) {
  const [HasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [value, setValue] = useState(props.defaultValue);


  function unFormattedIncome(newValue) {
    return parseFloat(newValue.replace('$', '').replace(/,/g, ''));
  }

  function validate(newValue) {
    const income = unFormattedIncome(newValue)

    let inputField = props.input_field;
    const isValidEntry = new RegExp(inputField['validation_regex']).test(income.toString());
    const isInRange = income > 1 && income.toString().length >= inputField['min_length'] && income.toString().length <= inputField['max_length'];

    return income && isValidEntry && isInRange;
  }

  function handleChange(e, setShowButton) {
    let newValue = e.target.value;

    if (validate(newValue)) {
      setHasError(false);
      setShowButton(true)
      formatIncome(newValue);
    } else {
      setValue(newValue ? newValue : '$')
      setShowButton(false)
      setHasError(true);
    }
  }

  function formatIncome(newValue) {
    let income = unFormattedIncome(newValue);
    setValue((income ? numberFormatter(income) : '$').toString());
  }


  return (
    <div>
      <label className="">
        <div className="uppercase mb-2 tracking-wide field-caption">
          {FirstCharUpperCase(props.label)}
        </div>
        <input
          type="text"
          name={props.name}
          pattern={props.input_field['validation_regex']}
          onChange={e => handleChange(e, props.setShowButton)}
          autoFocus={true}
          value={value}
          onBlur={e => formatIncome(e.target.value)}
          className="bg-transparent w-full h-12 text-3xl border-b-2 text-field"
          required
        />
      </label>
      {HasError ? (
        <div>
          <p className="error-block text-xs absolute my-2">{errorMessage}</p>
        </div>
      ) : null}
    </div>
  )
}
