import React, { useState } from "react";
import InfoIcon from "./InfoIcon";

const AppTooltip = (props: any) => {
  const [active, setActive] = useState(false);

  const showTip = () => setActive(true);
  const hideTip = () => setActive(false);

  return (
    <div
      className={`app-tooltip_wrapper relative`}>
      <div className="flex items-center">
        {props.children}
        <span onMouseEnter={showTip} onMouseLeave={hideTip}><InfoIcon /></span>
      </div>
      {active && (
        <div className="app-tooltip_tip x-sm:top-auto x-sm:bottom-full x-sm:-left-8">
          {props.text}
        </div>
      )}
    </div>
  );
};

export default AppTooltip;
