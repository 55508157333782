import React from "react";

type Props = {
  onClick?: Function,
  fillColor?: string,
}

const ProfileIcon = (props: Props) => {
  const { fillColor = "#534B4B" } = props;
  return (
    <svg cursor="pointer" width="20" height="20" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9541 0.90802C7.65752 0.90802 0.908203 7.65734 0.908203 15.9533C0.908203 24.2505 7.65752 31.0004 15.9541 31.0004C24.2507 31.0004 31 24.2505 31 15.9533C31.0006 7.65734 24.2507 0.90802 15.9541 0.90802ZM15.8956 7.27992C18.5197 7.27992 20.6476 9.40717 20.6476 12.0313C20.6476 14.6566 18.5197 16.7839 15.8956 16.7839C13.2709 16.7839 11.1436 14.656 11.1436 12.0313C11.1442 9.40717 13.2715 7.27992 15.8956 7.27992ZM24.3701 26.5114C22.06 28.3585 19.1348 29.4649 15.9541 29.4649C12.7777 29.4649 9.85558 28.3622 7.54547 26.52C7.76096 22.7125 10.2675 18.8656 13.3343 18.8656H18.577C21.6512 18.8656 24.1645 22.5727 24.3695 26.3864V26.3876C24.3695 26.3876 24.3701 26.4572 24.3701 26.5114Z" fill={fillColor} />
    </svg>
  );
}

export default ProfileIcon;