export const setDeserveEduThemeVars = () => {
    document.body.style.setProperty("--brand-br1-color", "#477BE2");
    document.body.style.setProperty("--primary-font", "suisseintl");
    document.body.style.setProperty("--secondary-font", "avenir-book");
    document.body.style.setProperty("--theme-input_label_font_family", "suisseintl");
    document.body.style.setProperty("--theme-input_font_family", "suisseintl");
    document.body.style.setProperty("--theme-color-rgb", "71, 123, 226");
    document.body.style.setProperty("--theme-color", "#477BE2");
    document.body.style.setProperty("--text-h1-sm", "26px");
    document.body.style.setProperty("--progress-line-over-color", "#87A945");
    document.body.style.setProperty("--text-color", "#57595E");
    document.body.style.setProperty("--text-color_typehead-menu-item", "#57595E");
    document.body.style.setProperty("--text2-color", "#FFFFFF");
    document.body.style.setProperty("--theme-error", "#57595E");
    document.body.style.setProperty("--letter-spacing", "-0.3px");
    document.body.style.setProperty("--theme-background", "#F0F1F4");
    document.body.style.setProperty("--theme-background-dark", "#0CB9AE");
    document.body.style.setProperty("--theme-br2", "#19C5BA");
    document.body.style.setProperty("--theme-background-image", "none");
    document.body.style.setProperty("--theme-approval-text", "#FFFFFF");
    document.body.style.setProperty("--theme-checkbox-background", "#477BE2");
    document.body.style.setProperty("--theme-radio-checkbox-background", "#477BE2");
    document.body.style.setProperty("--theme-card-background", "#FFFFFF");
    document.body.style.setProperty("--theme-card-text", "#000000");
    document.body.style.setProperty("--slider-bg", "#E5E5E5");
    document.body.style.setProperty("--slider-off", '#E5E5E5');
    document.body.style.setProperty("--text-bold", "600");
    document.body.style.setProperty("--theme-payment-success-block-background", "#fff");
    document.body.style.setProperty("--theme-subtitle-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-cardaction-error", "#011936");
    document.body.style.setProperty("--theme-popup-text-bg-color", "rgba(0, 157, 255, 0.16)");
    document.body.style.setProperty("--theme-popup-icon-bg-color", "#FAF7F7");
    document.body.style.setProperty("--theme-autopay_btn_bg_color", "#477BE2");
    document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    document.body.style.setProperty("--text-t1-color", "#2D282E");

    document.body.style.setProperty("--text-t2-font", "suisseintl-light");

    // New sign in button style vars
    // document.body.style.setProperty("--theme-signin_apply_btn_bg_color", "#87A945");
    // document.body.style.setProperty("--theme-autopay_btn_bg_color", "#87A945");
    // document.body.style.setProperty("--theme-autopay_btn_text_color", "#FFFFFF");
    // document.body.style.setProperty("--theme-signin_apply_btn_text_color", "#ffffff");
    // document.body.style.setProperty("--theme-signin_btn_bg_color", "#87A945");
    // document.body.style.setProperty("--theme-signin_btn_border_color", "#87A945");
    // document.body.style.setProperty("--theme-signin_btn_text_color", "#FFFFFF");
    // document.body.style.setProperty("--theme-signin_btn_devider_color", "#1A1717");
    // document.body.style.setProperty("--theme-devider_opacity", "0.5");
    // document.body.style.setProperty("--theme-signin_diff_option_color", "#1A1717");

    // New theme variables text_1_heading
    document.body.style.setProperty("--theme-text_1_h_color", "#221F20");
    document.body.style.setProperty("--theme-text_1_h_font_size", "38px");
    document.body.style.setProperty("--theme-text_1_h_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_weight", "400");
    document.body.style.setProperty("--theme-text_1_h_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_font_family", "suisseintl");
    document.body.style.setProperty("--theme-text_1_h_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_font_feature_settings", "'clig' off, 'liga' off'");

    document.body.style.setProperty("--xsm-theme-text_1_h_font_size", "28px");

    // New theme variables text_1_heading_2
    document.body.style.setProperty("--theme-text_1_h_2_color", "#57595E");
    document.body.style.setProperty("--theme-text_1_h_2_font_size", "18px");
    document.body.style.setProperty("--theme-text_1_h_2_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_2_font_weight", "350");
    document.body.style.setProperty("--theme-text_1_h_2_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_2_font_family", "suisseintl");
    document.body.style.setProperty("--theme-text_1_h_2_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_2_font_feature_settings", "'clig' off, 'liga' off'");
    document.body.style.setProperty("--xsm-theme-text_1_h_2_font_size", "16px");


    // New theme variables text_1_heading_3
    document.body.style.setProperty("--theme-text_1_h_3_color", "#534B4B");
    document.body.style.setProperty("--theme-text_1_h_3_font_size", "12px");
    document.body.style.setProperty("--theme-text_1_h_3_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_3_font_weight", "400");
    document.body.style.setProperty("--theme-text_1_h_3_leading", "normal");
    document.body.style.setProperty("--theme-text_1_h_3_font_family", "suisseintl");
    document.body.style.setProperty("--theme-text_1_h_3_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_1_h_3_font_feature_settings", "'clig' off, 'liga' off'");


    // New theme variables text_2_heading
    document.body.style.setProperty("--theme-text_2_h_color", "#707272");
    document.body.style.setProperty("--theme-text_2_h_font_size", "16px");
    document.body.style.setProperty("--theme-text_2_h_font_style", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_weight", "350");
    document.body.style.setProperty("--theme-text_2_h_leading", "normal");
    document.body.style.setProperty("--theme-text_2_h_font_family", "suisseintl");
    document.body.style.setProperty("--theme-text_2_h_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-text_2_h_font_feature_settings", "'clig' off, 'liga' off'");

    document.body.style.setProperty("--text-t2-size", "16px");

    // New theme variables brand_btn_void
    document.body.style.setProperty("--theme-brand_btn_void_color", "rgba(71, 123, 226, 1)");
    document.body.style.setProperty("--theme-brand_btn_void_font_size", "16px");
    document.body.style.setProperty("--theme-brand_btn_void_font_style", "normal");
    document.body.style.setProperty("--theme-brand_btn_void_font_weight", "500");
    document.body.style.setProperty("--theme-brand_btn_void_leading", "normal");
    document.body.style.setProperty("--theme-brand_btn_void_font_family", "suisseintl");
    document.body.style.setProperty("--theme-brand_btn_void_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-brand_btn_void_text_center", "center");
    document.body.style.setProperty("--theme-brand_btn_void_font_feature_settings", "'clig' off, 'liga' off'");

    // New theme variables brand_btn_solid
    document.body.style.setProperty("--theme-brand_btn_solid_color", "#FFF");
    document.body.style.setProperty("--theme-brand_btn_solid_bg_color", "#477BE2");
    document.body.style.setProperty("--theme-brand_btn_solid_font_size", "16px");
    document.body.style.setProperty("--theme-brand_btn_solid_font_style", "normal");
    document.body.style.setProperty("--theme-brand_btn_solid_font_weight", "500");
    document.body.style.setProperty("--theme-brand_btn_solid_leading", "normal");
    document.body.style.setProperty("--theme-brand_btn_solid_font_family", "suisseintl");
    document.body.style.setProperty("--theme-brand_btn_solid_letter_spacing", "-0.3px");
    document.body.style.setProperty("--theme-brand_btn_solid_text_center", "center");
    document.body.style.setProperty("--theme-brand_btn_solid_font_feature_settings", "'clig' off, 'liga' off'");
    document.body.style.setProperty("--theme-brand_btn_solid_disabled_bg_color", "#477BE2");
    document.body.style.setProperty("--theme-brand_btn_solid_disabled_color", "#ffffff8c");

    document.body.style.setProperty("--text-t2-color", "#525353");

    // New theme variables body-b1
    document.body.style.setProperty("--text-b1-font_family", "suisseintl-light");
    document.body.style.setProperty("--text-b1-font_weight", "300");
    document.body.style.setProperty("--text-b1-font_size", "26px");
    document.body.style.setProperty("--text-b1-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b1-line_height", "normal");

    // New theme variables body-b3
    document.body.style.setProperty("--text-b3-font_family", "suisseintl-light");
    document.body.style.setProperty("--text-b3-font_size", "18px");
    document.body.style.setProperty("--text-b3-font_weight", "300");
    document.body.style.setProperty("--text-b3-letter_spacing", "-0.3px");

    // New theme variables body-b4
    document.body.style.setProperty("--text-b4-font_family", "suisseintl");
    document.body.style.setProperty("--text-b4-font_size", "18px");
    document.body.style.setProperty("--text-b4-font_weight", "500");
    document.body.style.setProperty("--text-b4-letter_spacing", "-0.3px");

    // New theme variables body-b5
    document.body.style.setProperty("--text-b5-font_family", "suisseintl-light");
    document.body.style.setProperty("--text-b5-font_size", "16px");
    document.body.style.setProperty("--text-b5-font_weight", "300");
    document.body.style.setProperty("--text-b5-line_height", "20px");
    document.body.style.setProperty("--text-b5-letter_spacing", "-0.3px");

    // New theme variables body-b7
    document.body.style.setProperty("--text-b7-font_family", "suisseintl-light");
    document.body.style.setProperty("--text-b7-font_size", "13px");
    document.body.style.setProperty("--text-b7-font_style", "normal");
    document.body.style.setProperty("--text-b7-font_weight", "300");
    document.body.style.setProperty("--text-b7-line_height", "normal");
    document.body.style.setProperty("--text-b7-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b7-color", "#57595E");

    // New theme variables body-b10
    document.body.style.setProperty("--text-b10-font_family", "suisseintl");
    document.body.style.setProperty("--text-b10-font_size", "13px");
    document.body.style.setProperty("--text-b10-font_weight", "350");
    document.body.style.setProperty("--text-b10-letter_spacing", "-0.3px");

    // New theme variables body-b11
    document.body.style.setProperty("--text-b11-font_family", "suisseintl");
    document.body.style.setProperty("--text-b11-font_size", "10px");
    document.body.style.setProperty("--text-b11-font_weight", "350");
    document.body.style.setProperty("--text-b11-letter_spacing", "-0.3px");
    document.body.style.setProperty("--text-b11-line_height", "normal");
    document.body.style.setProperty("--text-b11-color", "#221F20");

    // New theme variables captions-c1
    document.body.style.setProperty("--text-c1-font_family", "suisseintl");
    document.body.style.setProperty("--text-c1-font_size", "10px");
    document.body.style.setProperty("--text-c1-font_weight", "350");
    document.body.style.setProperty("--text-c1-letter_spacing", "1px");

    // New theme variables captions-c2-standard
    document.body.style.setProperty("--text-c2-std-font_family", "sf-ui-text");
    document.body.style.setProperty("--text-c2-std-font_size", "10px");
    document.body.style.setProperty("--text-c2-std-font_weight", "400");
    document.body.style.setProperty("--text-c2-std-line_height", "11.93px");
    document.body.style.setProperty("--text-c2-std-letter_spacing", "1px");

    // New theme variables captions-c6
    document.body.style.setProperty("--text-c6-font_family", "suisseintl");
    document.body.style.setProperty("--text-c6-font_size", "10px");
    document.body.style.setProperty("--text-c6-font_weight", "500");

    // New theme variables headings-h1
    document.body.style.setProperty("--text-h1-font_family", "din-pro");
    document.body.style.setProperty("--text-h1-font_size", "38px");
    document.body.style.setProperty("--text-h1-font_weight", "400");
    document.body.style.setProperty("--text-h1-line_height", "40px");
    document.body.style.setProperty("--text-h1-letter_spacing", "-0.3px");

    // New theme variables headings-h2
    document.body.style.setProperty("--text-h2-font_family", "din-pro");
    document.body.style.setProperty("--text-h2-font_size", "30px");
    document.body.style.setProperty("--text-h2-font_weight", "400");
    document.body.style.setProperty("--text-h2-line_height", "32px");

    // New theme variables headings-h4
    document.body.style.setProperty("--text-h4-font_family", "din-pro");
    document.body.style.setProperty("--text-h4-font_size", "20px");
    document.body.style.setProperty("--text-h4-font_weight", "400");
    document.body.style.setProperty("--text-h4-letter_spacing", "-0.3px");


    // New theme variables headings-h5
    document.body.style.setProperty("--text-h5-font_family", "din-pro");
    document.body.style.setProperty("--text-h5-font_size", "18px");
    document.body.style.setProperty("--text-h5-font_weight", "400");

    // New theme variables numbers-n1
    document.body.style.setProperty("--text-n1-font_family", "suisseintl-medium");
    document.body.style.setProperty("--text-n1-font_size", "70px");
    document.body.style.setProperty("--text-n1-font_weight", "500");
    document.body.style.setProperty("--text-n1-letter_spacing", "-0.3px");

    // New theme variables numbers-n3
    document.body.style.setProperty("--text-n3-font_family", "din-pro");
    document.body.style.setProperty("--text-n3-font_size", "50px");
    document.body.style.setProperty("--text-n3-font_weight", "400");
    document.body.style.setProperty("--text-n3-letter_spacing", "-0.3px");

    // New theme variables numbers-n6
    document.body.style.setProperty("--text-n6-font_family", "din-pro");
    document.body.style.setProperty("--text-n6-font_size", "16px");
    document.body.style.setProperty("--text-n6-font_weight", "400");
    document.body.style.setProperty("--text-n6-letter_spacing", "-0.3px");

    // New theme variables button-bn1
    document.body.style.setProperty("--text-bn1-font_family", "suisseintl");
    document.body.style.setProperty("--text-bn1-font_size", "20px");
    document.body.style.setProperty("--text-bn1-font_weight", "500");
    document.body.style.setProperty("--text-bn1-letter_spacing", "-0.4px");

    // New theme variables button-bn3
    document.body.style.setProperty("--text-bn3-font_family", "suisseintl");
    document.body.style.setProperty("--text-bn3-font_size", "16px");
    document.body.style.setProperty("--text-bn3-font_weight", "500");

    // New theme variables headline-h1
    document.body.style.setProperty("--text-headline-h1-font_family", "suisseintl");
    document.body.style.setProperty("--text-headline-h1-font_weight", "500");
    document.body.style.setProperty("--text-headline-h1-font_size", "38px");
    document.body.style.setProperty("--text-headline-h1-font_style", "normal");
    document.body.style.setProperty("--text-headline-h1-line_height", "42px");
    document.body.style.setProperty("--text-t4-color", "#fff");
};