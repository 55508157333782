export const profileView = {
    personal:{
        title: "DETAILS",
        name: "Name",
        email: "Email",
        phone: "Phone",
        address: "Address"
    },

    creditDetails: {
        title: "CREDIT DETAILS",
        creditLimit: "Credit Limit",
        availableCredit: "Available Credit",
        apr: "APR"
    },

    other: {
        title: "OTHER",
        contact: 'Contact'
    },

    yourCard: "Your Card"
}

export const DEFAULT_AGREEMENTS_TO_VIEW_IDS = ['cardholder_agreement', 'rewards_agreement'];