/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";
import BtnNext from "../components/BtnNext";
import { useForm } from "react-hook-form-lts";
import { post } from "../utils/client";
import { formatMobileNumber } from "../utils/mobileNumberFormatter";
import { track } from "../utils/analytics";
import { OtpTimers } from "../utils/constants";
import { workflow } from '../constants/workflow.constants';
import { getStaticErrorActions } from '../utils/phone-signin-dynamic-actions';

import {
  EXISTING_ACCOUNT_PAGE_URL,
  PHONE_OTP_VERIFY_ENDPOINT_V3,
  PHONE_TRIGGER_ENDPOINT_V3,
} from "../constants/urls.constants";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { logErrorToSentry, getNewSignInCommonEventProps, getUSPhoneNumber, isPhoneNumberCached, redirectTo, replaceAt, setSessionId, validatePhoneNumber } from "../utils/helpers";
import { EVENT } from "../constants/events.constants";
import { AxiosError } from "axios";
import ClientStorage from "../utils/client-storage";
import { ResendCodeButton } from "../components/ResendCodeButton";

declare global {
  interface window { }
}

type Props = {
  query: string;
  mobile_number: string;
  stepData: any;
  workflowSubmitCb: Function;
};

function SignInVerifyPhoneOtp(props: Props) {
  const appConfig = window['appConfig'];
  const OTP_time: number = props?.stepData?.next_screen?.capture_phone_otp?.timer;
  const location: any = useLocation();
  const [hasError, setHasError] = useState(false);
  const [isSignInFlowOtpSent, setIsSignInFlowOtpSent] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [IsInvalidOTP, setIsInvalidOTP] = useState(false);
  const [OTPExpired, setOTPExpired] = useState(false);
  const [HasFailed, setHasFailed] = useState(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState(true);
  const isInitialMount = useRef(true);
  const [counter, setCounter] = useState(OTP_time);
  const resendOtpLinkRef = useRef(null);
  const query: any = parse(location.search.replace("?", ""));
  const isSigninFlow = query.flow ? true : false;
  const cachedPhoneNumber = ClientStorage.getLocal("phone_number");
  const rawPhoneNumber: string = props?.stepData?.phone || cachedPhoneNumber;
  const phone: string = getUSPhoneNumber(rawPhoneNumber)
  const plainPhoneNumber: string = phone.includes("+1") ? phone?.replace("+1", "") : phone;

  const {
    register,
    handleSubmit,
    reset,
    formState: {
      isValid
    }
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const { capture_phone_otp: { validation } } = props.stepData.next_screen;

  const invalid_otp_str = `Something went wrong. Please try again.`;
  const otp_expired_str = `Your code expired. Please try again.`;

  const resendPhoneOTP = useCallback(() => {
    return new Promise((resolve, reject) => {
      let body = {
        tenant_id: window['appConfig']['PROGRAM_ID'],
        phone: phone
      };

      track(EVENT.dfc_auth_signin_phone_resend_clicked);

      post(PHONE_TRIGGER_ENDPOINT_V3, body)
        .then((data) => {
          track(EVENT.dfc_auth_phone_verify_otp_succeed, {
            ...getNewSignInCommonEventProps(),
          });
          setSessionId(data);
          props.workflowSubmitCb({
            ...props?.stepData,
            ...data,
            phone: props?.stepData?.phone || cachedPhoneNumber
          });
          setCounter(OTP_time);
          resolve(data);
        })
        .catch((err: AxiosError) => {
          logErrorToSentry('signin_resend_phone_otp_failed', body, err);

          track(EVENT.dfc_auth_phone_verify_otp_failed, {
            ...getNewSignInCommonEventProps(),
            ...err?.response?.data
          });
          reject(err);
        });
    });
  }, [cachedPhoneNumber, phone, props]);

  const resendCodeCB = useCallback((e?: any) => {
    const resendOtpLink = resendOtpLinkRef.current;

    e?.preventDefault();

    if (resendOtpLink) {
      const disableResendOtpClassList = ["pointer-events-none", "opacity-40"];

      resendOtpLink.classList.add(...disableResendOtpClassList);
      setTimeout(() => {
        resendOtpLink.classList.remove(...disableResendOtpClassList);
      }, OtpTimers.resend_otp_timer * 1000);
    }

    setSubmitting(true);
    resendPhoneOTP()
      .then(() => {
        setShowSubmitBtn(true);
        setHasFailed(false);
        setOTPExpired(false);
        setIsInvalidOTP(false);
        reset();
      })
      .catch(() => {
        setHasFailed(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [resendPhoneOTP, reset])

  useEffect(() => {
    if (!validation) { // Track props if validation property is not present in props
      track(
        EVENT.dfc_auth_signin_phone_otp_invalid_props,
        {
          props: {
            ...props.stepData,
            phone: phone
          }
        }
      );
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (query.flow) {
        track(
          EVENT.dfc_auth_signin_phone_otp_screen,
          getNewSignInCommonEventProps(query)
        );
      } else {
        track(
          EVENT.dfc_auth_signup_phone_otp_screen,
          getNewSignInCommonEventProps(query)
        );
      }
    }
  }, [query]);

  useEffect(() => {
    let intervalId: any;
    if (counter === 0) {
      track(
        EVENT.dfc_auth_phone_otp_expired,
        getNewSignInCommonEventProps(query)
      );
      setOTPExpired(true);
      setShowSubmitBtn(false);
      clearInterval(intervalId);
      return;
    }

    intervalId = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [counter, query]);

  useEffect(() => {
    let allowDirectOtpSent = isSigninFlow && validatePhoneNumber(ClientStorage.getLocal('phone_number')) ? 'signin' : null;

    if (allowDirectOtpSent && !isSignInFlowOtpSent) {
      setIsSignInFlowOtpSent(true);
      resendCodeCB();
    }
  }, [isSignInFlowOtpSent, isSigninFlow, resendCodeCB]);

  useEffect(() => {
    if (isValid) {
      setHasError(false);
    }
  }, [isValid]);

  function handleFormSubmit({ otp_code }) {
    if (query.flow) {
      track(EVENT.dfc_auth_signin_phone_otp_next_clicked, getNewSignInCommonEventProps());
    } else {
      track(EVENT.dfc_auth_signup_phone_otp_next_clicked, getNewSignInCommonEventProps());
    }
    if (isValid) {
      ClientStorage.setLocal("phone_otp_code", otp_code);
      setSubmitting(true);
      verifyPhoneOTP(otp_code);
    } else {
      setHasError(true);
    }
  }

  function verifyPhoneOTP(otp_code: string) {
    let body = {
      tenant_id: window['appConfig']['PROGRAM_ID'],
      phone: props?.stepData?.phone || cachedPhoneNumber,
      otp: otp_code,
      is_signin_flow: query?.flow ? true : false,
      is_phone_number_cached: isPhoneNumberCached(cachedPhoneNumber, query?.flow, phone),
      ...cachedPhoneNumber ? { refresh_token: ClientStorage.getLocal('application_refresh_token') } : {}
    };
    post(PHONE_OTP_VERIFY_ENDPOINT_V3, body)
      .then((data) => {
        track(
          EVENT.dfc_auth_phone_verify_otp_succeed, {
          ...getNewSignInCommonEventProps(query),
        }
        );
        setSessionId(data);
        props.workflowSubmitCb({
          ...props?.stepData,
          ...data,
          phone: props?.stepData?.phone || cachedPhoneNumber
        });
        return data;
      })
      .then((res) => {
        ClientStorage.setLocal("non_cached_phone_number", getUSPhoneNumber(props?.stepData?.phone));
        onOTPSubmitSuccess(res);
      })
      .catch((err: AxiosError) => {
        const errorData = err?.response?.data || {};

        logErrorToSentry('signin_verify_phone_otp_failed', body, err);

        onOTPSubmitFail(err);
        track(
          EVENT.dfc_auth_phone_verify_otp_failed, {
          ...getNewSignInCommonEventProps(query),
          ...errorData,
        });

        switch (errorData?.code) {
          case "invalid_user":
            props.workflowSubmitCb({
              ...props?.stepData,
              ...errorData,
              ...getStaticErrorActions(errorData?.code, {
                phone: formatMobileNumber(plainPhoneNumber),
                redirectTo: `${window.location.pathname}${window.location.search}`
              })
            });
            break;
          case "send_otp_error":
            props.workflowSubmitCb({
              ...props?.stepData,
              ...errorData,
              ...getStaticErrorActions(errorData?.code, {
                title: errorData?.error?.display_message || errorData?.message,
                subtitle: errorData?.error?.display_description,
                phone: formatMobileNumber(plainPhoneNumber),
                redirectTo: `${window.location.pathname}${window.location.search}`
              })
            });
            break;
          default:
            props.workflowSubmitCb({
              ...props?.stepData,
              ...errorData,
              ...getStaticErrorActions(errorData?.code, {
                title: errorData?.error?.display_message || errorData?.message,
                subtitle: errorData?.error?.display_description,
                phone: formatMobileNumber(plainPhoneNumber),
                redirectTo: `${window.location.pathname}${window.location.search}`
              })
            });
            break;
        }
      });
  }


  function onOTPSubmitSuccess(res: any) {
    setSubmitting(false);
    if (res?.data?.code) {
      if (res.data.code === "invalid_otp") {
        setIsInvalidOTP(true);
      }
      if (res.data.code === "expired_otp") {
        setOTPExpired(true);
      }
    }
  }

  function onOTPSubmitFail(err: any) {
    setSubmitting(false);
    if (err?.response && err?.response?.status === 409) {
      let url = EXISTING_ACCOUNT_PAGE_URL;
      redirectTo(url);
    } else {
      if (err?.response?.data?.code === "invalid_otp") {
        setIsInvalidOTP(true);
      } else if (err?.response?.data?.code === "expired_otp") {
        setOTPExpired(true);
      } else {
        setHasFailed(true);
      }
    }
  }

  let seconds = "0";
  const Minutes = Math.floor(counter / 60);
  let secNum = counter - Minutes * 60;
  seconds = secNum < 10 ? "0" + secNum.toString() : secNum.toString();

  function renderExpireView() {
    if (counter !== 0 && !OTPExpired && !IsInvalidOTP) {
      return (
        <div className="txt-type-para font-b10 py-2 theme-text-color text-2-heading text-t2-color text-b7" data-testid="otp-info-text">
          <p data-testid="resend-text">{workflow.otp.resendCodeMessage}</p>
          <p data-testid="resend-timer">{workflow.otp.codeExpiration} {Minutes}:{seconds}</p>
        </div>
      );
    }

    return (
      <p className="txt-type-para font-b10 py-2 theme-text-color text-2-heading text-t2-color text-b7">
        <span data-testid="otp-info-text-l1">{workflow.otp.sendTheOtpTo}</span>{" "}
        <span data-testid="otp-info-text-l2">
          {isSigninFlow ? replaceAt(plainPhoneNumber, -1, "XXX XXX- ") : formatMobileNumber(plainPhoneNumber)}.
        </span>
        <br />
        <span data-testid="otp-info-text-l3">{workflow.otp.dataRatesMayApply}</span>
      </p>
    );
  }

  return (
    <div id="verify_phone_otp" className={`app-container-${appConfig.PARTNER_NAME} signin-flow-page`}>
      {(IsInvalidOTP && !OTPExpired) && <p className="text-main mb-10 text-1-heading text-headline-h1" data-testid="invalid-otp">{invalid_otp_str}</p>}
      {OTPExpired && <p className="text-main mb-10 text-1-heading text-headline-h1" data-testid="otp-expired">{otp_expired_str}</p>}
      {HasFailed && <p className="text-main mb-10 text-1-heading" data-testid="otp-failed">Failed</p>}
      {!IsInvalidOTP && !OTPExpired && !HasFailed && <p className="text-main mb-10 text-1-heading text-headline-h1" data-testid="text-main">Check your messages</p>}
      {(!IsInvalidOTP && !OTPExpired) && <p className="txt-type-para no-underline text-1-heading-2 text-t2-color text-b3" data-testid="text-subtitile">
        We sent a code to <span>{isSigninFlow ? replaceAt(plainPhoneNumber, -1, "XXX XXX- ") : formatMobileNumber(plainPhoneNumber)}</span>
      </p>}
      <div className="content-container">
        <form onSubmit={handleSubmit(handleFormSubmit)} data-testid="phone-otp-form">
          <div className="pb-4 relative">
            <label className="">
              <div
                className="mb-2 mt-4 tracking-wide field-caption text-c2"
                data-testid="field-caption">ENTER CODE</div>
              <input
                type="text"
                className="bg-transparent w-full h-12 text-3xl border-b-2 outline-none text-field text-b1"
                data-testid="otp_code"
                maxLength={validation?.phone_otp?.max_length}
                minLength={validation?.phone_otp?.min_length}
                placeholder="Enter Code"
                {...register("otp_code", {
                  required: true,
                  maxLength: validation?.phone_otp?.max_length,
                  minLength: validation?.phone_otp?.min_length,
                  pattern: new RegExp(validation?.phone_otp?.regex, 'g'),
                  disabled: OTPExpired
                })}
              />
            </label>
            <p
              className={`${hasError ? "visible" : "invisible"} error-block text-xs absolute my-2`}
              data-testid="error-label"
            >
              {workflow.otp.enterValidCode}
            </p>

            <div className={`${hasError ? "mt-12" : "mt-8"}`}>{renderExpireView()}</div>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

            {(counter !== 0 || !HasFailed) && showSubmitBtn && !IsInvalidOTP && !OTPExpired && (
              <a
                href="#"
                onClick={resendCodeCB}
                className="font-bn4 color-theme my-6 text-small-btn inline-block"
                ref={resendOtpLinkRef}
                data-testid="resend-code"
              >
                {workflow.otp.resendCode}
              </a>
            )}
          </div>
          {!submitting && <div className="text-center">
            {(showSubmitBtn && !IsInvalidOTP && !OTPExpired) ?
              (<BtnNext data-testid="next-btn" className={!isValid ? 'opacity-25 inline-block' : ''} onClick={handleSubmit(handleFormSubmit)} />)
              : <ResendCodeButton resendCode={resendCodeCB} />
            }
          </div>}
          {submitting && <BtnSpinnerNext />}
        </form>
      </div>
    </div>
  );
}

export default SignInVerifyPhoneOtp;
