import { useState, useCallback, useRef } from "react";
import { FinicityConnect } from "@finicity/connect-web-sdk";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";

import {
  getInstitutions,
  getFinicityLiteUrl,
  getFincityAccounts,
  syncPaymentMethods,
  startFinicityConnectFix,
} from "./api/finicity";

const CURRENT_VIEW_ENUM = {
  INSTITUTIONS: "INSTITUTIONS",
  ACCOUNTS: "ACCOUNTS",
};

const useFinicity = ({ accessToken, filter = undefined, fetchOnLoad = true }) => {
  const [finicityState, setFinicityState] = useState({
    current_view: CURRENT_VIEW_ENUM.INSTITUTIONS,
  });

  const institution_login_id_ref = useRef(null);
  const customer_id_ref = useRef(null);
  const institution_name_ref = useRef(null);

  const connectMethods = useCallback(
    async (accounts) => {
      setFinicityState({
        ...finicityState,
        showConnectingBankMessage: true,
      });

      const payload = {
        customer_id: customer_id_ref.current,
        institution_login_id: institution_login_id_ref.current,
        name: institution_name_ref.current,
        accounts,
      };

      // Rahul: Consumer Finicity - Sync payment methods error handling
      syncPaymentMethods(accessToken, payload)
        .then((res) => {
          setFinicityState({
            ...finicityState,
            showConnectingBankMessage: false,
            bankConnected: true,
          });
        })
        .catch((err) => {
          setFinicityState({
            ...finicityState,
            showConnectingFailureMessage: true,
            showConnectingBankMessage: false,
            bankConnected: false,
          });
        });
    },
    [finicityState, accessToken]
  );

  const initiateFinicityConnect = useCallback(
    async (institution) => {
      institution_name_ref.current = institution.name;

      setFinicityState({
        ...finicityState,
        loading: true,
      });

      // get the finicity connect url
      const { connect_url, customer_id } = await getFinicityLiteUrl({
        accessToken,
        institution_id: institution.id,
      });

      //set customer id in the state
      // setFinicityState({...finicityState, customer_id})
      customer_id_ref.current = customer_id;

      FinicityConnect.launch(
        connect_url,
        {
          onDone: async (event) => {
            const { reason, code } = event;
            if (reason === "complete" && code === 200) {
              // get accounts from BFF (savings or checkings)  -> /bff/check-finicity-accounts (PUT) by passing institutionLoginId and customer id from state
              // const {institution_login_id, customer_id} = finicityState;
              const accounts = await getFincityAccounts(accessToken, {
                institution_login_id: institution_login_id_ref.current,
                customer_id: customer_id_ref.current,
                name: institution_name_ref.current,
              });
              setFinicityState({
                ...finicityState,
                current_view: CURRENT_VIEW_ENUM.ACCOUNTS,
                loading: false,
                accounts,
              });
            }
          },
          onCancel: (event) => {
            const { reason } = event;
            if (reason === "exit") {
              window.location.reload();
            }
          },
          onError: (event) => {
            console.log("onError", event);
          },
          onUser: (userActionObject) => {
            const { action, success, institutionLoginId } = userActionObject;
            if (
              (action === "AddAccounts" || action === "AddOAuthAccounts") &&
              success
            ) {
              // set the institutionLoginId in the state
              // setFinicityState({...finicityState, institution_login_id: institutionLoginId})
              institution_login_id_ref.current = institutionLoginId.toString();
            }
          },
          onLoad: () => {
            console.log("loaded");
          },
        },
        {
          overlay: "rgba(199,201,199, 0.5);z-index: 10000;",
        }
      );
    },
    [accessToken, finicityState]
  );

  const initiateFinicityConnectFix = useCallback(
    async (payment_method_id) => {
      const res = await startFinicityConnectFix({
        accessToken,
        payment_method_id,
      });

      const { connect_url, customer_id } = res || {};

      if (connect_url) {
        FinicityConnect.launch(
          connect_url,
          {
            onDone: async (event) => {
              const { reason, code } = event;
              if (reason === "complete" && code === 200) {
                // get accounts from BFF (savings or checkings)  -> /bff/check-finicity-accounts (PUT) by passing institutionLoginId and customer id from state
                // const {institution_login_id, customer_id} = finicityState;
                const accounts = await getFincityAccounts(accessToken, {
                  institution_login_id: institution_login_id_ref.current,
                  customer_id: customer_id_ref.current,
                  name: institution_name_ref.current,
                });
                setFinicityState({
                  ...finicityState,
                  current_view: CURRENT_VIEW_ENUM.ACCOUNTS,
                  loading: false,
                  accounts,
                });
              }
            },
            onCancel: (event) => {
              const { reason } = event;
              if (reason === "exit") {
                window.location.reload();
              }
            },
            onError: (event) => {
              Sentry.captureException(event);
            },
            onUser: (userActionObject) => {
              const { action, success, institutionLoginId } = userActionObject;
              if (
                (action === "AddAccounts" || action === "AddOAuthAccounts") &&
                success
              ) {
                // set the institutionLoginId in the state
                // setFinicityState({...finicityState, institution_login_id: institutionLoginId})
                institution_login_id_ref.current =
                  institutionLoginId.toString();
              }
            },
            onLoad: () => {
              console.log("loaded");
            },
          },
          {
            overlay: "rgba(199,201,199, 0.5);z-index: 10000;",
          }
        );
      } else {
        return {
          reload: true,
        };
      }
    },
    [accessToken, finicityState]
  );

  const { status, data, error, isLoading, isFetching, ...rest } = useQuery({
    queryKey: ["institutions", filter],
    queryFn: () => {
      return getInstitutions({ accessToken, filter });
    },
    enabled: fetchOnLoad,
  });

  const { default_institutions, institutions, metadata } = data || {};

  return {
    initiateFinicityConnect,
    connectMethods,
    error,
    isLoading: isFetching,
    fetchingConnectURL: finicityState.loading,
    accounts: finicityState.accounts,
    current_view: finicityState.current_view,
    institutions: filter ? institutions : default_institutions,
    institutionsMetadata: metadata,
    showConnectingBankMessage: finicityState.showConnectingBankMessage,
    showConnectingFailureMessage: finicityState.showConnectingFailureMessage,
    bankConnected: finicityState.bankConnected,
    initiateFinicityConnectFix,
  };
};

export default useFinicity;
