import React from "react";
import emitter from "../utils/emitter";
import ClientStorage from "../utils/client-storage";
import { IMAGE_PREFIX} from "../constants/urls.constants";

type UploadBankTokenProps = { currentStep: Object,  breadcrumbs: Object, onClick: Function };
type UploadBankTokenState = {intervalId: any};

class UploadBankToken extends React.Component<UploadBankTokenProps, UploadBankTokenState> {
    uploadBankToken() {
    const bank_token = ClientStorage.getLocal("bank_token");
    const metadata = ClientStorage.getLocal("plaid-metadata");

    ClientStorage.deleteLocal("bank_token");
    ClientStorage.deleteLocal("plaid-metadata");
    ClientStorage.deleteLocal("redirectUrl");
    ClientStorage.deleteLocal("link_token");

    let data = Object.assign({}, JSON.parse(metadata), { bank_token: bank_token });

    this.props.onClick(data);
  }

  componentDidMount() {
    const breadcrumbs = {};
    const identifier = this.props.currentStep['identifier'];
    emitter.emit('progress', { breadcrumbs, identifier });
    this.uploadBankToken();
  }

  render() {
    const template = this.props.currentStep["ui_template_type"];
    const step = this.props.currentStep[template];
    const imgSrc = `${IMAGE_PREFIX()}/${step.image_name}.gif`;
    const loading = `${IMAGE_PREFIX()}/loading.gif`

    return (
      <div>
        <div className="row pb-5">
          <img src={loading} alt="loading" className="m-auto w-20"/>
        </div>
        <div className="text-center row">
          <p className="text-main mb-10" data-testid='title'>
            {step.upload_state.title}
          </p>
          <img className="w-3/4 mx-auto my-4" src={imgSrc} alt="not found"/>
        </div>
      </div>
    );
  }
}

export default UploadBankToken;
