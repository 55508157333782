import React from "react";
import Toast from "./Toast";
type Props = {
    title: string;
    timeout: number
}
export const AutoHideToast = ({ timeout = 6000, title }: Props) => {
    return (
        <Toast
            cssClasses="w-1/3 x-sm:w-full fixed m-auto top-6 x-sm:top-2 left-0 right-0 z-50 bg-white x-sm:p-4 text-center rounded-lg"
            text={title}
            timeout={timeout}
        />
    );
}