export const paymentMessage = {

    paymentSuccess: {
        title: "Payment Processing",
        message: "We’ll take about 7 days to process and post your payment of $",
        btnDone: "Done"
    },

    autopaySuccess: {
        title: "Auto pay scheduled",
        message1: "You’ve scheduled auto pay to be drawn on ",
        message2: " of every month, from ",
        message3: "(...",
        message4: "). Payment amount is",
        btnDone: "Done"
    },

    paymentError: {
        titleLine1: "Sorry, we couldn't",
        titleLine2: "process your",
        btnTryAgain: "Try again"
    },

    migrationError: {
        titleLine1: "It will take sometime",
        titleLine2: "to show payment details..."
    }
}