import React from "react";
import {autoPoll} from "../utils/auto_poll";
import emitter from "../utils/emitter";
import {track} from "../utils/analytics";
import {IMAGE_PREFIX} from "../constants/urls.constants";

type ApplicationReviewProps = {
  setWorkflow: Function,
  workflow: Object
};
type ApplicationReviewState = {intervalId: any};

class ApplicationReview extends React.Component<ApplicationReviewProps, ApplicationReviewState> {
  componentDidMount() {
    this.setAutoPoll();
  }

  setAutoPoll() {
    const interval =  autoPoll(this.props.workflow, this.props.setWorkflow);
    const breadcrumbs = this.props.workflow['breadcrumbs'];
    const identifier = this.props.workflow["steps"][0].identifier;
    const showNextSteps = false;
    emitter.emit('progress', { breadcrumbs, identifier, showNextSteps });
    this.setState({intervalId: interval});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  render() {
    const template = this.props.workflow["steps"][0]["ui_template_type"];
    const step = this.props.workflow["steps"][0][template];
    const imgSrc = `${IMAGE_PREFIX()}/${step.image_name}.png`;
    const partnerName = window['appConfig'].PARTNER_NAME;

    track('onb_application_in_review')

    return (
      <div className={`app-container-${partnerName} onb-workflow-page text-center`}>
        <h1 className="text-main mb-10" data-testid="title">
          {step.default_state.title}
        </h1>
        <img className="w-3/4 mx-auto my-4" src={imgSrc} alt="not found"/>
        <p className="theme-text2-color" data-testid="description">
          {step.description}
        </p>
      </div>
    );
  }
}

export default ApplicationReview;
