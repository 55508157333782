import React from "react";
import DownloadArrowIcon from "./DownloadArrowIcon";
type Props = {
  tickColor?: string,
  styleClass?: string,
};
function DownloadCircleIcon(props: Props) {
  const { tickColor, styleClass } = props;

  return (
    <div {...styleClass ? { "className": styleClass } : {
      "style": {
        "width": "70px",
        height: "70px",
        "border": "1px solid #fff",
        "borderRadius": "100%",
        "display": "inline-flex",
        "justifyContent": "center",
        "alignItems": "center"
      }
    }}>
      <DownloadArrowIcon color={tickColor} />
    </div>
  );
}

export default DownloadCircleIcon;
