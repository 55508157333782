const MagnifyingGlassIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.23244 10.2549C3.82872 10.2549 2.64071 9.76879 1.66841 8.79649C0.696111 7.82419 0.209961 6.63618 0.209961 5.23247C0.209961 3.82875 0.696111 2.64074 1.66841 1.66844C2.64071 0.696141 3.82872 0.209991 5.23244 0.209991C6.63615 0.209991 7.82416 0.696141 8.79646 1.66844C9.76876 2.64074 10.2549 3.82875 10.2549 5.23247C10.2549 5.7991 10.1648 6.33355 9.98447 6.83579C9.80418 7.33804 9.55949 7.78234 9.25042 8.16868L13.5775 12.4957C13.7191 12.6374 13.79 12.8177 13.79 13.0366C13.79 13.2555 13.7191 13.4358 13.5775 13.5775C13.4358 13.7192 13.2555 13.79 13.0366 13.79C12.8177 13.79 12.6374 13.7192 12.4957 13.5775L8.16865 9.25045C7.78231 9.55952 7.33801 9.80421 6.83576 9.9845C6.33352 10.1648 5.79907 10.2549 5.23244 10.2549ZM5.23244 8.70956C6.1983 8.70956 7.01928 8.37151 7.69538 7.69541C8.37148 7.01931 8.70953 6.19833 8.70953 5.23247C8.70953 4.26661 8.37148 3.44562 7.69538 2.76952C7.01928 2.09342 6.1983 1.75537 5.23244 1.75537C4.26658 1.75537 3.44559 2.09342 2.76949 2.76952C2.09339 3.44562 1.75534 4.26661 1.75534 5.23247C1.75534 6.19833 2.09339 7.01931 2.76949 7.69541C3.44559 8.37151 4.26658 8.70956 5.23244 8.70956Z"
        fill="#534B4B"
      />
    </svg>
  );
};

export default MagnifyingGlassIcon;
