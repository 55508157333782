import React, { useState } from "react";
import { IMAGE_PREFIX } from "../../constants/urls.constants";
import { isNewSignInFlowEnabled, isProveFlowEnabled, getCachedPhoneNumber } from "../../utils/helpers";
import { OldSignInFlowActions } from "../../components/OldSignInFlowActions";
import { NewSignInFlowActions } from "../../components/NewSignInFlowActions";

type DeserveCardOverviewNavProps = {};

export function DeserveCardOverviewNav(props: DeserveCardOverviewNavProps) {
  const phoneNumber = getCachedPhoneNumber();
  
  return (
    <>
      <nav className={`relative flex flex-wrap items-center justify-between px-2 ${isProveFlowEnabled() ? 'lg:px-24' : ''} py-3 ovr-page-nav x-sm:px-4`}>
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-white"
              href="http://deserve.com"
            >
              <img className={`${isProveFlowEnabled() ? 'h-6 w-32' : 'h-10 x-sm:h-8'}`} src={`${IMAGE_PREFIX()}/${isProveFlowEnabled() ? 'logo-white' : 'logo-deserve-black'}.svg`} alt="Deserve" />
            </a>
          </div>
          {!phoneNumber &&
            <div className="nav-text-amc text-xs x-sm:right-full flex x-sm:hidden">
              {isNewSignInFlowEnabled() ? <NewSignInFlowActions
                buttonsInRow={true}
                buttonsParentClass="w-auto ml-4"
                signInButtonClass="bg-transparent border font-sf-display font-normal text-sm"
                showOnTop={true}
                showApplyBtn={false}
              /> : <OldSignInFlowActions />}
            </div>
          }
        </div>
      </nav>
    </>
  );
}