import React, { useState, useEffect } from "react";
import { IMAGE_BASE_URL, FREEZE_CARD_UPDATE_API, IMAGE_PREFIX, PROFILE_API_URL } from "../constants/urls.constants";
import {commonHeaders, get} from "../utils/client";
import { ArrowRight } from "../components/ArrowRight";
import { CONTACT_US_PATH, DASHBOARD_PATH, EDIT_ADDRESS_PATH, PROFILE_PATH } from "../constants/route-paths.constants";
import Loading from "../components/Loading";
import ExclamationMark from "../components/ExclamationMark";
import Cookies from "js-cookie";
import axios from 'axios';
import { Link } from "react-router-dom";
import PaymentError from "./Payment_Error";
import { track } from "../utils/analytics";
import { EVENT } from "../constants/events.constants";
import { DEFAULT_AGREEMENTS_TO_VIEW_IDS, profileView } from "../constants/profile.constants";
import {getApplicationToken} from "../utils/helpers";
import { enableFreezeCardFeature } from "../utils/utils";

type Props = { partnerName: String };

const ProfileView = (props: Props) => {
  const appConfig = window['appConfig'];
  const [personal, setPersonal] = useState(null);
  const [credit, setCredit] = useState(null);
  const [profileOtherSection, setProfileOtherSection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showProfileError, setShowProfileError] = useState(false);
  const [card, setCard] = useState(null);
  const [state, set_state] = useState();
  const [apt, set_Apt] = useState(null);
  const [zipcode, set_zip_code] = useState();
  const [cardMessage, setCardMessage] = useState();

  const [freezeCardError, setFreezeCardError] = useState("");
  const [internationalCardError, setInternationalCardError] = useState("");
  const [cardLoading, setCardLoading] = useState(false);

  const application_token = getApplicationToken();

  const ExclamationMarkColor = {
    deserve: "#FFFFFF",
    amc: "#FFFFFF"
  };

  const OpacityValue = {
    amc: "0.6"
  }

  const arrowColor = document.body.style.getPropertyValue("--theme-card-text")

  const ERROR_MESSAGES = {
    freeze_card_action_err: `Unable to freeze card, Please try again later!`,
    international_card_action_err: `Unable to enable International Card Usage, Please try again later!`,
  }

  const getProfileData = () => {
    get(PROFILE_API_URL)
      .then((accountDataRes) => {
        let details = accountDataRes.sections.filter(x => x.identifier === "profile_personal")[0]?.cells;
        let creditDetails = accountDataRes.sections.filter(data => data.identifier === "profile_credit_detail")[0]?.cells;
        let _profileOtherSection = accountDataRes.sections.find(data => data.identifier === "profile_other");
        let personal = {};
        let credit = {};
        let content = details[2].detail.content[0];
        let cell = content['profile_template_a']?.cells;

        set_Apt(cell[0]?.value);
        set_state(cell[2].value);
        set_zip_code(cell[3].value);

        setProfileOtherSection(_profileOtherSection)

        if (creditDetails && Array.isArray(creditDetails)) {
          creditDetails.forEach(data => {
            credit[data?.identifier] = data?.subtitle;
          })
          setCredit(credit);
        }

        if (details && Array.isArray(details)) {
          details.forEach(detail => {
            personal[detail?.identifier] = detail?.subtitle;
          });
          setPersonal(personal);
        }
        getCardDetails(accountDataRes.sections.filter(data => data.identifier === "profile_card"));
        setCardLoading(false);
        track(EVENT.profile_screen_viewed)
      })
      .catch((er) => {
        track(EVENT.profile_screen_view_failed, {
          error: JSON.stringify(er, Object.getOwnPropertyNames(er)),
        });
        setShowProfileError(true);
        setCardLoading(false);
      })
      .finally(() => {
        setIsLoading(false)
      });
  }

  useEffect(() => {
    track(EVENT.profile_screen_view_initiated);
    setIsLoading(true);
    getProfileData();
  }, []);

  const getCardDetails = (cardDetails: any) => {
    const card_details = cardDetails[0]?.cells;
    const cardMessage = cardDetails[0]?.title;

    setCardMessage(cardMessage);

    let final_card = {};
    card_details?.forEach(item => {
      final_card[item.identifier] = item;
    })
    setCard(final_card);

  }

  const toggleFreezeCard = (event: any) => {
    setCardLoading(true);
    let cardData = card;
    if(cardData && cardData.profile_freeze_card) cardData.profile_freeze_card.status = event.target.checked;
    setCard({ ...cardData });
    axios.patch(`${FREEZE_CARD_UPDATE_API}`, {
      "freeze": event.target.checked
    }, {
      headers: {
        Authorization: `Bearer ${application_token}`,
        ...commonHeaders()
      }
    }).then(data => {
      setFreezeCardError("");
      setInternationalCardError("");
      track(EVENT.profileCardFreezed);
    }).catch(err => {
      setFreezeCardError(ERROR_MESSAGES.freeze_card_action_err);
      track(EVENT.freeze_card_failed);
    }).finally(() => {
      getProfileData();
    })
  }

  const toggleInternationalCard = (event: any) => {
    setCardLoading(true);
    let cardData = card;
    if (cardData && cardData.profile_international_usage) cardData.profile_international_usage.status = event.target.checked;
    setCard({ ...cardData });

    const body = {
      "international": event.target.checked
    };

    const headers = {
      headers: {
        Authorization: `Bearer ${application_token}`,
        ...commonHeaders()
      }
    };

    axios.patch(`${FREEZE_CARD_UPDATE_API}`, body, headers)
      .then(data => {
        setInternationalCardError("");
        track(EVENT.profileInternationalTxnPaused);
      })
      .catch(err => {
        setInternationalCardError(ERROR_MESSAGES.international_card_action_err);
        track(EVENT.international_card_activation_failed);
      })
      .finally(() => {
        getProfileData();
      });
  }

  const openEdit = (event: any) => {
    track(EVENT.profile_edit_address_screen_viewed);
    window.location.href = window.location.origin + EDIT_ADDRESS_PATH;
  }

  const contactClick = () => {
    track(EVENT.profile_contact_us_screen_viewed);
    window.location.href = window.location.origin + CONTACT_US_PATH;
  }

  const handleBack = () => {
    window.location.assign(DASHBOARD_PATH);
  }

  const getFlattentAgreements = (): Array<{
    identifier: string | undefined;
    title: string;
    pdf_url: string | null;
  }> =>  {
    return profileOtherSection && profileOtherSection?.cells?.map((otSecCellItem: any, otSecCellItemIdx: number) => {
      return otSecCellItem?.detail?.content?.map((otSecCellDtlContItem: any, otSecCellDtlContItemIdx: number) => {
        return otSecCellDtlContItem?.profile_template_b?.section?.cells?.map((otSecCellDtlContItem: any, otSecCellDtlContItemIdx: number) => {
          return otSecCellDtlContItem?.detail?.content?.map((otSecCellDtlContSecCellItem: any, otSecCellDtlContSecCellItemIdx: number) => {
            return {
              identifier: otSecCellDtlContSecCellItem?.identifier,
              title: otSecCellDtlContItem.title,
              pdf_url: otSecCellDtlContSecCellItem?.pdf_detail?.pdf_url,
            };
          })
        });
      })
    }).flat()?.flat()?.flat();
  };

  const renderAgreements = () => {
    return getFlattentAgreements()?.map((agreement: any, iIndex: number) => {
      const testIdByTitle = agreement?.title?.split(" ")?.join('-');
      if (DEFAULT_AGREEMENTS_TO_VIEW_IDS.includes(agreement?.identifier) && agreement?.pdf_url) {
        return (
          <div className="pb-6" key={`${agreement.title}_${iIndex}`} data-testid={`parent-${testIdByTitle}`}>
            <a
              className="text-base cursor-pointer text-heading-4 text-t2 flex items-center justify-between"
              target="_blank"
              href={agreement?.pdf_url}
              data-testid={`link-${testIdByTitle}`}
            >
              <div className="profile-address-wrapper" data-testid={`title-text-${testIdByTitle}`}>
                {agreement.title}
              </div>
              <div id="contact-arrow" className="profile-address-wrapper-right" data-testid={`title-text-${testIdByTitle}-arrow`}>
                <ArrowRight fillColor={arrowColor} />
              </div>
            </a>
          </div>
        );
      }
      return null;
    });
  }

  const getCardView = () => {
    if (card && Object.keys(card).length === 0) {
      return null;
    }
    if (card?.std_error) {
      return (
        <div className="p-7 rounded-2xl border border-transparent theme-card-bg" data-profile-item="card">
          <div className="pb-6 text-xs">{profileView.yourCard?.toUpperCase()}</div>
          <div className="text-base font-bold">{cardMessage}</div>
        </div>
      );
    }
    return (
      <div className="p-7 rounded-2xl border border-transparent theme-card-bg" data-profile-item="card">
        <div className="pb-6 text-xs text-c1">{profileView.yourCard?.toUpperCase()}</div>
        {cardLoading && <div className="w-0.3 h-0.3 profile-address-wrapper-right"> <img src={`${IMAGE_PREFIX()}/loading.gif`} alt="loading" className={"m-auto w-10 -mt-14 ml-12"} /></div>}
        {enableFreezeCardFeature(props.partnerName) && <div className="pb-6">
          <div className="text-base font-bold text-heading-4">
            {card?.profile_freeze_card?.title}
            <div className="profile-address-wrapper-right">
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input type="checkbox" id="toggleFreezeCard" data-testid="freeze-checkbox" className="sr-only card-toggle" onChange={toggleFreezeCard} checked={card?.profile_freeze_card?.status || false} disabled={cardLoading} />
                  <div className={`block bg-gray-600 w-12 h-8 rounded-full ${(card?.profile_freeze_card?.status === false && props.partnerName == "amc") ? 'opacity-70' : ''}`}></div>
                  <div className="dot absolute right-5 top-0.5 bg-white w-7 h-7 rounded-full transition"></div>
                </div>
              </label>
            </div>
          </div>
          <div className="text-base text-b3 text-t2 mt-1">
            {card?.profile_freeze_card?.subtitle}
          </div>
          {freezeCardError &&
            <div className='flex'>
              <ExclamationMark opacity={OpacityValue[String(props.partnerName)]} fillColor={ExclamationMarkColor[String(props.partnerName)]} /><small className={`cardActionErr ${props.partnerName == "amc" ? 'opacity-60' : ''}`}>{freezeCardError}</small>
            </div>}
        </div>}
        {card?.profile_international_usage && <div className="pb-6">
          <div className="text-base font-bold text-heading-4">
            {card?.profile_international_usage?.title}
            <div className="profile-address-wrapper-right">
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input type="checkbox" id="toggleInternationalCard" className="sr-only card-toggle" onChange={toggleInternationalCard} checked={card?.profile_international_usage?.status || false} disabled={cardLoading} />
                  <div className={`block bg-gray-600 w-12 h-8 rounded-full ${(card?.profile_international_usage?.status === false && props.partnerName == "amc") ? 'opacity-70' : ''}`}></div>
                  <div className="dot absolute right-5 top-0.5 bg-white w-7 h-7 rounded-full transition"></div>
                </div>
              </label>
            </div>
          </div>
          <div className="text-base text-b3 mt-1 text-t2">
            {card?.profile_international_usage?.subtitle}
          </div>
          {internationalCardError &&
            <div className='flex'>
              <ExclamationMark opacity={OpacityValue[String(props.partnerName)]} fillColor={ExclamationMarkColor[String(props.partnerName)]} /><small className={`cardActionErr ${props.partnerName == "amc" ? 'opacity-60' : ''}`}>{internationalCardError}</small>
            </div>}
        </div>}
      </div>
    );
  }


  return (
    isLoading ? <Loading /> :
      <div className={`dashboard-${props.partnerName} dashboard-profile-${props.partnerName} block lg:flex profile-page`} >
        <div className="flex flex-col md:h-screen x-sm:w-full md:w-3/4 px-14 pb-8 pt-12 x-sm:px-4 x-sm:pt-4 overflow-y-scroll">
          <div
            data-testid="logo"
            className="x-sm:mb-4 x-sm:ml-0 mb-4 flex items-center text-4xl leading-loose profile-page-title text-1-heading">
            Profile
            <div className="flex w-full justify-end -mr-2.5 x-sm:mr-3">
              <div onClick={handleBack} className={`${props.partnerName == "amc" || props.partnerName == "slm_ignite" ? 'w-10' : 'w-8'} dashboard-profile-close-${props.partnerName} cursor-pointer top-16 rounded-full x-sm:right-4 page-close`}>
                <img src={`${IMAGE_PREFIX()}/close-page.png`} alt="Cancel" />
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-7 flex-wrap x-sm:w-full x-sm:mx-0 h-full content-start py-2">
            <div className="flex flex-col gap-4 lg:w-2/5 md:w-2/4 x-sm:w-full">
              <div id="personal" className="x-sm:px-0 h-400-px  x-sm:w-full" data-profile-item="card-wp">
                <div className="p-7 rounded-2xl border border-transparent theme-card-bg" data-profile-item="card">
                  <div className="pb-6 text-xs text-c1">{profileView.personal.title}</div>
                  <div className="pb-6">
                    <div className="text-base font-bold text-heading-4">{profileView.personal.name}</div>
                    <div className="text-base text-b3 text-t2 mt-1">{personal?.profile_name}</div>
                  </div>
                  <div className="pb-6">
                    <div className="text-base font-bold text-heading-4">{profileView.personal.email}</div>
                    <div className="text-base text-b3 text-t2 mt-1 break-words">{personal?.profile_email}</div>
                  </div>
                  <div className="pb-6">
                    <div className="text-base font-bold text-heading-4">{profileView.personal.phone}</div>
                    <div className="text-base text-b3 text-t2 mt-1">{personal?.profile_phone}</div>
                  </div>
                  <div className="">
                    <div className="text-base font-bold text-heading-4">{profileView.personal.address}</div>
                    <div className="text-base cursor-pointer text-b3 text-t2 mt-1" onClick={openEdit}>
                      <div className="profile-address-wrapper x-sm:w-3/4 lg:w-3/4">
                        {personal?.profile_physical_address}, {state}, {zipcode}
                      </div>
                      <div id="address-arrow" className="profile-address-wrapper-right">
                        <ArrowRight fillColor={arrowColor} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="credit-details" className="x-sm:px-0 h-300-px x-sm:h-full  x-sm:w-full" data-profile-item="card-wp">
                <div className="p-7 rounded-2xl border border-transparent theme-card-bg" data-profile-item="card">
                  <div className="pb-6 text-xs text-c1">{profileView.creditDetails.title}</div>
                  <div className="pb-6">
                    <div className="text-base font-bold text-heading-4">{profileView.creditDetails.creditLimit}</div>
                    <div className="text-base text-b3 text-t2 mt-1">{credit?.profile_credit_limit}</div>
                  </div>
                  <div className="pb-6">
                    <div className="text-base font-bold text-heading-4">{profileView.creditDetails.availableCredit}</div>
                    <div className="text-base text-b3 text-t2 mt-1">{credit?.profile_available_credit}</div>
                  </div>
                  {credit?.profile_apr && <div className="">
                    <div className="text-base font-bold text-heading-4" data-testid="profile_apr">{profileView.creditDetails.apr}</div>
                    <div className="text-base text-b3 text-t2 mt-1">{credit?.profile_apr}</div>
                  </div>}
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 lg:w-2/5 md:w-2/4 x-sm:w-full">
              <div id="freeze-card" className="x-sm:px-0 x-sm:mt-0  x-sm:w-full" data-profile-item="card-wp">
                {getCardView()}
              </div>

              <div id='other' className="x-sm:px-0  x-sm:w-full" data-profile-item="card-wp">
                <div className="p-7 rounded-2xl border border-transparent theme-card-bg" data-profile-item="card">
                  <div className="pb-6 text-xs text-c1" data-testid="other-section">{profileView.other.title}</div>
                  {renderAgreements()}
                  <div className="">
                    <div className="text-base cursor-pointer text-heading-4 text-t2 flex items-center justify-between" onClick={contactClick}>
                      <div className="profile-address-wrapper">
                        {profileView.other.contact}
                      </div>
                      <div id="contact-arrow" className="profile-address-wrapper-right">
                        <ArrowRight fillColor={arrowColor} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {showProfileError && <PaymentError
          makePayment={() => window.location.assign(PROFILE_PATH)}
          partnerName={props.partnerName}
          type="profile"
        />}
      </div>
  )
}

export default ProfileView;