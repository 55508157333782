import Cookies from 'js-cookie'

const ClientStorage = {
  /**
   * @param  {string} key - Storage key name to set
   * @param  {string} val - storage value
   * @returns {null}
   */
  setLocal: function setLocal(key, val) {
    window.localStorage.setItem(key, val);
    return null;
  },

  /**
   * @param  {string} key - Storage key name to get
   * @returns {string} - Value of given key
   */
  getLocal: function getLocal(key) {
    return window.localStorage.getItem(key);
  },

  /**
   * @param  {string} key - Storage key name to delete
   * @returns {null}
   */
  deleteLocal: function deleteLocal(key) {
    window.localStorage.removeItem(key);
    return null;
  },

  /**
   * Clear domain all local storage items
   * @returns {null}
   */
  clearLocal: function clearLocal(config = {}) {
    const {exclude = []} = config;
    if(exclude.length === 0) {
      window.localStorage.clear();
    } else {
      Object.keys(window.localStorage).forEach((key) => {
        if(!exclude.includes(key)) {
          localStorage.removeItem(key);
        }
      })
    }    
    return null;
  },

  /**
   * @param  {string} key - Storage key name to set
   * @param  {string} val - storage value
   * @returns {null}
   */
  setSession: function setSession(key, val) {
    window.sessionStorage.setItem(key, val);
  },

  /**
   * @param  {string} key - Storage key name to get
   * @returns {string} - Value of given key
   */
  getSession: function getSession(key) {
    return window.sessionStorage.getItem(key);
  },

  /**
   * @param  {string} key - Storage key name to delete
   * @returns {null}
   */
  deleteSession: function deleteSession(key) {
    window.sessionStorage.removeItem(key);
  },

  /**
   * Clear domain all session storage items
   * @returns {null}
   */
  clearSession: function clearSession() {
    window.sessionStorage.clear();
  },


  /**
   * @param key - (string) Cookie key name to set
   * @param val - (string) Cookie value
   * @param  options - (object) Cookie options
   * @param options.path - (string) cookie path, use / as the path if you want your cookie to be accessible on all pages
   * @param options.expires - (Date) absolute expiration date for the cookie
   * @param options.maxAge -  (number) relative max age of the cookie from when the client receives it in seconds
   * @param options.domain - (string) domain for the cookie (sub.domain.com or .allsubdomains.com)
   * @param options.secure - (boolean) Is only accessible through HTTPS?
   * @param options.httpOnly - (boolean) Is only the server can access the cookie? Note: You cannot get or set httpOnly cookies from the browser, only the server.
   * @param options.sameSite - 'strict'
   * @returns {null}
  */
  setCookie: function setCookie(key, val, options = null) {
    Cookies.set(key, val, {
      path: '/',
      ...options ? options : {},
    });
  },

  /**
   * @param  {string} key - Cookie key name to get
   * @param  {object} options - Cookie options
   * @param  {boolean} options.doNotParse - do not convert the cookie into an object no matter what, default returns object
   * @returns {object|string} - Value of given key
   */
  getCookie: function getCookie(key, options = null) {
    return Cookies.get(key, {
      ...options ? options : {}
    });
  },

  /**
   * @param key - (string) Cookie key name to set
   * @param options - (object) Cookie options
   * @param options.path - (string) cookie path, use / as the path if you want your cookie to be accessible on all pages
   * @param options.expires - (Date) absolute expiration date for the cookie
   * @param options.maxAge -  (number) relative max age of the cookie from when the client receives it in seconds
   * @param options.domain - (string) domain for the cookie (sub.domain.com or .allsubdomains.com)
   * @param options.secure - (boolean) Is only accessible through HTTPS?
   * @param options.httpOnly - (boolean) Is only the server can access the cookie? Note: You cannot get or set httpOnly cookies from the browser, only the server.
   * @param options.sameSite - (boolean) Strict or Lax enforcement
   * @returns {null}
  */
   removeCookie: function removeCookie(key, options = null) {
    Cookies.remove(key, {
      path: '/',
      ...options ? options : {},
    });
  },

};

export default ClientStorage;