import React from "react";
type Props = {
  cssClasses?: string,
  children?: any
};

function FancyShadow(props: Props) {
  return (
    <div className={props.cssClasses}>
      {props.children}
    </div>
  );
}

export default FancyShadow;