import { useEffect } from 'react';
import emitter from "../utils/emitter";
import {track} from "../utils/analytics";
import * as Scroll from 'react-scroll';
import { IMAGE_PREFIX } from "../constants/urls.constants";
import { QRCodeSVG } from "qrcode.react";
import ReactHtmlParser from "html-react-parser";
import jwtDecode from "jwt-decode";
import {getApplicationToken} from "../utils/helpers";

type Props = { 
  CurrentStep: { ui_template_type: string }; 
  breadcrumbs: Object;
  workflow: Object,
  setWorkflow: Function
};

function TemplateM3(props: Props) {
  const ModelName = props.CurrentStep.ui_template_type;
  const ModelData = props.CurrentStep[ModelName];
  const next_steps = ModelData.next_steps;
  let Link = Scroll.Link;
  const QRCodeImageURL = window["appConfig"]["PROVE_FLOW_URL"];
  let email = jwtDecode(getApplicationToken())['email'];

  useEffect(() => {
    emitter.emit('progress', { breadcrumbs: props.breadcrumbs, identifier: props.CurrentStep['identifier'] });
  }, [props.CurrentStep, props.breadcrumbs, props.workflow, props.setWorkflow]);

  track('onb_prove_flow_viewed');

  const handleNext = (e: Event) => {
    e.preventDefault();
    track("onb_prove_flow_continue_clicked", {download_url: QRCodeImageURL});
    window.location.href = QRCodeImageURL;    
    return null;
  }

  return (
    <div key={props.CurrentStep['step_info'].step_identifier} id={props.CurrentStep['step_info'].step_identifier}>
      <p className="profile-page-title theme-text-color text-3xl lg:text-4xl px-3 sm:px-10 lg:px-14 mt-28 md:mt-0 mb-1 lg:mb-4 xl:mb-8 2xl:mb-6 text-center" data-testid="title">{ReactHtmlParser(ModelData.default_state.title)}</p>
      <p className="font-sf-display-light theme-text2-color text-lg px-3 lg:px-14 mb-14 lg:mb-4 xl:mb-8 2xl:mb-6 text-center" data-testid="title">{`${ReactHtmlParser(ModelData.default_state.subtitle)} ${email}`}</p>
      <div className='font-sf-display-light theme-text2-color hidden lg:block mt-9 mx-auto prove-qrCode-block'>
        <div className="font-sf-display-light theme-text2-color text-xl pt-8 text-center">{next_steps.steps[0].title.text}</div>
        <div className="font-sf-display-light theme-text2-size theme-text2-color text-sm pt-2 text-center px-20">{ReactHtmlParser(next_steps.sub_title)}</div>
        <div className='relative'>
          <QRCodeSVG className='hidden lg:block mx-auto pb-6 mt-1' size={250} includeMargin={true} value={QRCodeImageURL} />
        </div>
      </div>
      <div className='lg:hidden'>
        <div className='text-center w-64 h-16 mx-auto congrats-page-btn'>
          <Link
            to={`${1}`}
            containerId="containerElement"
            className='block h-full py-4 font-medium text-xl tracking-tighter onsent-button-submit cursor-pointer button-with-custom-text rounded-full text-white'
            onClick={handleNext}
          >
            {ModelData.default_state.first_action_title}
          </Link>
        </div>
        <div className='mt-14 text-center text-lg'>{next_steps.sub_title_mobile}</div>
      </div>
    </div>
  )
}

export default TemplateM3;
