import { useState, useEffect } from 'react';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [delayInProcess, setDelayInProcess] = useState(false);

  useEffect(() => {
    setDelayInProcess(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setDelayInProcess(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return {
    debouncedValue,
    delayInProcess,
  };
};

export default useDebounce;
