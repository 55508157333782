import React, { useEffect } from "react";
import { IGINITE_L_QNS, IGINITE_R_QNS } from "../constants/ignite-qns";
import { Accordion } from "./Accordion";

const SLMIgniteFAQView = (props) => {
  return (
    <section id="questions" className="md:pt-18 lg:pt-16 x-sm:px-8">
      <h1 className="page-sub-heading x-sm:text-xl lg:text-30px lg:text-left x-sm:mb-0 sm:mb-0 md:mb-4 lg:mb-4 xl:mb-4">
        <span className="block">Frequently Asked Questions</span>
      </h1>
      <div className="flex x-sm:flex-col md:flex-row lg:flex-row">
        <div className="md:pr-0 lg:pr-8 md:w-full lg:w-1/2 accordion-wp">
          {IGINITE_L_QNS().map((item, idx: number) => {
            return (
              <div className="border-b border-gray-300" key={`l${idx}`}>
                <Accordion
                  title={item.title}
                  content={item.content}
                  key={`r${idx}`}
                />
              </div>
            );
          })}
        </div>
        <div className="md:pl-0 lg:pl-8 md:w-full lg:w-1/2 accordion-wp">
          {IGINITE_R_QNS().map((item, idx: number) => {
            return (
              <div className="border-b border-gray-300" key={`r${idx}`}>
                <Accordion title={item.title} content={item.content} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SLMIgniteFAQView;
