import React, { useEffect, useState } from "react";
import axios from "axios";
import { loadVGSCollect } from "@vgs/collect-js";
import { useForm } from "react-hook-form";
import * as Sentry from "@sentry/react";
import * as Scroll from 'react-scroll';
import ReactHtmlParser from 'html-react-parser';

import BtnNext from "../components/BtnNext";
import IconUnCheck from "../components/IconUnCheck";
import IconCheck from "../components/IconCheck";
import emitter from '../utils/emitter';
import EyeIcon from "../components/EyeIcon";
import EyeCrossIcon from "../components/EyeCrossIcon";
import { VAULT_CONFIG_ENDPOINT, EPHEMERAL_TOKEN_API_URL, SSN_TOKEN_API } from "../constants/urls.constants";
import { track } from "../utils/analytics";
import BtnSpinnerNext from "../components/BtnSpinnerNext";
import { EVENT } from "../constants/events.constants";
import ErrorOccurred from "./ErrorOccurred";
import { workflow } from "../constants/workflow.constants";
import { getEphemeralTokenData, postWithAuthHeader } from "../utils/client";
import { VGS_COLLECT_VERSION } from "../utils/constants";

const SSN = (props) => {
  const [form, setForm] = useState(null);
  const [ssn, setSsn] = useState("");
  const template = props.workflow["steps"][0]["ui_template_type"];
  const chapter = props.workflow["steps"][0][template];
  const [hasError, setHasError] = useState(false);
  const [fatalError, setFatalError] = useState(false);
  const breadcrumbs = props.workflow.breadcrumbs;
  const identifier = props.workflow["steps"][0].identifier;
  const [contentCount, setContentCount] = useState(0);
  const [contentBtnText, setContentBtnText] = useState('Read Next');
  const [showSubmitBtn, setShowSubmitBtn] = useState(!chapter.show_read_next);
  const [ssn_checkbox, set_ssn_checkbox] = useState(false);
  const [ssnMaskIcon, setSSNMaskIcon] = useState('eyeCross');
  const [ssnFormField, setSsnFormField] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const subTitle = chapter?.subtitle;
  const footerImgTitleText = chapter?.footer?.left_image_label_info?.title?.text;
  const footerTitleText = chapter && chapter.footer && chapter.footer.title && chapter.footer.title.text;

  const agreementContent = chapter.description.split("<br><br>");

  const partnerName = window['appConfig'].PARTNER_NAME;

  let Link = Scroll.Link;
  let Element = Scroll.Element;
  let Events = Scroll.Events;
  let scrollSpy = Scroll.scrollSpy;

  const {
    register,
    handleSubmit,
    formState,
    setValue
  } = useForm({
    reValidateMode: "onChange",
    mode: "all",
  });

  const { isValid } = formState;

  Events.scrollEvent.register('begin', function (to, element) {
  });

  Events.scrollEvent.register('end', function (to, element) {
  });

  scrollSpy.update();

  useEffect(() => {
    emitter.emit('progress', { breadcrumbs, identifier });

    const text_color = getComputedStyle(document.body).getPropertyValue("--text-t2-color");
    const css = {
      width: "auto",
      height: "3rem",
      color: `${text_color}`,
      "font-size": "1.875rem",
      "line-height": "2.25rem",
    };

    const initForm = (vgs_collect) => {
      if (!form) {
        const vgsform = vgs_collect.init((state) => {
          return state.ssn ? clearSSNError(state.ssn.last4) : setHasError(true);
        });

        let environment = window["appConfig"].ENVIRONMENT || 'prod'

        let validations = environment === 'prod' ? ["required", "validSSN"] : ["required"]

        const VGS_VAULT_CNAME = window["appConfig"].VGS_VAULT_CNAME;
        vgsform.useCname(VGS_VAULT_CNAME);

        setSsnFormField(vgsform.field("#ssn_token", {
          type: "ssn",
          name: "ssn",
          validations: validations,
          max: 9,
          hideValue: isVisible,
          css,
          serializers: [{ name: "replace", options: { old: "-", new: "" } }],
        }));

        setForm(vgsform);
      }
    };

    const loadForm = async () => {
      let config = await axios.get(VAULT_CONFIG_ENDPOINT).then((res) => res.data);
      config['version'] = VGS_COLLECT_VERSION;
      loadVGSCollect(config).then(vgs_collect => {
        initForm(vgs_collect);
      }).catch((err) => {
        setFatalError(true);
        track(EVENT.onb_ssn_vgs_failed);
      });
    };

    loadForm().then(() => {
      // logger.info("Form loaded")
    });
  }, [breadcrumbs, identifier, form, isVisible]);

  const clearSSNError = (ssnLast4) => {
    setSsn(ssnLast4);
    setHasError(false);
  }

  const onSSNVisibilityChange = (e) => {
    let icon = isVisible ? 'eye' : 'eyeCross';
    setSSNMaskIcon(icon);

    if (ssnFormField && typeof ssnFormField.update === "function") {
      ssnFormField.update({ hideValue: !isVisible });
      setIsVisible(!isVisible);
    }
  }

  const handleFormSubmit = async () => {
    setSubmitting(true);
    if (ssn && ssn.length === 4) {
      track(EVENT.onb_ssn_next_clicked);
      footerImgTitleText && track(EVENT.onb_submit_application_clicked);
      const { ephemeral_token } = await getEphemeralTokenData(EPHEMERAL_TOKEN_API_URL);

      form.submit(
        `/tokenization`,
        {
          headers: {
            Authorization: `${ephemeral_token}`,
            'x-tenant-id': window['appConfig'].PROGRAM_ID,
            'Content-Type': 'application/json'
          },
          data: {
            debug_mode: false,
            last_4_digits_ssn: ssn,
            credit_pull_consent: true,
            phone_number: window['localStorage'].getItem(
              'non_cached_phone_number'
            ),
          },
        },
        (status, res) => {
          // logger.info('status, data --- ', status, res);
          if (status !== 200) {
            setSubmitting(false);
            track(EVENT.onb_ssn_submit_store_vgs_failed);
            Sentry.captureException(new Error(`SSN submission failed - ${status}`));
            setFatalError(true);
          } else {
            const { ssn } = res;
            submitSSNForm(ssn);
          }
        }
      );
    } else {
      handleSpinner();
      setHasError(true);
    }
  };

  const submitSSNForm = async (value) => {
    // @ts-ignore
    let res = await postWithAuthHeader(
      SSN_TOKEN_API,
      { debug_mode: false, last_4_digits_ssn: ssn, credit_pull_consent: true, ssn_token: value },
      EVENT.onb_ssn_submit_failed
    );
    setSubmitting(false);
    if (res.status === 200) {
      track(EVENT.onb_ssn_submitted)
      props.handleSubmit({});
    } else {
      track(EVENT.onb_ssn_submit_failed);
      Sentry.captureException(new Error(`SSN submission failed - ${res.status}`));
      setFatalError(true);
    }
  }

  const handleNext = (e: Event) => {
    e.preventDefault();
    if (contentBtnText === 'Accept') {
      setShowSubmitBtn(true);
      set_ssn_checkbox(true);
      setValue('ssn_checkbox', true, { shouldValidate: true });
    } else {
      if (contentCount !== agreementContent.length - 2) {
        setContentCount(contentCount + 1);
      } else {
        setContentBtnText('Accept');
      }
    }
  }


  let handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) { ssn_checkbox ? setShowSubmitBtn(true) : setContentBtnText('Accept') }
  }

  function handleSpinner(resp?: any) {
    setSubmitting(false);
  }

  function renderButtons() {
    if (chapter.footer?.action?.title_button_type?.text) {
      return (
        <div className='text-center text-xl tracking-tight p-2 md:p-5 cursor-pointer'>
          <button
            className={'w-72 h-16 bg-theme font-white rounded-full py-3 px-20 relative ' + (ssn && ssn_checkbox && ssn.length === 4 ? '' : 'opacity-25')}
            onClick={handleSubmit(handleFormSubmit)}
            disabled={!(ssn && ssn_checkbox && ssn.length === 4)}
          >
            {submitting
              ? <div className="loading-black absolute left-32 top-5"></div>
              : <div className="">{chapter.footer.action.title_button_type.text}</div>
            }
          </button>
        </div>
      );
    } else {
      if (showSubmitBtn || !chapter.footer.action) {
        return !submitting ? (<BtnNext className={ssn && ssn_checkbox && ssn.length === 4 ? '' : 'opacity-25 inline-block'} onClick={handleSubmit(handleFormSubmit)} />) : (<BtnSpinnerNext />);
      } else {
        return (
          <div className='text-center text-xl tracking-tight p-2 md:p-5 cursor-pointer'>
            <Link
              to={`${contentCount + 1}`}
              containerId="containerElement"
              className='w-72 consent-button-cancel rounded-full py-3 px-24'
              onClick={handleNext}>
              {contentBtnText}
            </Link>
          </div>
        );
      }
    }
  }

  return (
    <div className={`app-container-${partnerName} onb-workflow-page`}>
      {fatalError ? <ErrorOccurred /> :
        <div>
          <p className="text-main md:mb-1" data-testid="title">{chapter["default_state"].title}</p>
          {subTitle && <p className="text-base txt-type-para font-normal" data-testid="subtitle">{ReactHtmlParser(subTitle)}</p>}
          <div className="content-container">
            <form>
              <div className="pb-9 relative">
                <div className="content-container md:mt-5">
                  <label className="">
                    <div className="uppercase mb-2 tracking-wide field-caption">
                      {chapter.input_field.title}
                    </div>
                  </label>
                  <span id="ssn_token" className="form-field overflow-hidden relative">
                    <span className="absolute right-0" onClick={onSSNVisibilityChange}>
                      {ssnMaskIcon === 'eye' && <EyeIcon />}
                      {ssnMaskIcon === 'eyeCross' && <EyeCrossIcon />}
                    </span>
                  </span>
                  {hasError && (
                    <div className="flex flex-row mb-10 w-full space-x-4">
                      <p className="error-block absolute text-xs my-2">
                        {workflow.ssn.ssnErrorMessage}
                      </p>
                    </div>
                  )}
                </div>
                <div className="hidden overflow-hidden">
                  <span id="credit_pull_consent" className="form-field"></span>
                </div>
              </div>
              <div className="flex flex-row mb-4 w-full space-x-4">
                <span>
                  <input
                    type="checkbox"
                    name="ssn_checkbox"
                    id="ssn_check"
                    data-testid="ssn_check"
                    className="invisible w-5 h-auto absolute"
                    onChange={(e) => {
                      set_ssn_checkbox(!ssn_checkbox);
                      setShowSubmitBtn(true);
                    }}
                    ref={register({ required: true })}
                  />
                  <label className={ssn_checkbox ? "df-icon-checked" : "df-icon-unchecked"} htmlFor="ssn_check">
                    {ssn_checkbox ?
                      <IconCheck /> : <IconUnCheck />
                    }
                  </label>
                </span>
                <div className="h-auto txt-type-para overflow-y-auto mb-4 text-xs" id="containerElement" onScroll={handleScroll}>
                  {agreementContent.map((content, index) => {
                    return (

                      <React.Fragment key={index}>
                        <Element name={`${index}`}>
                          {ReactHtmlParser(agreementContent[index])}
                        </Element>
                      </React.Fragment>

                    );
                  })}
                </div>
              </div>
              {footerImgTitleText && <p className="text-base txt-type-para font-normal text-center rounded-md ssn-subtitle py-4 px-5 mb-1 sm:w-max mx-auto" data-testid="footerImgTitle">
                <span className="ssn_subtitle-info mr-2">&#x1F64B;</span>
                {ReactHtmlParser(footerImgTitleText)}
              </p>}
              <div className="text-center h-16">
                {renderButtons()}
              </div>
            </form>
          </div>
          {footerTitleText && <div data-testid="footerTitle" className='flex txt-type-para flex-row mt-10 text-xs'>{footerTitleText}</div>}
        </div>}
    </div>
  );
};

export default SSN;
